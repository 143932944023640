import { format } from 'date-fns'
import { formatAmountNoSymbol } from '@/utils/money'
import { FlagIcon } from 'lucide-react'
import TransactionStatusBadge from '../../Transactions/components/TransactionStatusBadge'
import { TransactionStatus } from '../../Transactions/const'
import { TransactionType } from '@/types/transactions'
import { Invoice } from '@/types/invoice'

type Props = {
    transactionsData?: TransactionType[]
    invoice?: Invoice
}

export default function LinkedTransactions({ transactionsData, invoice }: Props) {
    return (
        <section className='bg-white rounded-xl py-6'>
            <section className='rounded-xl border border-[#DADCE0]  mx-0 mb-12 overflow-y-scroll'>
                <table className='w-full leading-normal rounded-xl'>
                    <tr className='flex gap-6 text-left bg-[#f6f6f6] text-[#838383] font-light rounded-t-xl overflow-hidden'>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Transaction ID
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Date & time
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Amount </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Initiated by{' '}
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>
                            Destination
                        </th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Type</th>
                        <th className='w-[7rem] px-4 py-4 font-light bg-[#f6f6f6] text-left text-[12px]'>Status </th>
                    </tr>
                    {transactionsData?.length === 0 && (
                        <div className='flex items-center justify-center w-full py-4'>No transactions linked yet</div>
                    )}
                    {transactionsData?.map(transaction => (
                        <tr className='flex gap-6 text-[.9rem] '>
                            <td className='w-[7rem] px-4 py-4'>
                                {transaction?.transaction_id}
                                {transaction?.flagged && <FlagIcon className='h-4 w-4' />}
                            </td>
                            <td className='w-[7rem] px-4 py-4'>
                                <p className='font-normal mb-1 text-sm'>
                                    {format(new Date(transaction?.createdAt), 'dd/MM/yyyy')}
                                </p>
                                <p className='font-normal mb-1 text-sm'>
                                    {format(new Date(transaction?.createdAt), 'hh:mm a')}
                                </p>
                            </td>
                            <td className='w-[7rem] px-4 py-4'>
                                <span className='font-normal whitespace-nowrap'>
                                    {transaction.ownerTransactionType === 'DEBIT' ? (
                                        <span className='text-[#CF491E]'>
                                            {formatAmountNoSymbol(transaction.debit.amount)}
                                        </span>
                                    ) : (
                                        <span className='text-[#19C37D]'>
                                            {formatAmountNoSymbol(transaction.credit.amount)}
                                        </span>
                                    )}
                                </span>
                            </td>
                            <td className='w-[7rem] px-4 py-4'> {invoice?.customer?.name}</td>
                            <td className='w-[7rem] px-4 py-4'>{invoice?.org?.org_name}</td>
                            <td className='w-[7rem] px-4 py-4'>{transaction?.type} </td>
                            <td className='w-[7rem] px-4 py-4 whitespace-nowrap'>
                                <TransactionStatusBadge
                                    status={transaction.status.toLowerCase() as unknown as TransactionStatus}
                                />
                            </td>{' '}
                        </tr>
                    ))}
                </table>
            </section>
        </section>
    )
}
