import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../ModalWrapper'
import { useState } from 'react'
import CreateBudget1 from './CreateBudget1'
import { Button } from '@/components'
import CreateBudget2 from './CreateBudget2'
import { ArrowLeft } from 'lucide-react'
import { FormValues } from './utils'
import { useCreateBudget, useUpdateBudget } from '@/services/budget/mutations'
import { useAuthContext, useBudgetContext } from '@/context'
import { Budget } from '@/types/budget'
import { capitalizeText } from '@/utils/string'
import { toastHandler } from '@/components/utils/Toast'
import { useGetTeamById } from '@/services/employees/queries'

type CreateBudgetProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    updateBudget?: boolean
    budgetProps?: Budget | undefined
}

const CreateBudgetModal = ({ closeModal, budgetProps, updateBudget }: CreateBudgetProps) => {
    const { selectedAccount } = useAuthContext()
    const { setBudgetObj } = useBudgetContext()

    const formEmptyState: FormValues = {
        name: budgetProps?.name ?? '',
        amount: budgetProps?.allocated_amount ?? '',
        reset: budgetProps?.reset ?? '',
        start_date: (budgetProps?.start_date as string) ?? '',
        end_date: (budgetProps?.end_date as string) ?? '',
        manager: budgetProps?.manager.map(manager => ({
            label: `${capitalizeText(manager?.user?.first_name)} ${capitalizeText(manager?.user?.first_name)}`,
            value: manager?.id ?? manager?._id,
        })) as Record<'value' | 'label', string>[],
        category: budgetProps?.category ?? '',
        method: budgetProps?.category ?? 'automated',
        team_id: budgetProps?.team?._id ?? 'none',
        distribute_budgets: !budgetProps?.is_distributed ? 'false' : 'true',
        purpose: budgetProps?.purpose ?? '',
        unused_budget: budgetProps?.rollover ? '1' : '',
        currency: budgetProps?.currency._id as string,
        users:
            budgetProps?.account_type.toLowerCase() === 'userorgaccount'
                ? 'user'
                : budgetProps?.account_type.toLowerCase() ?? '',
    }

    // console.log({ budgetProps })
    const [step, setStep] = useState(1)

    const { mutate: createBudgetFn, isLoading: isCreatingBudget } = useCreateBudget({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess() {
            closeModal(false)
            toastHandler({ message: 'Budget created successfully', state: 'success' })
        },
    })
    const { mutate: updateBudgetFn, isLoading: isUpdatingBudget } = useUpdateBudget({
        queryParams: {
            budget_id: budgetProps?._id as string,
        },
        onSuccess: () => {
            closeModal(false)
            setBudgetObj(undefined)
            toastHandler({ message: 'Budget update successful', state: 'success' })
        },
    })

    const onSubmit = async (values: FormValues) => {
        const userOrgAccountId = values?.allocatedAmount && values?.allocatedAmount.map(data => data.member.id)
        const distributedAmount =
            values?.allocatedAmount &&
            values?.allocatedAmount
                .filter(data => data.amount !== '0')
                .map(data => {
                    if (data.amount === '0') return
                    return {
                        account: data.member.id,
                        allocated_amount: Number(data.amount) / 100,
                        available_amount: Number(data.amount) / 100,
                    }
                })

        const data_to_server = {
            allocated_amount: Number(values.amount) / 100,
            name: values.name,
            account_type: values.users !== 'team' ? values.users : 'Team',
            manager: values.manager.map(manager_details => manager_details?.value),
            category: values.category,
            // accounts: values.users === 'team' ? [values.team_id] : userOrgAccountId,
            accounts: values.users === 'team' ? getTeamUsers() : userOrgAccountId,
            distribution: distributedAmount,
            is_distributed: values.distribute_budgets === 'true' ? true : false,
            distribution_method: values.method,
            start_date: values.start_date,
            end_date: values.end_date,
            purpose: values?.purpose,
            rollover: values.unused_budget === '1' ? true : false,
            reset: values.reset,
            currency: values.currency,
            team: values.team_id !== 'none' ? values.team_id : undefined,
        }
        if (updateBudget) {
            updateBudgetFn(data_to_server)
            return
        }

        createBudgetFn(data_to_server)
        // console.log(data_to_server)
    }

    const [formState, setFormState] = useState<FormValues>(() => {
        // delete formEmptyState.start_date
        // delete formEmptyState.start_date
        return {
            ...formEmptyState,
        }
    })

    const { data: team_data } = useGetTeamById({
        queryParams: {
            team_id: formState.team_id,
        },
        enabled: formState.team_id !== 'none',
    })
    // console.log(formState)

    const getTeamUsers = () => {
        return team_data?.users.map(user => user.id)
    }

    const checkToEnableNextBtn = () => {
        const obj = { ...formState }
        if (formState.reset === 'one-off') {
            delete obj.unused_budget
        }
        if (formState.reset !== 'one-off') {
            delete obj.start_date
            delete obj.end_date
        }

        return Object.values(obj).every(value => value?.toString()?.length > 0)
    }

    return (
        <ModalWrapper formId='budgetForm' closeModal={() => closeModal(false)} shouldConfirmClose variants={undefined}>
            <div className='w-screen lg:w-[50rem] min-h-[65vh] lg:min-h-[75vh] relative flex flex-col justify-between'>
                <div className=''>
                    <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end'>
                        <div className='flex items-center w-[58%] justify-between h-full'>
                            <h2 className='font-semibold text-[#202020]'>
                                {updateBudget ? 'Update' : 'Create new'} budget
                            </h2>
                            <div
                                className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer mr-8'
                                onClick={() => {
                                    closeModal(false)
                                }}
                            >
                                <AiOutlineClose fontSize={25} />
                            </div>
                        </div>
                    </div>
                    <div className='px-4 lg:px-10 my-4 max-h-[400px] lg:max-h-[70vh] overflow-scroll'>
                        {step === 1 && (
                            <CreateBudget1
                                formState={formState}
                                setFormState={setFormState}
                                updateBudget={updateBudget}
                            />
                        )}
                        {step === 2 && <CreateBudget2 formState={formState} setFormState={setFormState} />}
                    </div>
                </div>
                <div className=''>
                    <div className=' mb-3 px-10 flex items-center justify-end gap-4 '>
                        {step === 2 && (
                            <div className='flex gap-1 my-6 cursor-pointer' onClick={() => setStep(1)}>
                                <ArrowLeft />
                                go back
                            </div>
                        )}

                        <Button
                            className=''
                            onClick={() => {
                                onSubmit(formState)
                            }}
                            disabled={step === 1 ? !checkToEnableNextBtn() : false}
                            loading={isCreatingBudget || isUpdatingBudget}
                        >
                            {updateBudget ? 'Update Budget' : 'Create budget'}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default CreateBudgetModal
