import { Button, Input } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useUpdateCompanyProfile } from '@/services/auth/mutations'
import { OrgType, UserOrgAccount } from '@/types/org'
import { useState } from 'react'

type Props = {
    setCloseEditProfile: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CompanyProfileEdit({ setCloseEditProfile }: Props) {
    const { selectedAccount, setSelectedAccount } = useAuthContext()
    const [editProfileDetails, setEditProfileDetails] = useState({
        email: selectedAccount?.org?.email,
        org_name: selectedAccount?.org?.org_name,
    })
    const [editProfile, setEditProfile] = useState({ generalInfo: false, locationInfo: false })

    const { mutate: updateCompanyFn, isLoading } = useUpdateCompanyProfile({
        onSuccess: data => {
            setCloseEditProfile(false)
            setEditProfile({ generalInfo: false, locationInfo: false })
            setSelectedAccount(prev => ({ ...(prev as UserOrgAccount), org: data as unknown as OrgType }))
            toastHandler({ state: 'success', message: 'Changes saved' })
        },
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })
    const orgDetails = selectedAccount?.org

    const profiles = [
        {
            name: 'Name',
            desc: selectedAccount?.org?.org_name,
            type: 'org_name',
        },
        { name: 'Email address', desc: orgDetails?.email, type: 'email' },
    ]

    const handleEditCompanyInfo = () => {
        updateCompanyFn({
            org_name: editProfileDetails?.org_name,
            email: editProfileDetails?.email,
        })
    }

    return (
        <>
            <section className='border border-[#DADCE0] rounded-xl px-6 '>
                <div className='flex flex-wrap gap-x-[2rem] gap-5 gap-y-7 mt-6 max-w-[65rem]'>
                    {profiles.map(profile => (
                        <div className='flex flex-col w-[18rem]'>
                            <label htmlFor={profile.name} className='text-[#838383] font-normal text-sm mb-2'>
                                {profile.name}
                            </label>
                            <div className='flex gap-1 '>
                                {
                                    <Input
                                        id={profile.type}
                                        name={profile.type}
                                        type='text'
                                        className='h-[2.5rem] font-medium disabled:text-[#DADCE0] disabled:bg-[#F9F9F9] border-[#DADCE0]'
                                        value={editProfileDetails?.[profile.type as keyof typeof editProfileDetails]}
                                        onChange={e =>
                                            setEditProfileDetails(prev => ({
                                                ...prev,
                                                [profile.type]: e.target.value,
                                            }))
                                        }
                                    />
                                }
                            </div>
                        </div>
                    ))}
                </div>
                <Button
                    className='ml-auto mr-4 mb-10 mt-8 min-w-[120px] text-sm'
                    loading={!editProfile?.locationInfo ? isLoading : false}
                    disabled={editProfile?.locationInfo}
                    onClick={() => {
                        setEditProfile(prev => ({ ...prev, generalInfo: true }))
                        handleEditCompanyInfo()
                    }}
                >
                    Save changes
                </Button>
            </section>
        </>
    )
}
