import CheckBox from '@/components/Table/CheckBox'
import { useAccountingContext } from '@/context'
import { ExtendedRow } from '@/context/accounting'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { format } from 'date-fns'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
    row: ExtendedRow
    idx: number
}
const AccountingRow = ({ row, idx }: Props) => {
    const { setRows, columns } = useAccountingContext()
    const { control } = useFormContext<ExtendedRow>()

    const handleSelect = (checked: boolean) => {
        setRows(prev => prev.map(r => (r._id === row._id ? { ...r, selected: checked } : r)))
    }

    const owner = row.recipient_type === 'vendors' ? undefined : row?.credit.account
    const vendorName = row?.metadata?.recipient?.name
    const userName =
        capitalizeText(row?.credit?.account.user?.first_name) +
        ' ' +
        capitalizeText(row?.credit?.account.user?.last_name?.[0])

    return (
        <tr className='border-t border-gray4/60'>
            <td className=' px-5 h-14 text-center'>
                <CheckBox
                    checked={!!row.selected}
                    onChange={e => handleSelect((e.target as HTMLInputElement).checked)}
                />
            </td>
            <td className=' px-5 h-14 text-gray7 text-sm font-medium'>
                {row.onModel !== 'Org'
                    ? userName
                    : owner?.user?.first_name
                      ? `${capitalizeText(owner?.user?.first_name)} ${capitalizeText(owner?.user?.last_name?.[0])}`
                      : vendorName
                        ? capitalizeText(vendorName)
                        : capitalizeText(row?.owner?.org_name)}
            </td>
            <td className=' px-5 h-14 text-gray7 text-sm font-medium'>
                {row.createdAt && `${format(new Date(row.createdAt), 'dd/MM/yyyy, hh:mm a')}`}
            </td>
            <td className=' px-5 h-14 text-gray7 text-sm font-medium'>
                {row.ownerTransactionType === 'DEBIT' ? (
                    <span className='text-[#CF491E]'>{formatAmount(row.debit.amount)}</span>
                ) : (
                    <span className='text-[#19C37D]'>{formatAmount(row.credit.amount)}</span>
                )}
            </td>
            <td className=' px-5 h-14 text-gray7 text-sm font-medium'>
                {' '}
                {row?.initiated_by?.first_name &&
                    `${capitalizeText(row?.initiated_by?.first_name)} ${capitalizeText(row?.initiated_by?.last_name?.[0])}.`}
            </td>
            <td className=' px-5 h-14 text-gray7 text-sm font-medium'>{row?.type}</td>

            {columns
                ?.filter(col => col.editable)
                .map(col => (
                    <td className=' px-5 h-14 text-gray7 text-sm'>
                        <Controller
                            key={col.id}
                            name={`rows.${idx}.${col.id}` as keyof ExtendedRow}
                            control={control}
                            disabled={!row.ready}
                            render={({ field }) => (
                                <input {...field} value={field.value?.toString()} placeholder={`Enter ${col.label}`} />
                            )}
                        />
                    </td>
                ))}
        </tr>
    )
}

export default AccountingRow
