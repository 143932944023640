import { Budget } from './budget'
import { CategoryType } from './categories'
import { ExpenseType } from './expense'
import { User } from './user'
import { DedicatedBankAccountType } from './accounts'
import { CurrencyType } from './currency'

type TransactionAccountType = {
    financial_account: {
        nickname: string

        provider: 'anchor'
    }
    account: {
        user: User
        email: string
        org_name: string
        org_type: string
        createdAt: string
        updatedAt: string
        status: string
        id: string
        invite_date: string
        org: string
    }
    financial_account_details: DedicatedBankAccountType
    accountType: string
    amount: number
}
export enum TransactionTypeType {
    PURCHASE = 'Purchase',
    REFUND = 'Refund',
    REIMBURSEMENT = 'Reimbursement',
    WALLET_FUNDING = 'Wallet Funding',
    ACCOUNT_FUNDING = 'Account Funding',
    WALLET_DEDUCTION = 'Wallet Deduction',
    CARD_FUNDING = 'Card Funding',
    WITHDRAWAL = 'Withdrawal',
    TRANSFER = 'Transfer',
    CONTRACT_PAYMENT = 'Contract Payment',
    BILL_PAYMENT = 'Bill Payment',
    PAYROLL = 'Payroll',
    INVOICE = 'Invoice',
    SUBSCRIPTION = 'Subscription Payment',
}

export type TransactionType = {
    debit: TransactionAccountType
    credit: TransactionAccountType
    description: string
    type: TransactionTypeType
    fee: number
    currency: CurrencyType
    category: CategoryType
    status: 'PENDING' | 'COMPLETED' | 'FAILED' | 'PROCESSING'
    owner: {
        email: string
        org_name: string
        org_type: string
        createdAt: string
        updatedAt: string
        id: string
        user: User
    }
    onModel: 'UserOrgAccount' | 'Org'
    ownerTransactionType: 'DEBIT' | 'CREDIT'
    notes: []
    expenses: ExpenseType[]
    createdAt: string
    updatedAt: string
    transaction_id: string
    financial_account_details: DedicatedBankAccountType
    financial_account: {
        nickname: string

        provider: 'anchor'
    }
    id: string
    _id: string
    flagged?: boolean
    receipt_url: string
    budget: Budget
    initiated_by: {
        first_name: string
        last_name: string
    }

    metadata: {
        recipient: {
            name: string
            description?: string
            _id?: string
        }
    }
    recipient_type: RecipientType.EMPLOYEES | RecipientType.VENDORS | RecipientType.EXTERNAL
    flagged_by?: User
    resolved_by?: User
    flagged_at?: string
    resolved_at?: string
    note?: { text: string; user: string; _id: string }
}

export enum RecipientType {
    EMPLOYEES = 'employees',
    VENDORS = 'vendors',
    EXTERNAL = 'external',
}
