import { ReactNode, createContext, useState } from 'react'

import { TransactionType } from '@/types/transactions'

export type ToggledColumn = {
    id: string
    label: string
    isCompulsory: boolean
    editable: boolean
}

export interface ExtendedRow extends TransactionType {
    toggledData?: Record<string, string>
    selected?: boolean
    ready?: boolean
}

export type AccountingContextDefaultTypes = {
    pageNumberClicked: boolean
    setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
    rows: ExtendedRow[]
    setRows: React.Dispatch<React.SetStateAction<ExtendedRow[]>>
    columns: ToggledColumn[]
    addColumn: (column: ToggledColumn) => void
}

const defaultValues: AccountingContextDefaultTypes = {
    pageNumberClicked: false,
    setPageNumberClicked: () => undefined,
    pageNumber: 1,
    setPageNumber: () => undefined,
    columns: [],
    rows: [],
    setRows: () => undefined,
    addColumn: () => undefined,
}

export const AccountingContext = createContext(defaultValues)

export default function AccountingContextProvider({ children }: { children: ReactNode }) {
    const [pageNumber, setPageNumber] = useState(1)
    const [pageNumberClicked, setPageNumberClicked] = useState(false)

    const [rows, setRows] = useState<ExtendedRow[]>([
        {
            _id: '675aba969f8280213e4ce49d',
            description: 'Salary Payment',
            type: 'Payroll',
            scope: 'INTERNAL_OUT',
            debit: {
                amount: 8448278,
                financial_account_type: 'Wallet',
                account: {},
            },
            credit: {
                account: {
                    _id: '66d0588d38f210148efa653e',
                    org: '653a74e42f732bca781d94e8',
                    status: 'ACTIVE',
                    email: 'ezehsuccess0476@gmail.com',
                    role: '6496bd8c16590b88d7a4a2b0',
                    invite_date: '2024-08-29T11:16:29.668Z',
                    createdAt: '2024-08-29T11:16:29.669Z',
                    updatedAt: '2024-09-09T10:09:31.170Z',
                    employee_id: 'EMP-00011',
                    __v: 0,
                    user: {
                        _id: '668cda3d56b408a9ab010888',
                        email_verified: true,
                        email: 'ezehsuccess0476@gmail.com',
                        first_name: 'Success',
                        is_org_owner: false,
                        is_password_enabled: false,
                        last_name: 'Ezeh',
                        onboarding_completed: false,
                        password: '$2b$10$tnC982N17jS9ItX5X/TR0.NbqGdrWth8mVFmDWR/4o3tCLa61R9pe',
                        phone: '09010123456',
                        is_kyc_complete: false,
                        account_status: 'ACTIVE',
                        internal: true,
                        createdAt: '2024-07-09T06:35:41.982Z',
                        updatedAt: '2024-12-12T10:42:57.339Z',
                        __v: 0,
                        timeZone: 'Africa/Lagos',
                        payrollVerificationToken: '8963',
                        payrollVerificationTokenExpiryDate: '2024-09-26T12:10:36.274Z',
                        passwordResetToken: '28080731861756000000',
                        passwordResetTokenExpiryDate: '2024-10-15T12:53:36.955Z',
                    },
                },
                accountType: 'UserOrgAccount',
                amount: 8448278,
                financial_account_type: 'BankAccount',
            },
            status: 'PROCESSING',
            owner: {
                _id: '653a74e42f732bca781d94e8',
                email: 'peculiarugbo@gmail.com',
                org_name: 'Bizpend Staging',
                org_type: 'SOLE_PROPRIETORSHIP',
                createdAt: '2023-10-26T14:17:08.514Z',
                updatedAt: '2024-11-19T15:52:21.801Z',
                __v: 0,
                kyb: '6579c0ade5aa199fc89ea6ef',
                country: 'nigeria',
                phone: '08114445886',
                postal_code: '6667520000',
                state: 'Abuja Federal Capital Territory',
                tax_id: 'asdasdn34hhjj',
                company_size: '401',
                industry: 'Pharmaceuticals',
                website: 'www.com',
                street: '1, new York, USA',
                kyb_completed: true,
                logo: 'https://d2la9dmngmayl9.cloudfront.net/invoices/1725974880025bizpendlogosvg.png',
                anchor_customer_id: '17207391088728-anc_bus_cst',
                timeZone: 'Africa/Lagos',
                mono_customer_id: '66dd76c4de48d60efeadf785',
                stripe_customer_id: 'cus_RFOWY9YrU0hTTG',
            },
            onModel: 'Org',
            ownerTransactionType: 'DEBIT',
            flagged: false,
            initiated_by: {
                _id: '668cda3d56b408a9ab010888',
                email_verified: true,
                email: 'ezehsuccess0476@gmail.com',
                first_name: 'Success',
                is_org_owner: false,
                is_password_enabled: false,
                last_name: 'Ezeh',
                onboarding_completed: false,
                password: '$2b$10$tnC982N17jS9ItX5X/TR0.NbqGdrWth8mVFmDWR/4o3tCLa61R9pe',
                phone: '09010123456',
                is_kyc_complete: false,
                account_status: 'ACTIVE',
                internal: true,
                createdAt: '2024-07-09T06:35:41.982Z',
                updatedAt: '2024-12-12T10:42:57.339Z',
                __v: 0,
                timeZone: 'Africa/Lagos',
                payrollVerificationToken: '8963',
                payrollVerificationTokenExpiryDate: '2024-09-26T12:10:36.274Z',
                passwordResetToken: '28080731861756000000',
                passwordResetTokenExpiryDate: '2024-10-15T12:53:36.955Z',
            },
            batch_id: '44020fe7-4864-4507-9fb7-8dc0dd6e26ac',
            fee: 5000,
            accounting_status: 'NEEDS_REVIEW',
            transaction_date: '2024-12-12T10:27:34.430Z',
            createdAt: '2024-12-12T10:27:34.434Z',
            updatedAt: '2024-12-12T10:27:34.434Z',
            transaction_id: 'TX-43381',
            currency: {
                _id: '66e45c547ef524f01b44e0c6',
                currency_code: 'NGN',
                symbol: '₦',
                name: 'Naira',
                createdAt: '2024-09-13T15:37:58.112Z',
                updatedAt: '2024-09-13T15:37:58.112Z',
                __v: 0,
            },
            __v: 0,
            metadata: {},
            expenses: {
                submitter: {
                    user: [],
                },
            },
        } as unknown as ExtendedRow,
        {
            _id: '675aba9280213e4ce4232d',
            description: 'Salary Payment',
            type: 'kdfdsdl',
            scope: 'INTERNAL_OUT',
            debit: {
                amount: 8448278,
                financial_account_type: 'Wallet',
                account: {},
            },
            credit: {
                account: {
                    _id: '66d0588d38f210148efa653e',
                    org: '653a74e42f732bca781d94e8',
                    status: 'ACTIVE',
                    email: 'ezehsuccess0476@gmail.com',
                    role: '6496bd8c16590b88d7a4a2b0',
                    invite_date: '2024-08-29T11:16:29.668Z',
                    createdAt: '2024-08-29T11:16:29.669Z',
                    updatedAt: '2024-09-09T10:09:31.170Z',
                    employee_id: 'EMP-00011',
                    __v: 0,
                    user: {
                        _id: '668cda3d56b408a9ab010888',
                        email_verified: true,
                        email: 'ezehsuccess0476@gmail.com',
                        first_name: 'Success',
                        is_org_owner: false,
                        is_password_enabled: false,
                        last_name: 'Ezeh',
                        onboarding_completed: false,
                        password: '$2b$10$tnC982N17jS9ItX5X/TR0.NbqGdrWth8mVFmDWR/4o3tCLa61R9pe',
                        phone: '09010123456',
                        is_kyc_complete: false,
                        account_status: 'ACTIVE',
                        internal: true,
                        createdAt: '2024-07-09T06:35:41.982Z',
                        updatedAt: '2024-12-12T10:42:57.339Z',
                        __v: 0,
                        timeZone: 'Africa/Lagos',
                        payrollVerificationToken: '8963',
                        payrollVerificationTokenExpiryDate: '2024-09-26T12:10:36.274Z',
                        passwordResetToken: '28080731861756000000',
                        passwordResetTokenExpiryDate: '2024-10-15T12:53:36.955Z',
                    },
                },
                accountType: 'UserOrgAccount',
                amount: 8448278,
                financial_account_type: 'BankAccount',
            },
            status: 'PROCESSING',
            owner: {
                _id: '653a74e42f732bca781d94e8',
                email: 'peculiarugbo@gmail.com',
                org_name: 'Bizpend Staging',
                org_type: 'SOLE_PROPRIETORSHIP',
                createdAt: '2023-10-26T14:17:08.514Z',
                updatedAt: '2024-11-19T15:52:21.801Z',
                __v: 0,
                kyb: '6579c0ade5aa199fc89ea6ef',
                country: 'nigeria',
                phone: '08114445886',
                postal_code: '6667520000',
                state: 'Abuja Federal Capital Territory',
                tax_id: 'asdasdn34hhjj',
                company_size: '401',
                industry: 'Pharmaceuticals',
                website: 'www.com',
                street: '1, new York, USA',
                kyb_completed: true,
                logo: 'https://d2la9dmngmayl9.cloudfront.net/invoices/1725974880025bizpendlogosvg.png',
                anchor_customer_id: '17207391088728-anc_bus_cst',
                timeZone: 'Africa/Lagos',
                mono_customer_id: '66dd76c4de48d60efeadf785',
                stripe_customer_id: 'cus_RFOWY9YrU0hTTG',
            },
            onModel: 'Org',
            ownerTransactionType: 'DEBIT',
            flagged: false,
            initiated_by: {
                _id: '668cda3d56b408a9ab010888',
                email_verified: true,
                email: 'ezehsuccess0476@gmail.com',
                first_name: 'Success',
                is_org_owner: false,
                is_password_enabled: false,
                last_name: 'Ezeh',
                onboarding_completed: false,
                password: '$2b$10$tnC982N17jS9ItX5X/TR0.NbqGdrWth8mVFmDWR/4o3tCLa61R9pe',
                phone: '09010123456',
                is_kyc_complete: false,
                account_status: 'ACTIVE',
                internal: true,
                createdAt: '2024-07-09T06:35:41.982Z',
                updatedAt: '2024-12-12T10:42:57.339Z',
                __v: 0,
                timeZone: 'Africa/Lagos',
                payrollVerificationToken: '8963',
                payrollVerificationTokenExpiryDate: '2024-09-26T12:10:36.274Z',
                passwordResetToken: '28080731861756000000',
                passwordResetTokenExpiryDate: '2024-10-15T12:53:36.955Z',
            },
            batch_id: '44020fe7-4864-4507-9fb7-8dc0dd6e26ac',
            fee: 5000,
            accounting_status: 'NEEDS_REVIEW',
            transaction_date: '2024-12-12T10:27:34.430Z',
            createdAt: '2024-12-12T10:27:34.434Z',
            updatedAt: '2024-12-12T10:27:34.434Z',
            transaction_id: 'TX-43381',
            currency: {
                _id: '66e45c547ef524f01b44e0c6',
                currency_code: 'NGN',
                symbol: '₦',
                name: 'Naira',
                createdAt: '2024-09-13T15:37:58.112Z',
                updatedAt: '2024-09-13T15:37:58.112Z',
                __v: 0,
            },
            __v: 0,
            metadata: {},
            expenses: {
                submitter: {
                    user: [],
                },
            },
        } as unknown as ExtendedRow,
    ])

    const [columns, setColumns] = useState<ToggledColumn[]>([
        { id: 'destination', label: 'Destination / Merchant', isCompulsory: true, editable: false },
        { id: 'date', label: 'Date & time', isCompulsory: true, editable: false },
        { id: 'amount', label: 'Amount', isCompulsory: true, editable: false },
        { id: 'initiated_by', label: 'Initiated by', isCompulsory: true, editable: false },
        { id: 'type', label: 'Type', isCompulsory: true, editable: false },
        { id: 'status', label: 'Status', isCompulsory: true, editable: true },
        { id: 'category', label: 'Category', isCompulsory: true, editable: true },
        { id: 'money', label: 'Money', isCompulsory: true, editable: true },
        { id: 'phone', label: 'Phone', isCompulsory: true, editable: true },
    ])

    const addColumn = (column: ToggledColumn) => {
        setColumns(prev => [...prev, column])
        setRows(prev =>
            prev.map(row => ({
                ...row,
                [column.id]: '',
            }))
        )
    }

    const values = {
        pageNumber,
        setPageNumber,
        pageNumberClicked,
        setPageNumberClicked,
        rows,
        setRows,
        columns,
        addColumn,
    }
    return <AccountingContext.Provider value={values}>{children}</AccountingContext.Provider>
}
