import CheckBox from '@/components/Table/CheckBox'
import { useAccountingContext } from '@/context'
import AccountingRow from './Accounting-Row'
import { Check, X } from 'lucide-react'
import { MissingReceipt } from '@/assets/assets.export'
import { FaEllipsisVertical } from 'react-icons/fa6'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { ExtendedRow } from '@/context/accounting'

type FormType = {
    rows: ExtendedRow[]
}

type Props = {
    rows: ExtendedRow[]
}
const AccountingTableComponent = ({ rows }: Props) => {
    const { setRows, columns } = useAccountingContext()

    const methods = useForm<FormType>({
        defaultValues: { rows },
    })

    useFieldArray({
        name: 'rows',
        control: methods.control,
    })

    const handleSelectAll = (checked: boolean) => {
        setRows(prev => prev.map(row => ({ ...row, selected: checked })))
    }

    const onSubmit = (data: FormType, updatedRow: ExtendedRow, ready: boolean) => {
        updatedRow = data.rows.find(row => row._id === updatedRow._id) as ExtendedRow
        setRows(prev => prev.map(r => (r._id === updatedRow._id ? { ...r, ready, ...updatedRow } : r)))
    }

    const allSelected = rows.every(row => row.selected)
    const someSelected = rows.some(row => row.selected)
    return (
        <FormProvider {...methods}>
            <div className='relative border border-gray5 rounded-lg'>
                <div className='flex'>
                    <div className='overflow-x-scroll flex-grow  scrollbar-thumb-gray7 scrollbar-thin'>
                        <table className='min-w-[1600px]   leading-normal rounded-lg border-collapse text-xs'>
                            <thead>
                                <tr>
                                    <th className='px-5 h-12 font-light bg-[#f9f9f9] rounded-tl-xl'>
                                        <CheckBox
                                            checked={allSelected}
                                            indeterminate={someSelected && !allSelected}
                                            onChange={e => handleSelectAll((e.target as HTMLInputElement).checked)}
                                        />
                                    </th>
                                    {columns.map(col => (
                                        <th
                                            className='px-5 h-12 bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'
                                            key={col.id}
                                        >
                                            {col.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((row, idx) => (
                                    <AccountingRow idx={idx} key={row._id} row={row} />
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className='flex-none'>
                        <table className=' leading-normal border-collapse text-xs'>
                            <thead>
                                <tr>
                                    <th className='border-l border-gray5 px-2 lg:px-5 h-12  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'>
                                        Ready
                                    </th>
                                    <th className='px-2 lg:px-5 h-12 border-gray5 bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'>
                                        Receipt
                                    </th>
                                    <th className='border-l px-4 lg:px-5 h-12 border-gray5 bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'>
                                        {''}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map(field => (
                                    <tr>
                                        <td className='border-l border-gray5 h-14 border-t'>
                                            {field?.ready ? (
                                                <button
                                                    onClick={() =>
                                                        methods.handleSubmit(data => onSubmit(data, field, false))()
                                                    }
                                                    aria-label='ready'
                                                    className='rounded p-1 bg-errorRed/20 text-errorRed mx-auto flex'
                                                >
                                                    <X strokeWidth={2} size={16} />
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() =>
                                                        methods.handleSubmit(data => onSubmit(data, field, true))()
                                                    }
                                                    aria-label='ready'
                                                    className='rounded p-1 bg-green/20 text-green mx-auto flex'
                                                >
                                                    <Check strokeWidth={2} size={16} />
                                                </button>
                                            )}
                                        </td>
                                        <td className='border-r border-gray5 h-14 border-t'>
                                            <div className='text-xs text-gray7 flex flex-col gap-0.5 items-center'>
                                                <span>
                                                    <MissingReceipt />
                                                </span>
                                                <span className='text-[0.65rem]'>Missing</span>
                                            </div>
                                        </td>
                                        <td className='h-14 border-gray5 border-t text-center'>
                                            <button aria-label='more options' className='text-gray7/70'>
                                                <FaEllipsisVertical size={17} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </FormProvider>
    )
}

export default AccountingTableComponent
