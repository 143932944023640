import Logo from '@/assets/bizpendlogo.png'
import blueTick from '@/assets/blue-tick.svg'
import signupImage from '@/assets/sidebar sign up image.svg'
import { Button, Input } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useCreateNewEmployeeUser } from '@/services/auth/mutations'
import { useGetInviteDetails } from '@/services/employees/queries'
import { getInviteEmployeeDataProps } from '@/services/employees/types'
import { CheckIcon, EyeIcon, EyeOffIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useSearchParams } from 'react-router-dom'

type FormValues = {
    first_name: string
    last_name: string
    email: string
    password: string
    org_type: string
    org_name: string
}

const err_msg =
    'Must be between (6-20) characters and should include at least one uppercase, one numeric value and one special character'

const EmployeeSignUp = () => {
    const { setLoggedIn } = useAuthContext()

    const [inviteDetails, setInviteDetails] = useState<getInviteEmployeeDataProps>()

    const { handleSubmit, control, watch } = useForm<FormValues>()

    const [showPassword, setShowPassword] = useState(false)

    //extracts the search params from the url
    const [queryParameter] = useSearchParams()
    const query_details = queryParameter.get('account') as string

    //==================asynchronous operations to fetch the invited user and as well create and account for them=======
    const { mutate: createNewEmployeeUserFn, isLoading } = useCreateNewEmployeeUser({
        onSuccess: () => {
            setLoggedIn(true)
            toastHandler({ message: 'Sign up successful' })
        },
        queryParams: {
            account: query_details,
        },
    })

    const { data: invite_data } = useGetInviteDetails({
        queryParams: {
            account: query_details,
        },
    })
    //==============================================================================

    const onSubmit = async (values: FormValues) => {
        createNewEmployeeUserFn(values)
    }

    const handleShowPassword = () => {
        setShowPassword(prev => !prev)
    }
    useEffect(() => {
        setInviteDetails(invite_data)
    }, [invite_data, query_details])

    const watchPassword = watch('password')
    const checkIfUpperCase =
        watchPassword === undefined || watchPassword.length < 1 ? false : /[A-Z]|[\u0080-\u024F]/.test(watchPassword)
    const checkIfLowerCase =
        watchPassword === undefined || watchPassword.length < 1 ? false : /[a-z]|[\u0080-\u024F]/.test(watchPassword)
    const checkIfHasNumber =
        watchPassword === undefined || watchPassword.length < 1 ? false : /[0123456789]/.test(watchPassword)
    // const checkIfHasSpecialChar =
    //     watchPassword === undefined || watchPassword.length < 1 ? false : /[~`!@#$%^&*()_+='"?.,]/.test(watchPassword)

    const disableBtn = checkIfUpperCase && checkIfLowerCase && checkIfHasNumber && watchPassword.length > 7

    return (
        <div className='grid grid-cols-4 relative'>
            <div
                className={`hidden lg:grid lg:col-span-1 border-r border-[#DADCE0] bg-[#EFEFF9]  overscroll-y-contain absolute w-1/4`}
            >
                <div className='h-[100vh] flex flex-col  '>
                    <div className='p-8 py-3 border-b border-[#DADCE0]'>
                        <div className=''>
                            <img src={Logo} alt='logo' className='w-[137px]' />
                        </div>
                    </div>
                    <div className='flex flex-col mt-10 flex-1'>
                        <div className='px-3 xl:px-8 pb-12 flex-1'>
                            <div className='flex gap-3 items-start mb-8'>
                                <img src={blueTick} alt='tick' />
                                <div className='flex flex-col gap-2'>
                                    <span className={`text-lg font-bold text-[#202020]`}>Join us today</span>
                                    <p
                                        className={`text-[#5E5E5E] xl:pr-8 text-sm lg:text-base xl:text-lg font-medium `}
                                    >
                                        Create your account and join your organization
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='pr-8'>
                            <img src={signupImage} alt='sign-up image' className='w-full' />
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`col-span-4 lg:col-start-2 lg:col-span-3  w-[90%] md:w-3/4 max-w-[1100px] mx-auto mt-5 justify-center items-center pb-10 md:pb-0`}
            >
                <div className='h-full flex flex-col justify-center my-auto'>
                    <form onSubmit={handleSubmit(onSubmit)} className=''>
                        <h2 className='text-[#31254B] text-3xl mb-4 font-semibold'>Join us today</h2>
                        <p className='text-[#202020] mb-6 text-[18px] lg:max-w-[50%]'>
                            You’ve been invited to join{' '}
                            <span className='text-[#454ADE] font-semibold'> {inviteDetails?.org_name}</span> in using
                            our services. Create your account in just one click
                        </p>

                        <div className='flex flex-col md:flex-row gap-[30px] mb-5'>
                            <div className='flex flex-col flex-1 gap-2'>
                                <label htmlFor='firstName' className='text-[#202020] text-[14px]'>
                                    First name <span className='text-[red]'>*</span>
                                </label>
                                <Controller
                                    name='first_name'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input className='h-[3rem]' {...field} placeholder='Enter your first name' />
                                    )}
                                />
                            </div>

                            <div className='flex flex-col flex-1 gap-2'>
                                <label htmlFor='lastName' className='text-[#202020] text-[14px]'>
                                    Last name <span className='text-[red]'>*</span>
                                </label>
                                <Controller
                                    name='last_name'
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input {...field} className='h-[3rem]' placeholder='Enter your last name' />
                                    )}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col md:flex-row gap-[30px] mb-5'>
                            <div className='flex flex-col flex-1 gap-2'>
                                <label htmlFor='OrganizationName' className='text-[#202020] text-[14px]'>
                                    Business name <span className='text-[red]'>*</span>
                                </label>
                                <Input
                                    placeholder='Enter the name of your  organization'
                                    disabled
                                    value={inviteDetails?.org_name}
                                    className='text-gray bg-[#F9F9F9] border-[#DADCE0] h-[3rem]'
                                />
                            </div>
                            <div className='flex flex-col flex-1 gap-2'>
                                <label htmlFor='email' className='text-[#202020] text-[14px]'>
                                    Work email <span className='text-[red]'>*</span>
                                </label>

                                <Input
                                    type='email'
                                    placeholder='Enter your work email address'
                                    disabled
                                    value={inviteDetails?.email}
                                    className='text-gray bg-[#F9F9F9] border-[#DADCE0] h-[3rem]'
                                />
                            </div>
                        </div>

                        <div className='flex flex-col flex-1 gap-2 mb-5'>
                            <label htmlFor='password' className='text-[#202020] text-[14px]'>
                                Password <span className='text-[red]'>*</span>
                            </label>
                            <Controller
                                name='password'
                                rules={{
                                    required: true,
                                    minLength: { value: 6, message: err_msg },
                                    maxLength: { value: 20, message: err_msg },
                                    pattern: {
                                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                                        message: err_msg,
                                    },
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder='Enter your password'
                                        className='h-[3rem]'
                                        rightIcon={
                                            showPassword ? (
                                                <EyeOffIcon onClick={handleShowPassword} className='cursor-pointer' />
                                            ) : (
                                                <EyeIcon onClick={handleShowPassword} className='cursor-pointer' />
                                            )
                                        }
                                        {...field}
                                    />
                                )}
                            />
                            <ul className='text-[14px] text-[#838383] flex flex-col gap-2 mt-2 mb-4'>
                                <li className='flex gap-2 items-center font-medium'>
                                    <CheckIcon
                                        className={`rounded-full border-2 p-1 scale-75 ${
                                            watchPassword?.length > 7 ? 'text-[#19C37D]' : 'text-[#838383]'
                                        }`}
                                        strokeWidth={4}
                                    />
                                    <span
                                        className={`${watchPassword?.length > 7 ? 'text-[#202020]' : 'text-[#838383]'}`}
                                    >
                                        Your password must be a minimum of 8 characters long
                                    </span>
                                </li>
                                <li className='flex gap-2 items-center font-medium'>
                                    <CheckIcon
                                        className={`rounded-full border-2 p-1 scale-75 ${
                                            checkIfUpperCase ? 'text-[#19C37D]' : 'text-[#838383]'
                                        }`}
                                        strokeWidth={4}
                                    />
                                    <span className={`${checkIfUpperCase ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                        Your password must contain at least one uppercase letter (A-Z)
                                    </span>
                                </li>
                                <li className='flex gap-2 items-center font-medium'>
                                    <CheckIcon
                                        className={`rounded-full border-2 p-1 scale-75 ${
                                            checkIfLowerCase ? 'text-[#19C37D]' : 'text-[#838383]'
                                        }`}
                                        strokeWidth={4}
                                    />
                                    <span className={`${checkIfLowerCase ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                        Your password must contain at least one lowercase letter (a-z)
                                    </span>
                                </li>
                                <li className='flex gap-2 items-center font-medium'>
                                    <CheckIcon
                                        className={`rounded-full border-2 p-1 scale-75 ${
                                            checkIfHasNumber ? 'text-[#19C37D]' : 'text-[#838383]'
                                        }`}
                                        strokeWidth={4}
                                    />
                                    <span className={`${checkIfHasNumber ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                        Your password must contain at least one numeric character (0-9)
                                    </span>
                                </li>
                                {/* <li className='flex gap-2 items-center font-medium'>
                                <CheckIcon
                                    className={`rounded-full border-2 p-1 scale-75 ${
                                        checkIfHasSpecialChar ? 'text-[#19C37D]' : 'text-[#838383]'
                                    }`}
                                    strokeWidth={4}
                                />
                                <span className={`${checkIfHasSpecialChar ? 'text-[#202020]' : 'text-[#838383]'}`}>
                                    Your password must contain at least one special character (e.g., !@#$%^&*)
                                </span>
                            </li> */}
                            </ul>

                            {/* <span
                            className={`${
                                isValid ? 'opacity-0' : 'opacity-95'
                            } text-[red] text-xs transition-all duration-75`}
                        >
                            {' '}
                            {!getValues().password ? err_msg : errors?.password?.message}
                            <br />
                        </span> */}
                        </div>

                        <div className='flex flex-col-reverse md:flex-row gap-4 md:gap-0 items-center justify-between mt-10'>
                            <p className='flex items-center gap-2 text-[#202020] font-medium'>
                                Already have an account?
                                <Link to='/' className='text-[#454ADE] font-semibold'>
                                    Log in
                                </Link>
                            </p>

                            <Button loading={isLoading} disabled={!disableBtn} variant='default' type='submit'>
                                Join Business
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EmployeeSignUp
