import React, { useState } from 'react'
import ModalWrapper from '../../ModalWrapper'
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import { AiOutlineClose } from 'react-icons/ai'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    handleTerminate: (text: string) => void
    isLoading: boolean
}
export default function TerminateContractPrompt({ closeModal, handleTerminate, isLoading }: Props) {
    const [reason, setReason] = useState('')
    const [note, setNote] = useState('')
    const checkNote = note.length > 10 ? false : true
    return (
        <ModalWrapper variants={undefined}>
            <div className='w-screen lg:w-[37rem] max-h-[90vh] overflow-scroll '>
                <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-end'>
                    <div className='flex items-center w-[94%] lg:w-[65%] justify-between h-full'>
                        <h2 className='font-semibold text-[#31254B] text-2xl'>Terminate contract</h2>
                        <div
                            className='bg-[#F2F2F2] w-[30px] h-[30px] mr-2 p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                            onClick={() => closeModal(false)}
                        >
                            <AiOutlineClose fontSize={25} />
                        </div>
                    </div>
                </div>
                <div className='px-[1.8rem] mt-8'>
                    <h1 className='text-xl'>Why are you terminating this contract?</h1>
                    <p className='text-sm text-[#5E5E5E]'>
                        Are you sure you want to terminate this contract? Please provide a reason below.
                    </p>
                </div>
                <form onSubmit={(e: React.FormEvent) => e.preventDefault()} className='mt-2 pb-6'>
                    <div className='p-[1.8rem] pt-3 pb-0 max-h-[400px] lg:max-h-[80vh] overflow-scroll'>
                        <div className={`flex flex-col gap-[1.6rem] mb-12`}>
                            <div className=' flex flex-col gap-2'>
                                <label htmlFor='flag' className='text-[#202020] text-[14px] font-medium'>
                                    Reason <span className='text-[red]'>*</span>
                                </label>
                                <Select name='flag' onValueChange={e => setReason(e)}>
                                    <SelectTrigger className='w-full h-[3.5rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'>
                                        <SelectValue placeholder={`Why are you terminating this contract`} />
                                    </SelectTrigger>
                                    <SelectContent className='w-[33.3rem] max-h-[200px] overflow-scroll'>
                                        <SelectItem value='suspicious charges'>Suspicious Charges</SelectItem>
                                        <SelectItem value='fraud activity'>Fraudulent Activity</SelectItem>
                                        <SelectItem value='other'>Others</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>

                            <div className='flex flex-col flex-1 gap-3'>
                                <label className='flex items-center gap-1'>
                                    Additional note {reason === 'other' ? '(Compulsory)' : '(Optional)'}
                                </label>
                                <Textarea
                                    name='note'
                                    placeholder='Explain further if you wish to.'
                                    className='border border-[#DADCE0] pt-4 min-h-[7.5rem] text-base resize-none'
                                    value={note}
                                    onChange={e => {
                                        const { value } = e.target
                                        setNote(value)
                                    }}
                                />
                            </div>
                        </div>
                        <Button
                            className='w-full bg-errorRed text-base font-normal mb-3 hover:bg-errorRed disabled:bg-errorRed disabled:opacity-40'
                            onClick={() => {
                                if (reason === 'other') {
                                    handleTerminate(note)
                                } else handleTerminate(reason)
                            }}
                            loading={isLoading}
                            disabled={reason === 'other' ? checkNote : reason.length === 0}
                        >
                            Yes, terminate contract
                        </Button>
                    </div>
                </form>
            </div>
        </ModalWrapper>
    )
}
