import { Menu } from '@headlessui/react'
import { UserAccount } from '@/services/payout/types'
import { ExpenseType } from '@/types/expense'
import React, { useState } from 'react'
import { useAuthContext, useExpenseContext } from '@/context'
import { toastHandler } from '@/components/utils/Toast'
import { ExpenseStatus } from '../const'
import { Button } from '@/components'
import ReimburseExpenseModal from '@/components/Modals/Expense/ReimburseExpense/ReimburseExpenseMondal'
import {
    useMutateSubmitDraftExpense,
    useMutationAuthorizeExpense,
    useOwnerOverrideExpense,
} from '@/services/expenses/mutations'
import { CgSpinner } from 'react-icons/cg'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { EditExpenseButtonAction } from './ExpenseAuthorizePermission'
import ExpenseFormRoot from '@/components/Modals/Expense/CreateExpense/ExpenseFormRoot'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { expenseSidebarPermissions } from '@/layout/LayoutComponents/utils'

type Props = {
    expense: ExpenseType
    hasAPrimaryBank: () => boolean
    submitterAccount: UserAccount[]
    setExpenseDetails: React.Dispatch<React.SetStateAction<ExpenseType>>
    isRefetchingExpense: boolean
}

const OwnerAuthorizeExpensePermission = ({
    expense,
    hasAPrimaryBank,
    isRefetchingExpense,
    setExpenseDetails,
    submitterAccount,
}: Props) => {
    const [reimburseModal, setReimburseModal] = useState(false)
    const [approveButtonClicked, setApproveButtonClicked] = useState(false)
    const [declinedButtonClicked, setDeclinedButtonClicked] = useState(false)
    const { editModal, setEditModal, categories } = useExpenseContext()

    const hasPermission = useHasAccessPermisson(expenseSidebarPermissions)

    const { mutate: authorizeExpense, isLoading: isAuthorizationLoading } = useMutationAuthorizeExpense({
        queryParams: {
            id: expense?.id,
        },
        refetch: [['/expense-id']],
        onSuccess(data) {
            toastHandler({
                message: 'Expense Approved',
                state: 'success',
            })
            setExpenseDetails(prev => ({ ...prev, status: data?.status }))
        },
    })

    const { mutate: submitDraftExpense, isLoading: isSubmitting } = useMutateSubmitDraftExpense({
        queryParams: { id: expense?.id as string },
        onSuccess: data => {
            toastHandler({
                message: 'Draft submitted',
                state: 'success',
            })
            setExpenseDetails(prev => ({ ...prev, status: data?.status }))
        },
    })

    const { mutate: overrideExpenseApproval, isLoading } = useOwnerOverrideExpense({
        queryParams: {
            id: expense?.id,
        },
        refetch: [['/expense-id']],
        onSuccess(data) {
            setExpenseDetails(prev => ({ ...prev, status: data?.status }))
            setApproveButtonClicked(false)
            setDeclinedButtonClicked(false)
        },
    })
    const handleAccountCheck = () => {
        if (hasAPrimaryBank()) {
            setReimburseModal(true)
            return
        }
        toastHandler({ message: 'User does not have any bank as primary', state: 'error' })
    }

    const handleAuthorizeExpense = (status: string, override?: boolean) => {
        if (status === ExpenseStatus.approved) {
            setApproveButtonClicked(true)
        }
        if (status === ExpenseStatus.denied) {
            setDeclinedButtonClicked(true)
        }

        if (override) {
            overrideExpenseApproval(
                { approve: status === ExpenseStatus.approved },
                {
                    onSuccess: () => {
                        toastHandler({
                            message:
                                status === ExpenseStatus.approved ? 'Expense Approved (Override)' : 'Expense Declined',
                            state: 'success',
                        })
                    },
                }
            )

            return
        }
        authorizeExpense({ approve: status === ExpenseStatus.approved })
    }
    const { selectedAccount } = useAuthContext()
    const isPartOfApprover = expense.approvers?.some(
        approver => approver?.account?.id === selectedAccount?.id && !approver?.approved
    )

    // if (expense.status === ExpenseStatus.reimbursed) return

    return (
        <>
            <div className='flex flex-col lg:flex-row flex-1'>
                {
                    <EditExpenseButtonAction
                        expense={expense}
                        setEditModal={setEditModal}
                        isRefetchingExpense={isAuthorizationLoading || isRefetchingExpense || isLoading}
                    />
                }

                {expense?.status === ExpenseStatus.draft && (
                    <Button
                        loading={isSubmitting}
                        disabled={isAuthorizationLoading || isRefetchingExpense}
                        onClick={() => submitDraftExpense({})}
                        className='bg-brand  min-w-[130px] min-h-[45px]'
                    >
                        Submit Expense
                    </Button>
                )}
                {expense?.status !== ExpenseStatus.draft &&
                    hasPermission &&
                    (expense?.reimbursable &&
                    (expense?.status === ExpenseStatus.approved || expense?.status === ExpenseStatus.processing) ? (
                        <div className='flex justify-center mb-8 flex-1  '>
                            <Button
                                onClick={handleAccountCheck}
                                className=' w-96 bg-[#19C37D] hover:bg-[#19C37D] hover:opacity-90 text-white'
                                disabled={expense?.status === ExpenseStatus.processing || isRefetchingExpense}
                            >
                                {expense?.status === ExpenseStatus.processing ? 'Processing' : 'Reimburse'} expense
                            </Button>
                        </div>
                    ) : expense?.status !== ExpenseStatus.approved &&
                      expense?.status !== ExpenseStatus.denied &&
                      expense?.status !== ExpenseStatus.reimbursed ? (
                        <div className='flex gap-3 justify-center flex-1'>
                            <Button
                                className='lg:mr-4  min-w-[130px] min-h-[45px] whitespace-nowrap'
                                variant={'secondaryOutline'}
                                onClick={() => handleAuthorizeExpense(ExpenseStatus.denied, true)}
                                loading={declinedButtonClicked && isLoading}
                                disabled={isAuthorizationLoading || isRefetchingExpense || isLoading}
                            >
                                Decline
                            </Button>
                            <TooltipProvider delayDuration={10}>
                                <Menu as={'animate'} className={'relative'}>
                                    <Menu.Button
                                        disabled={
                                            isRefetchingExpense ||
                                            (approveButtonClicked && isLoading) ||
                                            isAuthorizationLoading
                                        }
                                        className={
                                            'disable:opacity-60 bg-brand text-white rounded-md text-sm p-3 px-3 flex items-center gap-2'
                                        }
                                    >
                                        Approve Expense
                                        {((approveButtonClicked && isLoading) || isAuthorizationLoading) && (
                                            <CgSpinner className='animate-spin' size={15} />
                                        )}
                                    </Menu.Button>
                                    <Menu.Items
                                        className={
                                            'bottom-9 absolute bg-white border border-gray5 rounded-md w-full p-2 space-y-3'
                                        }
                                    >
                                        <Menu.Item>
                                            <Tooltip>
                                                <TooltipTrigger className='mr-2' type='button'>
                                                    <button
                                                        onClick={() => handleAuthorizeExpense(ExpenseStatus.approved)}
                                                        disabled={
                                                            !isPartOfApprover ||
                                                            isRefetchingExpense ||
                                                            (approveButtonClicked && isLoading) ||
                                                            isAuthorizationLoading
                                                        }
                                                        className='disabled:opacity-50 disabled:cursor-not-allowed text-xs font-medium text'
                                                    >
                                                        Approve (Policy)
                                                    </button>
                                                </TooltipTrigger>

                                                <TooltipContent>
                                                    <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                        Approve based on your role in this expense policy
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <Tooltip>
                                                <TooltipTrigger className='mr-2' type='button'>
                                                    <button
                                                        onClick={() =>
                                                            handleAuthorizeExpense(ExpenseStatus.approved, true)
                                                        }
                                                        className='text-xs font-medium disabled:opacity-50 disabled:cursor-not-allowed'
                                                        disabled={
                                                            isRefetchingExpense ||
                                                            (approveButtonClicked && isLoading) ||
                                                            isAuthorizationLoading
                                                        }
                                                    >
                                                        Approve (Override)
                                                    </button>
                                                </TooltipTrigger>

                                                <TooltipContent>
                                                    <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                        Approve as an authorized user outside this expense policy
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </Menu.Item>
                                    </Menu.Items>
                                </Menu>
                            </TooltipProvider>
                        </div>
                    ) : (
                        <></>
                    ))}

                {reimburseModal && (
                    <ReimburseExpenseModal
                        setShowModal={setReimburseModal}
                        accounts={submitterAccount}
                        expense={expense}
                    />
                )}
                {editModal && (
                    <ExpenseFormRoot
                        setShowModal={setEditModal}
                        selectedExpense={expense!}
                        categories={categories}
                        hasAPrimaryBank={!hasAPrimaryBank()}
                        edit={true}
                    />
                )}
            </div>
        </>
    )
}

export default OwnerAuthorizeExpensePermission
