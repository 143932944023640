import { NewMonthlySummary } from '@/services/transactions/types'
import { TransactionType, TransactionTypeType } from '@/types/transactions'
import { capitalizeText } from '@/utils/string'

export function calculateAverageTotal(data: NewMonthlySummary[]) {
    const { sumOutgoingTotal, count1 } = data.reduce(
        (accumulator, monthData) => {
            if (Object.prototype.hasOwnProperty.call(monthData, 'outgoingTotal')) {
                accumulator.sumOutgoingTotal += parseFloat(monthData.outgoingTotal!)
                accumulator.count1++
            }
            return accumulator
        },
        { sumOutgoingTotal: 0, count1: 0 }
    )
    const { sumIncomingTotal, count2 } = data.reduce(
        (accumulator, monthData) => {
            if (Object.prototype.hasOwnProperty.call(monthData, 'incomingTotal')) {
                accumulator.sumIncomingTotal += parseFloat(monthData.incomingTotal!)
                accumulator.count2++
            }
            return accumulator
        },
        { sumIncomingTotal: 0, count2: 0 }
    )

    const averageOutgoingTotal = count1 > 0 ? sumOutgoingTotal / count1 : 0
    const averageIncomingTotal = count2 > 0 ? sumIncomingTotal / count2 : 0

    return { averageOutgoingTotal, averageIncomingTotal }
}

export function getFullMonthName(abbreviatedMonth: string): string {
    const monthMap: Record<string, string> = {
        Jan: 'January',
        Feb: 'February',
        Mar: 'March',
        Apr: 'April',
        May: 'May',
        Jun: 'June',
        Jul: 'July',
        Aug: 'August',
        Sep: 'September',
        Oct: 'October',
        Nov: 'November',
        Dec: 'December',
    }

    return monthMap[abbreviatedMonth] || abbreviatedMonth
}

export function findMonthWithHighestTotal(data: NewMonthlySummary[]) {
    let highestOutgoingTotal = -Infinity
    let monthWithHighestOutgoingTotal: NewMonthlySummary | undefined

    data.forEach(monthData => {
        if (monthData.outgoingTotal !== undefined) {
            const outgoingTotal = parseFloat(monthData.outgoingTotal)
            if (outgoingTotal > highestOutgoingTotal) {
                highestOutgoingTotal = outgoingTotal
                monthWithHighestOutgoingTotal = monthData
            }
        }
    })
    let highestIncomingTotal = -Infinity
    let monthWithHighestIncomingTotal: NewMonthlySummary | undefined

    data.forEach(monthData => {
        if (monthData.incomingTotal !== undefined) {
            const incomingTotal = parseFloat(monthData.incomingTotal)
            if (incomingTotal > highestIncomingTotal) {
                highestIncomingTotal = incomingTotal
                monthWithHighestIncomingTotal = monthData
            }
        }
    })

    return { monthWithHighestOutgoingTotal, monthWithHighestIncomingTotal }
}

export function transactionDestination(data: TransactionType) {
    const vendorName = capitalizeText(data?.metadata?.recipient?.name)
    const userName = data?.credit?.account?.user
        ? capitalizeText(data?.credit?.account?.user?.first_name) +
          ' ' +
          capitalizeText(data?.credit?.account?.user?.last_name?.[0])
        : 'N/A'
    const orgName = capitalizeText(data?.owner?.org_name)
    const creditDetails = data?.credit
    const transactionType = data?.type

    const type = data?.type === TransactionTypeType.TRANSFER ? data?.description : data?.type

    const destination =
        transactionType === TransactionTypeType.INVOICE || transactionType === TransactionTypeType.WALLET_FUNDING
            ? orgName
            : transactionType === TransactionTypeType.SUBSCRIPTION ||
                transactionType === TransactionTypeType.WALLET_DEDUCTION
              ? 'Bizpend Technologies'
              : transactionType === TransactionTypeType.TRANSFER ||
                  transactionType === TransactionTypeType.ACCOUNT_FUNDING
                ? data?.recipient_type === 'vendors'
                    ? vendorName
                    : creditDetails?.financial_account_details?.account_name
                      ? `${creditDetails?.financial_account_details?.account_name} (${creditDetails?.financial_account?.nickname ?? creditDetails?.financial_account?.provider})`
                      : 'N/A'
                : userName

    return { destination, type }
}
