import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { Controller, useFormContext } from 'react-hook-form'
import { AccountingSetUpFormData } from './AccountingSetupModal'
import { useRef, useState } from 'react'
import { XLSX_SVG } from '@/assets/assets.export'
import { TbReload } from 'react-icons/tb'
import * as XLSX from 'xlsx'
import PreviewUploadedFieldsModal from './PreviewUploadedFieldsModal'
import { toastHandler } from '@/components/utils/Toast'

type Props = {
    closeModal?: () => void
    index: number
    addMoreField?: () => void
    fields: AccountingSetUpFormData['customFields']
    nextStep?: () => void
    edit?: boolean
    loading?: boolean
}

export enum FieldTypes {
    single = 'single',
    multiple = 'multiple',
}
const CreateCustomFieldsModal = ({
    closeModal,
    index,
    addMoreField,
    fields,
    nextStep,
    edit = false,
    loading,
}: Props) => {
    const { control, watch, setValue } = useFormContext()

    const fileRef = useRef<HTMLInputElement>(null)

    const [file, setFile] = useState<File | null>(null)
    const [previewModal, setPreviewModal] = useState(false)

    const handleCustomFieldUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.[0]) {
            setFile(e.target.files?.[0])
            handleFileUpload(e.target.files?.[0])
        }
    }

    const handleFileUpload = (file: File): void => {
        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
            if (e.target && e.target.result) {
                const data = new Uint8Array(e.target.result as ArrayBuffer)
                const workbook = XLSX.read(data, { type: 'array' })
                const worksheet = workbook.Sheets[workbook.SheetNames[0]]
                const jsonData: { Field: string }[] = XLSX.utils.sheet_to_json(worksheet)

                setValue(
                    `customFields.${index}.uploadedField`,
                    jsonData.map(data => data.Field)
                )
            }
        }
        reader.readAsArrayBuffer(file)
    }

    const nameField = watch(`customFields.${index}.name`)
    const currentField = (watch(`customFields.${index}`) ?? {}) as AccountingSetUpFormData['customFields'][0]
    const isMultiple = watch(`customFields.${index}.type`) === FieldTypes.multiple

    return (
        <section className='min-h-[15rem] w-screen lg:w-[33rem] overflow-scroll '>
            <div className='flex items-center border-b border-[#DADCE0] p-2 px-4 justify-between'>
                <h2 className='text-center text-base font-medium opacity-80'>Set and confirm transactions table</h2>
                <CancelIcon className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer' onClick={() => closeModal?.()} />
            </div>
            <div className='px-3 lg:px-5 p-5'>
                {[{ ...fields[index] }]?.map(field => {
                    return (
                        <div
                            key={field?.name ?? Math.random()}
                            className='flex flex-col gap-3 border border-gray5 rounded-lg p-3'
                        >
                            <div className='flex gap-4'>
                                <fieldset className='flex-1'>
                                    <Controller
                                        name={`customFields.${index}.name`}
                                        rules={{
                                            required: true,
                                            minLength: 2,
                                        }}
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <Input
                                                    {...field}
                                                    value={currentField.name}
                                                    onChange={field.onChange}
                                                    className='h-12 border-gray5 flex-1'
                                                    placeholder='Enter field name'
                                                />
                                            )
                                        }}
                                    />
                                </fieldset>

                                <fieldset className='flex-1'>
                                    <Controller
                                        name={`customFields.${index}.type`}
                                        rules={{
                                            required: true,
                                            minLength: 2,
                                        }}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                value={currentField.type}
                                                name='type'
                                                onValueChange={data => field.onChange(data)}
                                            >
                                                <SelectTrigger className='h-12 border-gray5 min-w-full'>
                                                    <SelectValue placeholder={'Select field type'} />
                                                </SelectTrigger>
                                                <SelectContent className='w-[10rem]'>
                                                    <SelectItem value={FieldTypes.single}>Single</SelectItem>
                                                    <SelectItem value={FieldTypes.multiple}>Multiple</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        )}
                                    />
                                </fieldset>
                            </div>
                            <fieldset>
                                <Controller
                                    name={`customFields.${index}.description`}
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            value={currentField.description}
                                            className='h-12 border-gray5 flex-1'
                                            placeholder='Enter description'
                                        />
                                    )}
                                />
                            </fieldset>

                            <fieldset className='mt-1 flex items-center gap-3'>
                                <Controller
                                    name={`customFields.${index}.required`}
                                    rules={{
                                        required: true,
                                        minLength: 2,
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            onChange={e => {
                                                field.onChange(e.target.checked)
                                            }}
                                            checked={currentField.required}
                                            className='h-3 accent-brand w-3 border-gray5 cursor-pointer'
                                            type='checkbox'
                                            placeholder='Enter required'
                                        />
                                    )}
                                />
                                <label
                                    htmlFor={`customFields.${index}.require`}
                                    className='text-xs text-gray7 font-medium'
                                >
                                    Require this field when exporting your transactions
                                </label>
                            </fieldset>

                            {isMultiple && (
                                <div>
                                    <h4 className='text-sm border-b border-gray5 pb-2 font-medium mt-6'>
                                        Instructions
                                    </h4>
                                    <ul className='text-gray7 list-decimal list-inside space-y-1 mt-3 text-sm'>
                                        <li>
                                            Download csv template{' '}
                                            <a
                                                className='font-medium text-brand'
                                                href='/custom_fields_template.xlsx'
                                                download='Custom-Fields-Template.xlsx'
                                                target='_blank'
                                                rel='noreferrer'
                                            >
                                                here
                                            </a>{' '}
                                            to upload options for this field
                                        </li>

                                        <li>Open the template and fill it correctly</li>
                                        <li>Upload the completed csv once you’ve filled in the template.</li>
                                    </ul>
                                    <div className='border border-[#DADCE0] rounded-lg px-5 py-3 mt-5'>
                                        <input
                                            type='file'
                                            name='csv_file'
                                            id='csv_file'
                                            aria-label='Upload your csv file here'
                                            hidden
                                            ref={fileRef}
                                            accept='.csv, .xls, .xlsx'
                                            onChange={handleCustomFieldUpload}
                                        />

                                        {file?.name || edit ? (
                                            <div className='flex justify-center'>
                                                <div className='w-[10rem] border border-[#DADCE0] rounded-xl p-1.5 '>
                                                    <XLSX_SVG className=' w-20 mx-auto  p-2 opacity-80' />
                                                    <span className='text-center w-full text-xs block mb-3 break-words'>
                                                        {file?.name ?? `${nameField}'s file`}
                                                    </span>
                                                    <span className='flex gap-2 mx-2 items-center mb-3 mt-1 text-[#5E5E5E] justify-center'>
                                                        <button
                                                            type='button'
                                                            className='text-sm text-[#CF491E] flex items-center justify-center gap-1 cursor-pointer'
                                                            onClick={() => {
                                                                fileRef?.current?.click()
                                                            }}
                                                        >
                                                            <TbReload /> Change
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <label
                                                htmlFor='profile_img'
                                                className={`cursor-pointer font-medium text-[#A8A8A8] flex justify-between gap-2 w-full flex-col items-center`}
                                                onClick={() => fileRef?.current?.click()}
                                            >
                                                <>
                                                    <div className='text-xs font-normal'>
                                                        <span className='block  '>Click here to upload CSV file</span>
                                                        <span className='block  '>
                                                            only .csv, .xls files. Max size: 2MB
                                                        </span>
                                                    </div>

                                                    <button
                                                        className='px-2 text-brand text-sm'
                                                        onClick={() => {
                                                            fileRef?.current?.click()
                                                        }}
                                                    >
                                                        Import custom fields
                                                    </button>
                                                </>
                                            </label>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>

            <div className='border-t border-gray4 flex mt-6 justify-end items-center p-4 gap-4'>
                {!isMultiple && (
                    <>
                        {addMoreField && !edit && (
                            <Button
                                disabled={!nameField}
                                onClick={() => {
                                    addMoreField()
                                    toastHandler({ message: 'Field added successfully', state: 'success' })
                                }}
                                variant={'outline'}
                                size={'lg'}
                                className='text-xs min-w-[100px] px-3 disabled:cursor-not-allowed disabled:opacity-60 '
                            >
                                Save & add another field
                            </Button>
                        )}
                        <Button
                            disabled={!nameField}
                            loading={loading}
                            onClick={nextStep}
                            size={'lg'}
                            className='text-xs px-4'
                        >
                            {!edit ? ' Save & preview' : 'Update'}
                        </Button>
                    </>
                )}

                {isMultiple && (
                    <Button
                        disabled={!nameField || Number(currentField?.uploadedField?.length ?? 0) < 1}
                        onClick={() => setPreviewModal(true)}
                        size={'lg'}
                        loading={loading}
                        className='text-xs px-4'
                    >
                        Preview upload
                    </Button>
                )}
            </div>

            {previewModal && (
                <PreviewUploadedFieldsModal
                    name={nameField}
                    currentField={currentField}
                    closeModal={() => setPreviewModal(false)}
                    index={index}
                    loading={loading}
                    nextStep={() => {
                        nextStep?.()
                        setPreviewModal(false)
                    }}
                />
            )}
        </section>
    )
}

export default CreateCustomFieldsModal
