import { Button, Input } from '@/components'
import ProgressBar from '@/components/ProgressBar'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { useAuthContext, useCurrencyContext } from '@/context'
import { useLinkAccount } from '@/services/accounts/mutations'
import { useGetDedicatedBankAccounts } from '@/services/accounts/queries'
import { BankAccountConnectionEnum, DedicatedBankAccountType } from '@/types/accounts'
import { formatAmount } from '@/utils/money'
import MonoConnect from '@mono.co/connect.js'
import { AlertCircleIcon } from 'lucide-react'
import { useMemo, useState } from 'react'
import AccountCard from '../components/AccountCard'
import { prodEnvironment } from '@/utils/utils'
import notFound from '@/assets/not-found.svg'
import { AccountingEmptyState } from '@/assets/assets.export'

const LinkedAccounts = () => {
    const { selectedAccount } = useAuthContext()
    const { currencies } = useCurrencyContext()
    const [searchInput, setSearchInput] = useState('')
    const { data, isLoading, isRefetching } = useGetDedicatedBankAccounts({
        queryParams: { entity_id: selectedAccount?.org?.id as string },
    })
    const bankAccounts = useMemo(
        () =>
            data?.filter(account => account.connection_type === BankAccountConnectionEnum.LINK) ??
            ([] as DedicatedBankAccountType[]),
        [data]
    )

    const handleSearchAccounts = (text: string) => {
        if (!text) {
            return bankAccounts
        } else {
            return bankAccounts.filter(account => {
                const isNickNameMatch = account?.nickname?.toLowerCase().includes(text)
                const isAccountNumberMatch = account?.account_number?.toString().includes(text)
                const isNameMatch = account?.account_name?.toLowerCase().includes(text)
                const isBankNameMatch = account?.bank_name?.toLowerCase().includes(text)
                return isNameMatch || isAccountNumberMatch || isNickNameMatch || isBankNameMatch
            })
        }
    }

    const { mutate: linkAccount, isLoading: isLinkAccountLoading } = useLinkAccount({})

    const monoConnect = useMemo(() => {
        const monoInstance = new MonoConnect({
            onClose: () => console.log('Widget closed'),
            onLoad: () => console.log('Widget loaded successfully'),
            onSuccess: ({ code }: { code: string }) => {
                linkAccount({
                    code,
                })
            },
            key: import.meta.env.VITE_MONO_PUBLIC_KEY,
            ...(selectedAccount?.org.mono_customer_id && {
                data: {
                    customer: { id: selectedAccount?.org.mono_customer_id },
                },
            }),
        })

        monoInstance.setup()

        return monoInstance
    }, [])

    const totalAmount = bankAccounts?.reduce((acc, cur) => acc + cur?.balance, 0)

    const accountBalance = [
        { name: 'NGN total balance', amount: formatAmount(totalAmount), type: 'NGN' },
        { name: 'USD total balance', amount: '$0.00', type: 'USD', hidden: prodEnvironment() },
        { name: 'EUR total balance', amount: '$0.00', type: 'EUR', hidden: prodEnvironment() },
    ]

    if (isLoading) return <ProgressBar />

    return (
        <div className='my-5'>
            <div className='flex flex-col md:flex-row md:items-center justify-between gap-5'>
                <div>
                    <h2 className='text-lg font-semibold'>Bizpend Accounts</h2>
                    <p className='text-sm text-gray7'>
                        These are bank accounts linked to Bizpend for financial transactions
                    </p>
                </div>
                <Button
                    onClick={() => monoConnect.open()}
                    loading={isLinkAccountLoading}
                    disabled={isLinkAccountLoading}
                >
                    Link account with Mono
                </Button>
            </div>
            <div className='mt-5 bg-white rounded-xl p-3 md:p-5 flex flex-col lg:flex-row lg:items-center justify-between'>
                <div className='flex flex-col lg:flex-row max-w-3xl justify-between flex-1 gap-3 lg:gap-0'>
                    {accountBalance.map(balance => {
                        if (balance?.hidden) return
                        return (
                            <div className='first:border-none first:pl-0 lg:border-l lg:pl-7 border-gray5 w-full '>
                                <p className='flex items-center gap-1 text-gray7'>
                                    {currencies.find(currency => currency?.currency_code === balance.type)?.flag}
                                    {balance.name}
                                    <TooltipProvider delayDuration={10}>
                                        <Tooltip>
                                            <TooltipTrigger className='mr-2' type='button'>
                                                <AlertCircleIcon size={15} />
                                            </TooltipTrigger>

                                            <TooltipContent>
                                                <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded max-w-sm'>
                                                    The total amount is the sum of all bank account balances in{' '}
                                                    {balance.type}
                                                </p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </p>
                                <h3 className='text-3xl text-gray7 font-bold'>{balance.amount}</h3>
                            </div>
                        )
                    })}
                </div>
                <div className='mt-3 lg:mt-0'>
                    <Input
                        onChange={e => setSearchInput(e.target.value)}
                        placeholder='search accounts'
                        className='h-10 border-gray6 drop-shadow-sm'
                    />
                </div>
            </div>
            <div
                className={`mt-6 lg:mt-20 grid pb-16  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 gap-y-3 lg:gap-y-10 ${isRefetching ? 'opacity-60' : ''}`}
            >
                {handleSearchAccounts(searchInput.toLowerCase())?.map(account => (
                    <AccountCard bankAccounts={bankAccounts} account={account} />
                ))}
                {handleSearchAccounts(searchInput.toLowerCase())?.length === 0 && searchInput.length > 0 && (
                    <div
                        className={`'h-[450px] col-span-6 flex flex-col text-center justify-center items-center pb-10 ${
                            isRefetching ? 'opacity-30' : ''
                        }`}
                    >
                        <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                        <span className='text-xl font-bold text-[#5E5E5E] mt-3'>Sorry! No result found :(</span>
                        <span className='text-[#202020] text-sm lg:w-[45%] mx-auto mt-3 font-medium'>
                            Oops! It seems we couldn't find any matching results for your search. Please try again with
                            different keywords or refine your search fiter for better results.
                        </span>
                    </div>
                )}
                {handleSearchAccounts(searchInput.toLowerCase())?.length === 0 && searchInput.length === 0 && (
                    <div className='col-span-6 p-8  rounded-xl flex flex-col text-center justify-center items-center'>
                        <AccountingEmptyState className='mx-auto' />
                        <span className='text-xl font-medium text-[#5E5E5E] mt-3'>No linked accounts yet</span>
                        <span className='text-gray7 mt-2 text-sm w-[90%] lg:w-1/2 mx-auto'>
                            Link your first account to get started!
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LinkedAccounts
