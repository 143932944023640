import { Button } from '@/components'
import { useAuthContext, useExpenseContext } from '@/context'
import { ExpenseType } from '@/types/expense'
import { ExpenseStatus } from '../const'
import { useState } from 'react'
import { toastHandler } from '@/components/utils/Toast'
import ReimburseExpenseModal from '@/components/Modals/Expense/ReimburseExpense/ReimburseExpenseMondal'
import { UserAccount } from '@/services/payout/types'
import { useMutateSubmitDraftExpense, useMutationAuthorizeExpense } from '@/services/expenses/mutations'
import { IsOwner } from '@/utils/permissions'
import ExpenseFormRoot from '@/components/Modals/Expense/CreateExpense/ExpenseFormRoot'
import { expenseSidebarPermissions } from '@/layout/LayoutComponents/utils'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'

type Props = {
    expense: ExpenseType
    hasAPrimaryBank: () => boolean
    submitterAccount: UserAccount[]
    setExpenseDetails: React.Dispatch<React.SetStateAction<ExpenseType>>
    isRefetchingExpense: boolean
}

export default function ExpenseAuthorizePermission({
    expense,
    hasAPrimaryBank,
    submitterAccount,
    setExpenseDetails,
    isRefetchingExpense,
}: Props) {
    const [reimburseModal, setReimburseModal] = useState(false)
    const [approveButtonClicked, setApproveButtonClicked] = useState(false)
    const [declinedButtonClicked, setDeclinedButtonClicked] = useState(false)
    const { editModal, setEditModal, categories } = useExpenseContext()
    const hasPermission = useHasAccessPermisson(expenseSidebarPermissions)

    const { mutate: submitDraftExpense, isLoading: isSubmitting } = useMutateSubmitDraftExpense({
        queryParams: { id: expense?.id as string },
        onSuccess: data => {
            toastHandler({
                message: 'Draft submitted',
                state: 'success',
            })
            setExpenseDetails(prev => ({ ...prev, status: data?.status }))
        },
    })
    const { mutate: authorizeExpense, isLoading: isAuthorizationLoading } = useMutationAuthorizeExpense({
        queryParams: {
            id: expense?.id,
        },
        refetch: [['/expense-id']],
        onSuccess(data) {
            setExpenseDetails(prev => ({ ...prev, status: data?.status }))
            setApproveButtonClicked(false)
            setDeclinedButtonClicked(false)
        },
    })

    const handleAccountCheck = () => {
        if (hasAPrimaryBank()) {
            setReimburseModal(true)
            return
        }
        toastHandler({ message: 'User does not have any bank as primary', state: 'error' })
    }

    const { selectedAccount } = useAuthContext()

    const handleAuthorizeExpense = (status: string) => {
        if (status === ExpenseStatus.approved) {
            setApproveButtonClicked(true)
        }
        if (status === ExpenseStatus.denied) {
            setDeclinedButtonClicked(true)
        }

        authorizeExpense(
            { approve: status === ExpenseStatus.approved },
            {
                onSuccess: () => {
                    toastHandler({
                        message: status === ExpenseStatus.approved ? 'Expense Approved' : 'Expense Declined',
                        state: 'success',
                    })
                },
            }
        )
    }
    const isApprovedByMe = expense.approvers?.some(
        approver => approver?.account?.id === selectedAccount?.id && approver?.approved
    )

    return (
        <>
            <div className='flex flex-col lg:flex-row flex-1'>
                {
                    <EditExpenseButtonAction
                        expense={expense}
                        setEditModal={setEditModal}
                        isRefetchingExpense={isAuthorizationLoading || isRefetchingExpense}
                    />
                }

                {expense?.status === ExpenseStatus.draft && (
                    <Button
                        loading={isSubmitting}
                        disabled={isAuthorizationLoading || isRefetchingExpense}
                        onClick={() => submitDraftExpense({})}
                        className='bg-brand  min-w-[130px] min-h-[45px]'
                    >
                        Submit Expense
                    </Button>
                )}
                {expense?.status !== ExpenseStatus.draft &&
                    hasPermission &&
                    (expense?.reimbursable &&
                    (expense?.status === ExpenseStatus.approved || expense?.status === ExpenseStatus.processing) &&
                    isApprovedByMe ? (
                        <div className='flex justify-center mb-8 flex-1  '>
                            <Button
                                onClick={handleAccountCheck}
                                className=' w-96 bg-[#19C37D] hover:bg-[#19C37D] hover:opacity-90 text-white'
                                disabled={expense?.status === ExpenseStatus.processing || isRefetchingExpense}
                            >
                                {expense?.status === ExpenseStatus.processing ? 'Processing' : 'Reimburse'} expense
                            </Button>
                        </div>
                    ) : (expense?.status === ExpenseStatus.pending ||
                          expense?.status === ExpenseStatus.partialApproval) &&
                      !isApprovedByMe ? (
                        <div className='flex flex-1 flex-col lg:flex-row gap-8 mb-8 justify-between'>
                            <div className='flex gap-3 justify-end flex-1'>
                                <Button
                                    className='lg:mr-4  min-w-[130px] min-h-[45px]'
                                    variant={'secondaryOutline'}
                                    onClick={() => handleAuthorizeExpense(ExpenseStatus.denied)}
                                    loading={declinedButtonClicked && isAuthorizationLoading}
                                    disabled={isAuthorizationLoading || isRefetchingExpense}
                                >
                                    Decline
                                </Button>

                                <Button
                                    loading={approveButtonClicked && isAuthorizationLoading}
                                    onClick={() => handleAuthorizeExpense(ExpenseStatus.approved)}
                                    disabled={isAuthorizationLoading || isRefetchingExpense}
                                    className='bg-brand  min-w-[130px] min-h-[45px]'
                                >
                                    Approve
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <></>
                    ))}
                {reimburseModal && (
                    <ReimburseExpenseModal
                        setShowModal={setReimburseModal}
                        accounts={submitterAccount}
                        expense={expense}
                    />
                )}

                {editModal && (
                    <ExpenseFormRoot
                        setShowModal={setEditModal}
                        selectedExpense={expense!}
                        categories={categories}
                        hasAPrimaryBank={!hasAPrimaryBank()}
                        edit={true}
                    />
                )}
            </div>
        </>
    )
}

export const EditExpenseButtonAction = ({
    expense,
    setEditModal,
    isRefetchingExpense,
}: {
    expense: ExpenseType
    setEditModal: React.Dispatch<React.SetStateAction<boolean>>
    isRefetchingExpense: boolean
}) => {
    const { selectedAccount } = useAuthContext()

    const isExpenseCreator =
        expense?.submitter?._id ?? expense?.submitter?.id !== selectedAccount?._id ?? selectedAccount?.id

    const isPartOfApprover = expense.approvers?.some(
        approver => approver?.account?.id === selectedAccount?.id && !approver?.approved
    )

    return (expense?.status === ExpenseStatus.pending || expense?.status === ExpenseStatus.draft) &&
        (isExpenseCreator || isPartOfApprover || IsOwner()) ? (
        <div className='flex-1'>
            <Button
                className='w-full lg:w-auto lg:min-w-[130px] min-h-[45px] mb-2 lg:mb-0 disabled:opacity-60'
                onClick={() => setEditModal(true)}
                variant={'outline'}
                disabled={isRefetchingExpense}
            >
                Edit
            </Button>
        </div>
    ) : (
        <></>
    )
}
