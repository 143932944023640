import { Edit } from 'lucide-react'
import { useMemo, useState } from 'react'
// import { useBudgetContext } from '@/context'
import { motion } from 'framer-motion'
import { BulbInfo, CalendarIcon, CrownLogo, GreaterThan } from '@/assets/assets.export'
import { useNavigate, useParams } from 'react-router-dom'
import { formatAmountNoSymbol } from '@/utils/money'
import { ComputePercent } from '../utils'
import { useGetOrgBudgetById } from '@/services/budget/queries'
import ProgressBar from '@/components/ProgressBar'
import CreateBudgetModal from '@/components/Modals/Budget/CreateBudgetRoot'
import TransactionsLinkedToBudget from './TransactionsLinkedToBudget'
import { capitalizeText } from '@/utils/string'
import { format, parseISO } from 'date-fns'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { useAuthContext, useCurrencyContext } from '@/context'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components'
import { noop } from '@tanstack/react-table'
import { BudgetUsers } from './BudgetUsers'
import { EmployeeType } from '@/types/org'
import { Menu } from '@headlessui/react'

const BudgetDetails = () => {
    const [createModal, setCreateModal] = useState(false)
    const [tab, setTab] = useState('Budget users')
    const { selectedAccount } = useAuthContext()
    const { selectedCurrencyInfo } = useCurrencyContext()

    const navigate = useNavigate()
    const { budget_id } = useParams()

    const { data: budGetByIdData, isLoading } = useGetOrgBudgetById({
        queryParams: {
            budget_id: budget_id as string,
        },
    })
    const getNextDate = (startDate: string, option: string) => {
        const startDateParsed = parseISO(startDate) // Parse the start date string into a Date object

        const nextDate = new Date(startDateParsed)

        if (option === 'weekly') {
            nextDate.setDate(startDateParsed.getDate() + 7)
        } else if (option === 'monthly') {
            nextDate.setMonth(startDateParsed.getMonth() + 1)
        }

        return nextDate
    }

    const budgetObj = useMemo(() => budGetByIdData, [budGetByIdData])
    const isBudgetManager = budgetObj?.manager.some(manager => manager?._id === selectedAccount?.id)
    const currencySymbol = selectedCurrencyInfo?.(budgetObj?.currency?._id)?.symbol

    if (isLoading) return <ProgressBar />

    const { bgColor, color, percentage } = ComputePercent(budgetObj!)
    return (
        <>
            <section className='bg-offwhite pb-[1rem] px-2 lg:px-0'>
                {/* <div className='w-full py-6 block'> */}
                <section className='font-medium py-5 pl-[.5rem] lg:pl-[2.9rem] mx-auto text-base flex gap-1 mb-2'>
                    <span onClick={() => navigate('/dashboard/budget')} className='cursor-pointer'>
                        Budget lists
                    </span>
                    <span className='text-[#838383] flex gap-1'>
                        {' '}
                        <GreaterThan className='scale-[0.75]' />
                        {budgetObj?.name}
                    </span>
                </section>
                <div className='lg:w-[93%] mx-auto'>
                    <div className='bg-white rounded-2xl px-4 lg:px-6 pt-6 pb-[20px]'>
                        <div className='flex justify-between items-center gap-3'>
                            <div className='flex justify-between items-center w-11/12'>
                                <div className='flex items-center gap-2 relative'>
                                    <h1 className='text-[20px] font-semibold'>{budgetObj?.name}</h1>
                                    {isBudgetManager && (
                                        <TooltipProvider delayDuration={10}>
                                            <Tooltip>
                                                <TooltipTrigger>
                                                    <Edit
                                                        className='cursor-pointer w-4 h-4'
                                                        color='#838383'
                                                        onClick={() => {
                                                            setCreateModal(true)
                                                        }}
                                                    />
                                                </TooltipTrigger>
                                                <TooltipContent className=''>
                                                    <p className='bg-[#EFEFF9] p-2 text-[#202020] text-xs rounded'>
                                                        Edit budget
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    )}
                                </div>
                                <div className='flex items-center gap-4'>
                                    <span
                                        className='px-3 py-1 rounded-full text-base'
                                        style={{ color: color, backgroundColor: bgColor }}
                                    >
                                        {percentage.toFixed(1)}%
                                    </span>
                                    <div className='bg-[#fff] border border-[#DADCE0] rounded-lg'>
                                        <Select onValueChange={noop} value='7 days'>
                                            <SelectTrigger className='w-full border-none'>
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent className='w-[9rem]'>
                                                <SelectItem value='7 days'>This month</SelectItem>
                                                <SelectItem value='1 month'>Last Month</SelectItem>
                                                <SelectItem value='3 months'>Last 3 Months</SelectItem>
                                                <SelectItem value='6 months'>Last 6 Months</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className='relative'>
                                <Menu>
                                    <Menu.Button id='budget-info'>
                                        <BulbInfo />
                                    </Menu.Button>
                                    <Menu.Items className='w-[285px] absolute right-0 top-12 bg-white flex flex-col gap-[10px] shadow-2xl rounded-xl z-[100] p-4  md:min-w-max max-w-2xl '>
                                        <div className=''>
                                            <p className='font-semibold text-sm'>Manager (s)</p>
                                            <p className='mt-1 text-xs text-gray7 w-[260px]'>
                                                {budgetObj?.manager.map(
                                                    (manager, i) =>
                                                        ` ${manager.user.first_name} ${manager.user.last_name}${i !== budgetObj.manager.length - 1 ? ',' : ''}`
                                                )}
                                            </p>
                                        </div>
                                        <div className=''>
                                            <p className='font-semibold text-sm'>Budget users</p>
                                            <p className='text-brand font-medium text-xs'>
                                                {budgetObj?.account_type === 'All'
                                                    ? 'All users'
                                                    : budgetObj?.team?.name}
                                            </p>
                                        </div>
                                        <div className='grid grid-cols-2 font-semibold text-sm gap-3'>
                                            <div className=''>
                                                <p>Created</p>
                                                <p className='text-xs font-medium text-gray7'>
                                                    {format(new Date(budgetObj?.createdAt as string), 'dd/MM/yyyy')}
                                                </p>
                                            </div>
                                            <div className=''>
                                                <p>Expiration date</p>
                                                <p className='text-xs font-medium text-gray7'>
                                                    {budgetObj?.reset === 'one-off'
                                                        ? format(new Date(budgetObj?.end_date as string), 'dd/MM/yyyy')
                                                        : 'N/A'}
                                                </p>
                                            </div>
                                            <div className=''>
                                                <p>Renewal</p>
                                                <p className='text-xs font-medium text-gray7'>
                                                    {capitalizeText(budgetObj?.reset)}
                                                </p>
                                            </div>
                                            <div className=''>
                                                <p>Last updated</p>
                                                <p className='text-xs font-medium text-gray7'>
                                                    {format(new Date(budgetObj?.updatedAt as string), 'dd/MM/yyyy')}
                                                </p>
                                            </div>
                                        </div>
                                    </Menu.Items>
                                </Menu>
                            </div>
                        </div>
                        <div className='flex items-center justify-between flex-wrap mt-5'>
                            <div className='flex items-center gap-10 flex-wrap'>
                                <div className=''>
                                    <h2 className='text-[#5E5E5E] mb-2 font-medium'>Allocated</h2>
                                    <p className='font-medium text-xl'>
                                        {currencySymbol}
                                        {formatAmountNoSymbol(budgetObj?.allocated_amount)}
                                    </p>
                                </div>
                                <div className=''>
                                    <h2 className='text-[#5E5E5E] mb-2 font-medium'>Spent</h2>
                                    <p className='text-xl'>
                                        {currencySymbol}
                                        {formatAmountNoSymbol(
                                            Number(budgetObj?.allocated_amount) - Number(budgetObj?.available_amount)
                                        )}
                                    </p>
                                </div>
                                <div className=''>
                                    <h2 className='text-[#5E5E5E] mb-2 font-medium'>Available</h2>
                                    <p className='text-xl'>
                                        {budgetObj &&
                                            `${currencySymbol}${formatAmountNoSymbol(budgetObj?.available_amount)}`}
                                    </p>
                                </div>

                                <div className='hidden'>
                                    <h2 className='text-[#5E5E5E] mb-2'>Budget Users</h2>
                                    <p className='text-xl'>{budgetObj?.team?.name ?? 'All'}</p>
                                </div>
                            </div>
                            <div className='gap-8 mt-5 lg:mt-0 hidden'>
                                {
                                    <div className=''>
                                        <TooltipProvider delayDuration={1}>
                                            <Tooltip>
                                                <TooltipTrigger className='mr-4'>
                                                    <h2 className='text-[#5E5E5E] mb-2 flex items-center'>
                                                        <CalendarIcon className='mr-2' />
                                                        {budgetObj?.reset === 'one-off' ? 'Expires' : 'Next Renewal'}
                                                    </h2>
                                                </TooltipTrigger>

                                                <TooltipContent className=''>
                                                    <p className='bg-[#FFF9EB] p-2 text-[#202020] text-sm rounded'>
                                                        {budgetObj?.reset === 'one-off'
                                                            ? 'Indicates when the budget is set to expire'
                                                            : '  Indicates when the budget is set to renew'}
                                                    </p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                        <p className='font-bold text-lg'>
                                            {budgetObj?.end_date && format(new Date(budgetObj?.end_date), 'dd/MM/yyyy')}
                                            {budgetObj?.createdAt && budgetObj?.reset !== 'one-off'
                                                ? format(
                                                      getNextDate(
                                                          budgetObj ? budgetObj?.createdAt : '',
                                                          budgetObj ? budgetObj?.reset : ''
                                                      ),
                                                      'dd/MM/yyyy'
                                                  )
                                                : ''}
                                        </p>
                                    </div>
                                }
                                <div className=''>
                                    <TooltipProvider delayDuration={10}>
                                        <Tooltip>
                                            <TooltipTrigger className='mr-4'>
                                                <h2 className='text-[#5E5E5E] mb-2 flex items-center'>
                                                    <CrownLogo className='mr-2 scale-[1.2]' />
                                                    Manager
                                                </h2>
                                            </TooltipTrigger>

                                            <TooltipContent className=''>
                                                <p className='bg-[#FFF9EB] p-2 text-[#202020] text-sm rounded'>
                                                    The budget manager(s) has exclusive budget authority.
                                                </p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>

                                    <p className='font-bold text-lg'>
                                        {budgetObj?.manager?.map?.(manager => (
                                            <span className='block'>
                                                {capitalizeText(manager?.user?.first_name)}{' '}
                                                {capitalizeText(manager?.user?.last_name)}
                                            </span>
                                        ))}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-6 bg-white p-6 rounded-[20px]'>
                        <div className='flex sm:gap-2 w-[8rem] sm:w-[12rem] rounded-lg text-xs sm:text-base whitespace-nowrap'>
                            {['Budget users', 'Transactions'].map(option => (
                                <div
                                    className={`${'bg-#F9F9F9 text-white'}bg-[#F2F2F2] px-2 py-1 rounded cursor-pointer relative w-full`}
                                    onClick={() => setTab(option)}
                                >
                                    {tab == option && (
                                        <motion.span
                                            layoutId='expense_timeline__animation'
                                            className='bg-gradient-to-bl from-[#F2F2F2] to-[#F2F2F2] rounded-md z-0 text-center text-white inset-0 absolute inline-block w-full h-full '
                                        />
                                    )}
                                    <motion.span
                                        layout='position'
                                        className={`z-20 sticky ${tab === option ? 'text-[#202020] font-semibold' : 'text-gray7'}`}
                                    >
                                        {option}
                                    </motion.span>
                                </div>
                            ))}
                        </div>
                        {tab === 'Budget users' && <BudgetUsers users={budGetByIdData?.accounts as EmployeeType[]} />}
                        {tab === 'Transactions' && <TransactionsLinkedToBudget />}
                    </div>

                    {/* </div> */}
                </div>
            </section>

            {createModal && (
                <CreateBudgetModal updateBudget={true} budgetProps={budgetObj} closeModal={setCreateModal} />
            )}
        </>
    )
}

export default BudgetDetails
