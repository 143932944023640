import { Button } from '@/components'
import DeclineInvoicePrompt from '@/components/Modals/Invoice/DeclineInvoicePrompt'
import DeleteDraftInvoicePrompt from '@/components/Modals/Invoice/DeleleteDraftInvoicePrompt'
import SaveSendModal from '@/components/Modals/Invoice/SaveSendModal'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useGetOrgCustomers } from '@/services/customers/queries'
import { useApproveInvoice } from '@/services/invoice/mutations'
import { Invoice, InvoiceStatus } from '@/types/invoice'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
    invoice?: Invoice

    setRecordPayment: (l: boolean) => void
    setEditModal: (l: boolean) => void
}
const InvoicePermissionsWrapper = ({ invoice, setRecordPayment, setEditModal }: Props) => {
    const { selectedAccount } = useAuthContext()
    const [sendinvoiceModal, setSendInvoiceModal] = useState(false)
    const [declineInvoicePrompt, setDelineInvoicePrompt] = useState(false)
    const [deleteDraftInvoicePrompt, setDeleteDraftInvoicePrompt] = useState(false)

    const { data: org_customers } = useGetOrgCustomers({
        queryParams: {
            org: selectedAccount?.org?.id as string,
        },
    })
    const orgCustomers = useMemo(() => org_customers?.customers ?? [], [org_customers])

    const { mutate: approveInvoiceFn, isLoading } = useApproveInvoice({
        onSuccess: () => {
            toastHandler({ message: 'Invoice Approved', state: 'success' })
        },
    })

    const invoiceApprovers = invoice?.approvers ?? []
    const isApprover = invoiceApprovers?.some(
        approver => (approver?.account?.id ?? approver?.account?._id) === (selectedAccount?.id ?? selectedAccount?._id)
    )
    const canEditInvoice =
        (isApprover ||
            (invoice?.issuer?.id ?? invoice?.issuer?._id) === (selectedAccount?.id ?? selectedAccount?._id)) &&
        (invoice?.status === InvoiceStatus.DRAFT || invoice?.status === InvoiceStatus.PENDING)

    const isInvoiceSent =
        invoice?.status === InvoiceStatus.SENT ||
        invoice?.status === InvoiceStatus.PARTLY_PAID ||
        invoice?.status === InvoiceStatus.PARTIALLY_PAID

    const canSendInvoice = invoiceApprovers?.every(approver => approver?.approved)

    const canAuthorizeInvoice =
        isApprover &&
        (invoice?.status === InvoiceStatus?.DRAFT ||
            invoice?.status === InvoiceStatus.PENDING ||
            invoice?.status === InvoiceStatus.OVERDUE)
    const navigate = useNavigate()

    if (invoice?.status === InvoiceStatus?.PAID) return <></>
    return (
        <div className='flex justify-end flex-col lg:flex-row'>
            {canEditInvoice && (
                <div className='flex-1 flex  gap-8'>
                    {invoice?.status === InvoiceStatus.DRAFT && (
                        <Button
                            className=' mx-auto xs:mx-0 xs:min-w-[130px] min-h-[35px] '
                            onClick={() => {
                                setDeleteDraftInvoicePrompt(true)
                            }}
                            variant={'error'}
                        >
                            Delete draft
                        </Button>
                    )}
                    <Button
                        className=' mx-auto xs:mx-0 xs:min-w-[130px] min-h-[35px] border border-gray8'
                        onClick={() => {
                            setEditModal(true)
                            navigate(`/dashboard/invoice/update_invoice/${invoice?._id ?? invoice?.id}`)
                        }}
                        variant={'outline'}
                    >
                        Edit
                    </Button>
                </div>
            )}
            {canSendInvoice && (
                <div className='flex flex-wrap gap-3 lg:gap-6 justify-between w-full lg:w-auto mt-6 lg:mt-0 lg:justify-center mb-8 mx-auto'>
                    {invoice?.status === InvoiceStatus.APPROVED && (
                        <Button
                            onClick={() => setSendInvoiceModal(true)}
                            className='bg-transparent text-[#5e5e5e] hover:bg-transparent min-w-[120px] border border-gray8  min-h-12 text-sm'
                        >
                            Send invoice
                        </Button>
                    )}
                    {isInvoiceSent && (
                        <Button
                            className=' bg-[#19C37D] hover:bg-[#19C37D] hover:opacity-90 text-white px-0 min-w-[46%] lg:min-w-[120px] min-h-12 text-sm'
                            onClick={() => setRecordPayment(true)}
                        >
                            Mark as paid
                        </Button>
                    )}
                </div>
            )}
            {canAuthorizeInvoice && (
                <div className='flex flex-wrap gap-3 lg:gap-6 justify-between w-full lg:w-auto mt-6 lg:mt-0 lg:justify-center mb-8 mx-auto'>
                    <Button
                        disabled={isLoading}
                        onClick={() => setDelineInvoicePrompt(true)}
                        className='bg-transparent text-[#5e5e5e] hover:bg-transparent min-w-[120px] px-2 border-none min-h-12 text-sm'
                    >
                        Decline invoice
                    </Button>

                    <Button
                        loading={isLoading}
                        onClick={() => approveInvoiceFn({ id: invoice?.id ?? invoice?._id })}
                        className='hover:opacity-90 text-white p-4 min-w-[46%] lg:min-w-[120px] min-h-12 text-sm '
                    >
                        Approve invoice
                    </Button>
                </div>
            )}

            {sendinvoiceModal && (
                <SaveSendModal
                    invoice={invoice}
                    closeModal={() => setSendInvoiceModal(false)}
                    orgCustomers={orgCustomers}
                />
            )}

            {declineInvoicePrompt && (
                <DeclineInvoicePrompt closeModal={() => setDelineInvoicePrompt(false)} invoice={invoice} />
            )}
            {deleteDraftInvoicePrompt && (
                <DeleteDraftInvoicePrompt
                    invoice_id={invoice?._id ?? invoice?.id ?? ''}
                    closeModal={() => {
                        setDeleteDraftInvoicePrompt(false)
                    }}
                />
            )}
        </div>
    )
}

export default InvoicePermissionsWrapper
