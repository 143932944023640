export const staticField = [
    {
        name: 'Destination / Merchant',
        desc: 'Where the transaction is directed.',
    },
    {
        name: 'Amount',
        desc: 'Total value of the transaction.',
    },
    {
        name: 'Date and Time',
        desc: 'When the transaction occurred.',
    },
    {
        name: 'Initiated By',
        desc: 'Person initiating the transaction.',
    },
    {
        name: 'Transaction Type',
        desc: 'Nature of the transaction (e.g., payment, refund).',
    },
    {
        name: 'Category ',
        desc: 'Related accounting account.',
    },
]
