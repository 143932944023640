import { RequestAccessConfirmationModal } from '@/components/Modals/Business-directory/RequestAccessConfirmationModal'
import ResendRequestAccessPrompt from '@/components/Modals/Business-directory/ResendRequestAccessPrompt'
import Table from '@/components/Table'
import TableActions from '@/components/Table/TableActions'
import StatusColor from '@/components/utils/StatusColor'
import { RequestAccessType } from '@/types/request-access'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useState } from 'react'

export default function DirectoryRequestAccessTable({ requestAccessData }: { requestAccessData: RequestAccessType[] }) {
    const columnHelper = createColumnHelper<RequestAccessType>()

    const [requestingCompany, setRequestingCompany] = useState<RequestAccessType>()
    const [authorizeRequestAccess, setAuthorizeRequestAccess] = useState<'approve' | 'delete'>('approve')
    const [openRequestAccessModal, setOpenRequestAccessModal] = useState(false)
    const [openResendPrompt, setOpenResendPrompt] = useState(false)
    console.log(requestAccessData)
    const columns: ColumnDef<RequestAccessType, any>[] = [
        columnHelper.accessor('org_name', {
            header: () => {
                return <p>Org name</p>
            },
            cell: cell => <span className='text-gray7 font-medium'>{cell.renderValue()}</span>,
            enableSorting: false,
        }),
        columnHelper.accessor('first_name', {
            header: () => {
                return <p>Access User</p>
            },
            cell: cell => (
                <span className='text-gray7 font-medium'>{`${cell.renderValue()} ${cell.row.original?.last_name}`}</span>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('email', {
            header: () => {
                return <p>Email</p>
            },
            cell: cell => <span className='text-gray7 font-medium'>{`${cell.renderValue()}`}</span>,
            enableSorting: true,
        }),

        columnHelper.accessor('website', {
            header: () => {
                return <p>Website</p>
            },
            cell: cell => <span className='text-gray7 font-medium'>{`${cell.renderValue() ?? 'N/A'}`}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('phone', {
            header: () => <p>Phone</p>,
            cell: cell => <span className='text-errorRed'>{cell.renderValue()}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('createdAt', {
            header: () => <p>Date requested</p>,
            cell: cell => (
                <span className='text-gray7 font-medium'>
                    {cell.renderValue() && format(new Date(cell.renderValue()), 'dd MMM, yyyy')}
                </span>
            ),

            enableSorting: false,
        }),
        columnHelper.accessor('status', {
            header: () => <p>Status</p>,
            cell: cell => <StatusColor status={cell.renderValue().toLowerCase()} />,
            enableSorting: false,
        }),
        columnHelper.display({
            id: 'actions',
            cell: cell => (
                <TableActions
                    actions={[
                        {
                            label: 'Approve Request',
                            method: () => {
                                setRequestingCompany?.(cell?.row?.original)
                                setAuthorizeRequestAccess('approve')
                                setOpenRequestAccessModal(true)
                            },
                            hidden: cell?.row.original?.status == 'ACTIVE',
                        },
                        {
                            label: 'Decline Request',
                            method: () => {
                                setRequestingCompany?.(cell?.row?.original)
                                setAuthorizeRequestAccess('delete')
                                setOpenRequestAccessModal(true)
                            },
                            hidden: cell?.row.original?.status == 'ACTIVE',
                        },
                        {
                            label: 'Resend approval email',
                            method: () => {
                                setRequestingCompany?.(cell?.row?.original)
                                setOpenResendPrompt(true)
                            },
                            hidden: cell?.row.original?.status == 'PENDING',
                        },
                    ]}
                />
            ),
        }),
    ]
    return (
        <div className='rounded-lg'>
            <Table
                data={requestAccessData}
                columns={columns}
                emptyStateTitle='No Access Requested'
                emptyStateDescription='No Access has been requested yet'
            />
            {openRequestAccessModal && (
                <RequestAccessConfirmationModal
                    closeModal={() => setOpenRequestAccessModal(false)}
                    requestType={authorizeRequestAccess}
                    accessRequest={requestingCompany}
                />
            )}

            {openResendPrompt && (
                <ResendRequestAccessPrompt
                    closeModal={() => setOpenResendPrompt(false)}
                    requestingCompany={requestingCompany}
                />
            )}
        </div>
    )
}
