import { CancelIcon, DeleteIcon } from '@/assets/assets.export'
import { Button } from '@/components/Button'
import { useFormContext } from 'react-hook-form'
import ModalWrapper from '../ModalWrapper'

type Props = {
    closeModal: () => void
    name: string
    nextStep?: () => void
    index?: number
    loading?: boolean
    currentField: { name: string; description?: string; required?: boolean; uploadedField?: string[] }
}

const PreviewUploadedFieldsModal = ({ closeModal, nextStep, name, currentField, index, loading }: Props) => {
    const { setValue } = useFormContext()

    return (
        <ModalWrapper>
            <section className='min-h-[15rem] w-[33rem] overflow-scroll '>
                <div className='flex items-center border-b border-[#DADCE0] p-2 px-4 justify-between'>
                    <h2 className='text-center text-base font-medium opacity-80'>Preview field options</h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <div className='p-5'>
                    <p className='text-sm mb-5 text-gray7'>
                        Preview options for <span className='font-semibold text-black'>{name}</span> field
                    </p>

                    <div className='mt-2'>
                        <table className='w-full leading-normal border-collapse text-xs'>
                            <thead></thead>
                            <tbody>
                                {currentField?.uploadedField?.map((field, i) => (
                                    <tr key={i}>
                                        <td className='border-gray5 px-5 h-12 border-b font-medium text-gray7 '>
                                            <span className='flex gap-2'>
                                                <span>{i + 1}. </span>
                                                <span>{field}</span>
                                            </span>
                                        </td>

                                        <td className='border-gray5 px-5 h-12 border-b  text-gray7 flex justify-end gap-4'>
                                            <button
                                                aria-label='delete'
                                                onClick={() => {
                                                    const remainingField = currentField?.uploadedField?.filter(
                                                        data => data !== field
                                                    )
                                                    setValue(`customFields.${index}.uploadedField`, remainingField)
                                                }}
                                            >
                                                <DeleteIcon className='w-7 h-7' />{' '}
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='border-t border-gray4 flex mt-6 justify-end items-center p-4 gap-4'>
                    <Button
                        onClick={closeModal}
                        variant={'outline'}
                        size={'lg'}
                        className='text-xs min-w-[100px] px-3 '
                    >
                        back
                    </Button>
                    <Button onClick={nextStep} loading={loading} size={'lg'} className='text-xs px-4'>
                        Confirm and save
                    </Button>
                </div>
            </section>
        </ModalWrapper>
    )
}

export default PreviewUploadedFieldsModal
