import { useAuthContext } from '@/context'
import { UserOrgAccount } from '@/types/org'
import { QueryCache } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { useEffect } from 'react'

export default function SwitchOrgHelper({ account }: { account: UserOrgAccount }) {
    const queryCache = new QueryCache({})

    const { setSelectedAccount } = useAuthContext()

    const handleSwitchAccount = () => {
        setSelectedAccount(account)
        queryCache.clear()
        window.location.replace('/dashboard/overview')
        // window.location.reload()
    }
    useEffect(() => {
        const timeout = setTimeout(() => {
            handleSwitchAccount()
        }, 3000)

        return () => {
            clearTimeout(timeout)
        }
    })
    return (
        <section className='fixed inset-0 h-screen w-screen bg-white z-50'>
            <div className=' h-full ml-auto'>
                <Loader2
                    size={50}
                    className='animate-spin mx-auto grid place-items-center h-full opacity-50 text-black/80'
                />
            </div>
        </section>
    )
}
