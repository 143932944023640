import { AiOutlineClose } from 'react-icons/ai'
import ModalWrapper from '../../ModalWrapper'
import { Button, Input, Textarea } from '@/components'
import { useEffect, useState } from 'react'

import { TbCurrencyNaira } from 'react-icons/tb'
import { Vendor } from '@/types/vendor'
import { calculateTransferFee, formatAmount, formatAmountWithoutSymbols } from '@/utils/money'
import TransferVendor2 from './TransferVendor2'
import { useSendTransferOtp } from '@/services/funding/mutation'
import { TransactionType } from '@/types/transactions'

type FlagTransactionProps = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    vendorDetails: Vendor
    transactionDetails?: TransactionType
}

const formEmptyState = {
    amount: '',
    memo: 'Transfer',
}
const TransferVendorModal = ({ closeModal, vendorDetails, transactionDetails }: FlagTransactionProps) => {
    const [formState, setFormState] = useState(formEmptyState)
    const [step, setStep] = useState(1)

    const { mutate: sendOtpFn } = useSendTransferOtp({})

    useEffect(() => {
        if (step === 2) {
            sendOtpFn({})
        }
    }, [step])
    useEffect(() => {
        if (transactionDetails) {
            setFormState(prevState => ({
                ...prevState,
                amount: transactionDetails?.debit?.amount.toString(),
            }))
        }
    }, [transactionDetails])

    return (
        <ModalWrapper
            variants={undefined}
            formId='vendorTransfer'
            closeModal={() => closeModal(false)}
            shouldConfirmClose
        >
            {step === 1 && (
                <div className='w-[27rem] max-h-[90vh] overflow-scroll '>
                    <div className='border-b border-[#DADCE0] py-4 px-2  flex justify-between'>
                        <div className='flex items-center justify-between h-full w-full px-3'>
                            <h2 className='font-semibold text-[#31254B] text-xl'>
                                {transactionDetails ? 'Repeat transaction' : `Send money `} -{' '}
                                {vendorDetails?.name ?? transactionDetails?.metadata?.recipient?.name}
                            </h2>
                            <div
                                className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                                onClick={() => closeModal(false)}
                            >
                                <AiOutlineClose fontSize={25} />
                            </div>
                        </div>
                    </div>

                    <form
                        id='vendorTransfer'
                        onSubmit={(e: React.FormEvent) => e.preventDefault()}
                        className='mt-1 pb-6'
                    >
                        <div className='p-[1.8rem] pt-3 pb-0 overflow-scroll'>
                            <div className={`flex flex-col gap-[1.6rem] mb-8`}>
                                <div className='mt-5 flex flex-col gap-2 mb-2'>
                                    <label htmlFor='firstName' className='text-[#202020] text-[14px] font-medium '>
                                        Amount<span className='text-[red]'>*</span>
                                    </label>

                                    <Input
                                        type={'text'}
                                        name={'amount'}
                                        value={formatAmount(formState?.amount as string).replaceAll('₦', '')}
                                        placeholder='Enter amount'
                                        className='border-[#DADCE0]  h-[3rem] disabled:bg-[#F9F9F9]'
                                        onChange={e => {
                                            const { name } = e.target
                                            let value = e.target.value

                                            value = formatAmountWithoutSymbols(value)

                                            setFormState(prevState => ({
                                                ...prevState,
                                                [name]: value,
                                            }))
                                        }}
                                        leftIcon={<TbCurrencyNaira fontSize={25} color={'#838383'} />}
                                    />
                                    <div className=''>
                                        <span className='text-[12px] text-[#838383] block'>
                                            Fee:{' '}
                                            {Number(formState.amount) > 0
                                                ? formatAmount(calculateTransferFee(formState.amount) * 100)
                                                : formatAmount(0)}
                                        </span>
                                        <span className='text-[12px] text-[#838383] block'>
                                            {' '}
                                            Transfer starts at ₦1,000 as the minimum amount.
                                        </span>
                                    </div>
                                </div>

                                <div className='flex flex-col flex-1 gap-3'>
                                    <label className='flex items-center gap-1'>Memo</label>
                                    <Textarea
                                        name='memo'
                                        placeholder='Reason for transfer'
                                        className='border border-[#DADCE0] pt-4 min-h-[7.5rem] text-base resize-none'
                                        value={formState.memo}
                                        onChange={e => {
                                            const { name, value } = e.target
                                            setFormState(prevState => ({
                                                ...prevState,
                                                [name]: value,
                                            }))
                                        }}
                                    />
                                </div>
                            </div>
                            <Button
                                className='w-full text-base font-normal mb-3 disabled:opacity-30'
                                onClick={() => {
                                    setStep(2)
                                }}
                                disabled={!(parseFloat(formState.amount) >= 100000) || formState.memo.length < 0}
                            >
                                Send money
                            </Button>
                        </div>
                    </form>
                </div>
            )}
            {step === 2 && (
                <TransferVendor2
                    vendorDetails={vendorDetails as Vendor}
                    transactionDetails={transactionDetails}
                    closeModal={closeModal}
                    setStep={setStep}
                    formState={formState}
                />
            )}
        </ModalWrapper>
    )
}

export default TransferVendorModal

// "65e4b885126496e747b524b4"
