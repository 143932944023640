import { Button } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import usePersistedState from '@/hooks/usePersistedState'
import { useSendTransferOtp, useTransferFunds } from '@/services/payout/mutations'
import { useGetOrgVendors } from '@/services/vendors/queries'
import { TransactionType } from '@/types/transactions'
import { Vendor } from '@/types/vendor'
import { calculateTransferFee, formatAmount } from '@/utils/money'
import { useEffect, useState } from 'react'

type TransferVendor2Props = {
    vendorDetails: Vendor
    transactionDetails?: TransactionType
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    formState: {
        amount: string
        memo: string
    }
    setStep: React.Dispatch<React.SetStateAction<number>>
}

const TransferVendor2 = ({
    vendorDetails,
    closeModal,
    formState,
    setStep,
    transactionDetails,
}: TransferVendor2Props) => {
    const [timer, setTimer] = usePersistedState({ key: 'payrollTimer', defaultValue: 120 })
    const { mutate: sendTransferOtpFn, isLoading: isResendingOtp } = useSendTransferOtp({})
    const [otp, setOtp] = useState('')
    const { selectedAccount } = useAuthContext()
    const { data: orgVendors } = useGetOrgVendors({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
        enabled: true,
    })

    const vendorDetailsFetched = orgVendors?.vendors?.find(
        vendor => vendor?.id === transactionDetails?.metadata.recipient._id
    )
    const vendorBankDeets = vendorDetailsFetched?.bankAccount
    const { mutate: transferFundsFn, isLoading: isTransferring } = useTransferFunds({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess() {
            toastHandler({ message: 'Processing payment', state: 'success' })
            closeModal(false)
        },
    })

    const feePlusAmount = calculateTransferFee(formState ? formState.amount : '0') + Number(formState?.amount) / 100
    const { bankAccount } = vendorDetails
    const handleTransferFund = () => {
        transferFundsFn({
            amount: Number(formState?.amount) / 100,
            fee: calculateTransferFee(formState ? formState.amount : '0'),
            recipient_type: 'vendors',
            recipient_accounts: [
                {
                    account: transactionDetails ? transactionDetails.metadata.recipient._id : vendorDetails.id,
                    amount: Number(formState.amount) / 100,
                    account_number: transactionDetails ? vendorBankDeets?.account_number : bankAccount.account_number,
                    bank_code: transactionDetails ? vendorBankDeets?.bank_code : bankAccount.bank_code,
                    bank_name: transactionDetails ? vendorBankDeets?.bank_name : bankAccount.bank_name,
                    narration: formState.memo,
                },
            ],
            memo: formState.memo,
            otp: otp,
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1)
            }
            if (timer === 0) {
                setTimer(0)
                clearInterval(interval)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })
    return (
        <div className='p-10 w-[33rem]'>
            <h1 className='text-xl font-semibold text-[#202020] mb-3'>Confirm Transfer</h1>
            <div className='bg-[#F9F9F9] rounded-xl p-5 pb-4  mb-8'>
                <div className='mb-4'>
                    <h3 className='text-[#5E5E5E] font-semibold'>Amount</h3>

                    <p className='text-xl font-medium'>{formatAmount(feePlusAmount * 100)}</p>
                </div>
                <h3 className='text-[#5E5E5E] font-semibold mb-3'>Receipient</h3>

                <div className='flex gap-5 gap-y-3 flex-wrap'>
                    <div>
                        <h3 className=' font-semibold'>Bank name</h3>
                        <p className='text-[#5E5E5E] text-base'>
                            {transactionDetails ? vendorBankDeets?.bank_name : bankAccount?.bank_name ?? 'N/A'}
                        </p>
                    </div>
                    <div>
                        <h3 className='font-semibold'>Account number</h3>
                        <p className='text-base text-[#5E5E5E]'>
                            {transactionDetails
                                ? vendorBankDeets?.account_number
                                : bankAccount?.account_number ?? ' N/A'}
                        </p>
                    </div>
                    <div>
                        <h3 className=' font-semibold'>Account name</h3>
                        <p className=' text-[#5E5E5E] text-base'>
                            {transactionDetails
                                ? transactionDetails.metadata.recipient.name
                                : vendorDetails?.name ?? 'N/A'}
                        </p>
                    </div>
                </div>
            </div>
            <section className=''>
                <p className='mt-[10px] font-medium text-[#202020] text-[14px]'>
                    An OTP has been sent to your email to verify this action. Kindly enter OTP to proceed
                </p>
                <form>
                    <div className='flex flex-col md:flex-row mt-6 '>
                        <div className='flex flex-col flex-1 gap-3'>
                            <label htmlFor='email' className='text-[#202020] text-[14px]'>
                                Enter OTP
                            </label>
                            <input
                                className='h-[3.5rem] text-dark rounded-md border border-gray1 focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none disabled:cursor-not-allowed'
                                type='number'
                                placeholder='Enter your OTP'
                                onChange={e => {
                                    const { value } = e.target
                                    setOtp(value)
                                }}
                            />
                        </div>
                    </div>
                </form>
                <p className='mt-4 text-sm font-medium'>
                    I did not receive OTP.{' '}
                    <button
                        className='text-[#454ADE] font-semibold disabled:text-[#A8A8A8]'
                        onClick={() => {
                            sendTransferOtpFn({})
                            setTimer(120)
                        }}
                        disabled={timer > 0}
                    >
                        {' '}
                        Resend
                    </button>{' '}
                    {timer !== 0 && <span className='text-[#A8A8A8]'>in {timer < 10 ? `0${timer}` : timer} secs</span>}
                </p>
            </section>
            <div className='flex justify-end gap-6 mt-12'>
                <Button
                    className='bg-transparent min-w-[100px] text-[#202020] hover:bg-transparent disabled:bg-transparent disabled:opacity-30'
                    onClick={() => {
                        setStep(1)
                    }}
                    disabled={isTransferring}
                >
                    Back
                </Button>
                <Button
                    className='min-w-[100px] px-4'
                    loading={isTransferring}
                    type='submit'
                    disabled={otp.length < 4 || isResendingOtp || isTransferring}
                    onClick={handleTransferFund}
                >
                    Confirm transfer
                </Button>
            </div>
        </div>
    )
}

export default TransferVendor2

/* {
    "account_number": "0253165202",
    "bank_name": "Wema Bank",
    "bank_code": "035",
    "accountType": "Vendor",
    "is_primary": true,
    "account": "65e4b885126496e747b524b4",
    "id": "65e4b885126496e747b524b2"
}
*/
