import { CustomFieldsType } from '@/types/accounting'
import { createQuery } from '../api/query'
import { QueryOrgCOARes, QueryOrgGlCodeRes } from './type'

export const glcodeKey = ['gl-code']
export const chartOfAccountKey = ['coa']
export const customFields = ['custom-fields']
export const coAKey = (id: string) => ['coa', id]

export const useGetGLCode = createQuery<QueryOrgGlCodeRes>({
    url: '/gl-code',
    key: glcodeKey,
})

export const useGetCOA = createQuery<QueryOrgCOARes>({
    url: '/accounting/accounts',
    key: coAKey,
})

export const useGetCustomFields = createQuery<CustomFieldsType[]>({
    url: '/accounting/custom-fields',
    key: customFields,
})
