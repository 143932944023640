import { addDays } from 'date-fns'

export const calculatePayrollSum = (array: any[], property: string, gross_salary?: any) => {
    if (array?.length < 1 || !array) return 0

    const total = array.reduce((accumulator, object) => {
        if (object?.amountType === 'percentage' || object?.type === 'percentage') {
            const percentage = Number(object[property]) / 100
            return Number(accumulator) + percentage * Number(gross_salary)
        }
        return Number(accumulator) + Number(object[property])
    }, 0)

    return total
}

export const calculateBonusAndDeductionSum = (array: any[], property: string, gross_salary?: any) => {
    if (array?.length < 1 || !array) return 0

    const total = array.reduce((accumulator, object) => {
        if (object?.amountType === 'percentage' || object?.type === 'percentage') {
            const percentage = Number(object[property])
            return Number(accumulator) + percentage * Number(gross_salary)
        }
        return Number(accumulator) + Number(object[property])
    }, 0)

    return total
}

export const dayOfMonth = [
    { name: '1st of every month', desc: '1' },
    { name: '21st of every month', desc: '21' },
    { name: '22nd of every month', desc: '22' },
    { name: '23rd of every month', desc: '23' },
    { name: '24th of every month', desc: '24' },
    { name: '25th of every month', desc: '25' },
    { name: '26th of every month', desc: '26' },
    { name: '27th of every month', desc: '27' },
    { name: '28th of every month', desc: '28' },
]

export const dayOfWeek = [
    { name: 'Every Monday', desc: 'monday' },
    { name: 'Every Tuesday', desc: 'tuesday' },
    { name: 'Every Wednesday', desc: 'wednesday' },
    { name: 'Every Thursday', desc: 'thursday' },
    { name: 'Every Friday', desc: 'friday' },
    { name: 'Every Saturday', desc: 'saturday' },
    { name: 'Every Sunday', desc: 'sunday' },
]

export const biWeekly = [
    { name: 'Every 2 Monday', desc: 'monday' },
    { name: 'Every 2 Tuesday', desc: 'tuesday' },
    { name: 'Every 2 Wednesday', desc: 'wednesday' },
    { name: 'Every 2 Thursday', desc: 'thursday' },
    { name: 'Every 2 Friday', desc: 'friday' },
    { name: 'Every 2 Saturday', desc: 'saturday' },
    { name: 'Every 2 Sunday', desc: 'sunday' },
]
export const weekdayIndex = (weekdayName: string) =>
    ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].indexOf(weekdayName)

export const getPayDates = (payroll_freq: string, pay_day: string | number, year?: number) => {
    const date_today = new Date()
    const startDate = new Date(year ?? date_today.getFullYear(), date_today.getMonth(), Number(pay_day))
    const lastDay = addDays(startDate, payroll_freq === 'monthly' ? 30 : payroll_freq === 'biweekly' ? 15 : 6)

    return { startDate, lastDay }
}
