import { OrgType, UserOrgAccount } from '@/types/org'
import { RequestAccessType } from '@/types/request-access'
import { RoleType } from '@/types/roles'
import { User } from '@/types/user'

export type UserResponse = {
    success: true
    data: User
}

export type LoginResponse = {
    user: User
    accounts: UserOrgAccount[]
    account: UserOrgAccount
}

export type RolesResponse = {
    success: true
    data: RoleType
}

export type OverviewInsight = {
    transactions: string
    wallet_balance: number
    total_expenses: string
    card_issued?: number
    no_of_employees?: number
    no_of_cards?: number
    teams?: number
    available_balance: number
    current_balance: number
}
export type UserTransactionExpenseType = {
    totalExpenses: number
    totalUserTeams: number
    numberOfTransactions: number
    totalExpenseAmount: number
    totalTransactionsAmount: number
}

export type Address = {
    country: string
    state: string
    addressLine_1: string
    city: string
    postalCode: string
}

export enum KYBVerificationStatus {
    VERIFIED = 'VERIFIED',
    IN_PROGRESS = 'IN_PROGRESS',
    UNVERIFIED = 'UNVERIFIED',
    REJECTED = 'REJECTED',
    AWAITING_APPROVAL = 'AWAITING_APPROVAL',
}

export type QueryOrgKYB = {
    org: OrgType
    verification_status: KYBVerificationStatus
    is_kyb_completed: boolean
    address: {
        main: Address
        registered: Address
    }
    business_email: string
    business_phone: string
    business_type: string
    number_of_employees: number
    cac_bn1: string
    cac_it_form: string
    cac_it_number: string
    certificate_of_business_name: string
    certificate_of_incorporation: string
    city: string
    country: string
    description: string
    industry: string
    sub_industry: string
    legal_business_name: string
    memorandum_of_association: string
    cac_status_report: string
    postal_code: string
    proof_of_address: string
    rc_number: string
    scuml_certificate: string
    state: string
    tax_identification_number: string
    updatedAt: string
    website: string
    bn_number: string
    id: string
    registration_date: Date
    officers: any[]
}

export type QueryOrgRequestAccessRes = RequestAccessType[]
