import { Button, Input } from '@/components'
import { useAddQueryParamsToURL } from '@/hooks/useAddQueryParamsToURL'
import { motion } from 'framer-motion'
import { Search } from 'lucide-react'
import { Accounting_Account_Type } from '@/utils/enum'
import ChartOfAccountTable from '../components/ChartOfAccountsTable'
import { useGetCOA } from '@/services/accounting/queries'
import { useEffect, useMemo, useState } from 'react'
import ImportChartOfAccount from '@/components/Modals/Accounting/ImportChartOfAccount'
import ModalWrapper from '@/components/Modals/ModalWrapper'
import { Pagination } from '@/types/utils'
import AddNewAccountModal from '@/components/Modals/Accounts/AddNewAccountModal'

const filterType = {
    assets: Accounting_Account_Type.Asset,
    expenses: Accounting_Account_Type.Expense,
    equity: Accounting_Account_Type.Equity,
    liability: Accounting_Account_Type.Liability,
    revenue: Accounting_Account_Type.Revenue,
}

export default function AccountingChartOfAccount() {
    const { assets, equity, expenses, liability, revenue } = filterType
    const [setAccoutingFilter, searchParamDetails] = useAddQueryParamsToURL<{ type: keyof typeof filterType }>()
    const subRoute = searchParamDetails.get('type') ?? assets
    const [openImportModal, setOpenImportModal] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [pageNoClicked, setPageNoClicked] = useState(false)
    const [openAddNewAccount, setOpenAddNewAccount] = useState(false)

    const { data: coaData } = useGetCOA({
        queryParams: {
            limit: '10',
        },
    })
    const { data, isLoading, refetch } = useGetCOA({
        queryParams: {
            account_type: subRoute,
            page: pageNumber.toString(),
            limit: '10',
        },
    })

    useEffect(() => {
        if (pageNoClicked) {
            refetch()
            setPageNoClicked(false)
        }
    }, [pageNoClicked])

    const chartOfAccountData = useMemo(() => data?.results ?? [], [data])
    const pagination = useMemo(() => data?.pagination ?? ({} as Pagination), [data])
    const analytics = useMemo(() => coaData?.analytics ?? [], [coaData])

    const filterDetailsBtn = [
        {
            desc: 'Assets',
            id: assets,
            filterVal: analytics.find(data => data.account_type === assets)?.count,
        },
        {
            desc: 'Liabilities',
            filterVal: analytics.find(data => data.account_type === liability)?.count,

            id: liability,
        },
        {
            desc: 'Equity',
            filterVal: analytics.find(data => data.account_type === equity)?.count,

            id: equity,
        },
        {
            desc: 'Expenses',
            filterVal: analytics.find(data => data.account_type === expenses)?.count,

            id: expenses,
        },
        {
            desc: 'Revenue',
            filterVal: analytics.find(data => data.account_type === revenue)?.count,

            id: revenue,
        },
    ]

    return (
        <section className='mt-8'>
            <div className='flex flex-col lg:flex-row gap-5 justify-between'>
                <div>
                    <h2 className='text-xl font-semibold'>Chart of accounts</h2>
                    <p className='text-sm text-gray7'>Organize and manage your financial accounts seamlessly</p>
                </div>
                <div className='flex items-center gap-3'>
                    <Button className='text-sm min-h-[35px]' onClick={() => setOpenAddNewAccount(true)}>
                        Add new account
                    </Button>
                    <Button
                        onClick={() => setOpenImportModal(true)}
                        variant={'outline'}
                        size={'sm'}
                        className='text-sm py-2 px-3'
                    >
                        Import Chart of Accounts
                    </Button>
                </div>
            </div>
            <section className='mt-8 mb-4 bg-white p-4 rounded-xl'>
                <div className='overflow-x-scroll'>
                    <ul className='flex items-center gap-4 mb-5 bg-gray5/20 w-fit p-2 rounded-xl'>
                        {filterDetailsBtn.map(btnDetails => (
                            <li
                                className={`relative ${btnDetails.id === subRoute ? 'text-brand ' : 'bg-transparent text-[#5E5E5E]'} text-sm rounded-md p-2 px-3`}
                                onClick={() => setAccoutingFilter('type', btnDetails.id)}
                            >
                                <button className='flex gap-2'>
                                    <span className='z-10 text-gray7'>{btnDetails.desc} </span>
                                    {btnDetails?.filterVal && (
                                        <span
                                            className={`${btnDetails.id === subRoute ? 'bg-brand ' : ' bg-gray7 '} text-white text-xs px-3 p-0.5 rounded-xl z-10`}
                                        >
                                            {btnDetails?.filterVal}
                                        </span>
                                    )}
                                </button>
                                {btnDetails.id === subRoute && (
                                    <motion.div
                                        layoutId='accounting-coa-filter'
                                        className={
                                            'bg-white rounded  text-sm  w-full left-[1px] top-0  bottom-0 absolute z-0'
                                        }
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <Input
                    placeholder='Search'
                    className='max-w-xs h-11 border-gray4'
                    leftIcon={<Search className='text-gray' />}
                />
                <div className={`mt-5 ${isLoading ? 'opacity-30' : ''}`}>
                    <ChartOfAccountTable
                        coa={chartOfAccountData}
                        pagination={pagination}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        setPageNoClicked={setPageNoClicked}
                    />
                </div>
            </section>

            {openImportModal && (
                <ModalWrapper>
                    <ImportChartOfAccount closeModal={() => setOpenImportModal(false)} />
                </ModalWrapper>
            )}
            {openAddNewAccount && <AddNewAccountModal closeModal={() => setOpenAddNewAccount(false)} />}
        </section>
    )
}
