import React, { useEffect, useRef, useState } from 'react'
import { ExpenseFieldProps, createExpenseHelper } from './utils'
import { Control, Controller, UseFormWatch } from 'react-hook-form'
import { Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import { capitalizeText } from '@/utils/string'
import DatePicker from 'react-datepicker'
import { formatAmount, formatAmountNoSymbol, formatAmountWithoutSymbols } from '@/utils/money'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { CancelIcon, PDFIcon } from '@/assets/assets.export'
import useSingleFileUpload, { FileUploadFormatProps, fileEmptyState } from '@/hooks/useSingleFileUpload'
import { isAfter } from 'date-fns'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/Tooltips'
import { useNavigate } from 'react-router-dom'
import CustomDatePicker from '@/components/CustomDatePicker/CustomDatePicker'
import { QueryOrgBudgetType } from '@/services/budget/types'
import { CategoryType } from '@/types/categories'
import { handleCheckIfBudgetIsExpired } from '@/modules/dashboard/Budget/utils'
import { AlertCircleIcon } from 'lucide-react'
import { useAuthContext, useCurrencyContext } from '@/context'
import { ADMIN_EXPENSE_PERMISSIONS } from '@/utils/permisson-helper'
import { CurrencyIcon } from '../../Budget/CreateBudget1'

type Props = {
    watch: UseFormWatch<ExpenseFieldProps>
    control: Control<ExpenseFieldProps, any>
    categories: CategoryType[]
    setScanWithOCR: React.Dispatch<React.SetStateAction<boolean>>
    setProofExpenseFile: React.Dispatch<React.SetStateAction<FileUploadFormatProps | undefined>>
    proofExpenseFile: FileUploadFormatProps | undefined
    orgBudget: QueryOrgBudgetType
    isEdit?: boolean
    hasPrimaryBank: boolean
}
const create_expense_consts = {
    pdf: 'pdf',
    image: 'image',
    amount: 'amount',
    date: 'date',
}
export default function ExpenseForm({
    watch,
    control,
    categories,
    setScanWithOCR,
    proofExpenseFile,
    setProofExpenseFile,
    isEdit,
    hasPrimaryBank,
    orgBudget,
}: Props) {
    const navigate = useNavigate()
    const { selectedAccount } = useAuthContext()
    const { currencies, selectedCurrencyInfo } = useCurrencyContext()
    const formCurrency = watch('currency')
    const currencySymbol = selectedCurrencyInfo?.(formCurrency)?.symbol

    const [errInDate, setErrInDate] = useState(false)
    const calendarRef = useRef<DatePicker>(null)
    const divRef = useRef<HTMLButtonElement | null>(null)
    const orgExpensePermissions = ADMIN_EXPENSE_PERMISSIONS.every(perm =>
        selectedAccount?.role?.permissions?.includes(perm)
    )
    const { handleFile: uploadProofFn } = useSingleFileUpload({
        fileUpload: proofExpenseFile,
        setFileUpload: setProofExpenseFile,
    })

    const watchLinkBudget = watch('attact_budget')
    const description = watch('description')

    useEffect(() => {
        if (watchLinkBudget === 'link' && divRef.current) {
            divRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [watchLinkBudget])

    return (
        <div className='grid grid-cols-2 gap-3 lg:gap-6 my-10 mb-10 mx-4 lg:mx-8'>
            {createExpenseHelper.map(fieldDetails => {
                if (fieldDetails?.name === 'budget' && watchLinkBudget !== 'link') return
                if (fieldDetails.fieldType === 'checkbox' && !proofExpenseFile?.file_name) return
                if (fieldDetails?.fieldType === 'file' && isEdit) return
                if (fieldDetails?.name === 'attact_budget' && !orgExpensePermissions) return

                return (
                    <div
                        key={fieldDetails.name}
                        className={`flex flex-col flex-1 gap-3 relative ${
                            fieldDetails.name === 'receipt' ||
                            fieldDetails.name === 'description' ||
                            fieldDetails.name === 'budget' ||
                            fieldDetails.fieldType === 'radio'
                                ? 'col-span-2'
                                : ''
                        }`}
                    >
                        {fieldDetails?.title && (
                            <label
                                htmlFor='firstName'
                                className='text-[#202020] text-[15px] font-medium flex items-center gap-1'
                            >
                                {fieldDetails.title}{' '}
                                {fieldDetails.title !== 'Upload Receipt' && <span className='text-[red]'>*</span>}
                                {fieldDetails?.fieldType === 'textarea' && (
                                    <span className='ml-auto text-xs'>{description?.length ?? '0'}/120</span>
                                )}
                            </label>
                        )}

                        <Controller
                            name={fieldDetails.name as keyof ExpenseFieldProps}
                            rules={{
                                minLength: 2,
                                required:
                                    fieldDetails?.name === 'receipt' || fieldDetails?.name === 'scan' ? false : true,
                            }}
                            control={control}
                            render={({ field }) =>
                                fieldDetails.fieldType === 'dropdown' ? (
                                    <Select
                                        {...field}
                                        onValueChange={field.onChange}
                                        value={field.value as keyof ExpenseFieldProps}
                                    >
                                        {
                                            // No budget affiliated with the user
                                            orgBudget?.budgets?.length === 0 && field.name === 'budget' ? (
                                                <span className='bg-[#AE6B57]/20 text-[#AE6B57] p-2 rounded-md '>
                                                    No budget affiliated with the user
                                                </span>
                                            ) : (
                                                <SelectTrigger
                                                    ref={
                                                        fieldDetails?.name === 'budget' && watchLinkBudget === 'link'
                                                            ? divRef
                                                            : null
                                                    }
                                                    className='h-[3rem] border-[#DADCE0] bg-base_gray_100 text-[13px]'
                                                    variant={
                                                        (field?.value as string)?.length > 0 ? 'selected' : 'default'
                                                    }
                                                >
                                                    {(field?.value as string)?.length > 0 ? (
                                                        <SelectValue />
                                                    ) : (
                                                        <span>{fieldDetails.placeholder}</span>
                                                    )}
                                                </SelectTrigger>
                                            )
                                        }

                                        <SelectContent className=' max-h-[200px] overflow-scroll'>
                                            {fieldDetails.name === 'category'
                                                ? categories.map(category => (
                                                      <div className='flex items-center justify-between w-full'>
                                                          <SelectItem value={category.id} className='w-full'>
                                                              {capitalizeText(category.label)}{' '}
                                                          </SelectItem>
                                                      </div>
                                                  ))
                                                : fieldDetails?.name === 'budget' && watchLinkBudget === 'link'
                                                  ? orgBudget?.budgets?.map(budget => (
                                                        <div className='flex items-center justify-between w-full'>
                                                            <SelectItem
                                                                value={budget?._id}
                                                                disabled={handleCheckIfBudgetIsExpired(budget)}
                                                            >
                                                                <span>
                                                                    {budget.name} (Balance: {currencySymbol}
                                                                    {formatAmountNoSymbol(budget?.available_amount)})
                                                                </span>
                                                            </SelectItem>
                                                            {handleCheckIfBudgetIsExpired(budget) && (
                                                                <TooltipProvider delayDuration={4}>
                                                                    <Tooltip>
                                                                        <TooltipTrigger className='mr-2' type='button'>
                                                                            <AlertCircleIcon size={15} />
                                                                        </TooltipTrigger>

                                                                        <TooltipContent>
                                                                            <p className='bg-[#EFEFF9] p-2 text-[#202020] text-sm rounded'>
                                                                                This budget is no longer valid.
                                                                            </p>
                                                                        </TooltipContent>
                                                                    </Tooltip>
                                                                </TooltipProvider>
                                                            )}
                                                        </div>
                                                    ))
                                                  : fieldDetails?.name === 'currency'
                                                    ? currencies?.map(currency => (
                                                          <SelectItem className='text-xs' value={currency?.id}>
                                                              <span>{currency?.symbol}</span> - {currency?.name}(
                                                              {currency?.currency_code})
                                                          </SelectItem>
                                                      ))
                                                    : null}
                                        </SelectContent>
                                    </Select>
                                ) : fieldDetails.fieldType === 'date' ? (
                                    <>
                                        <CustomDatePicker
                                            {...field}
                                            ref={calendarRef}
                                            placeholderText={fieldDetails.placeholder}
                                            onChange={e => {
                                                const today = new Date()
                                                if (isAfter(e as Date, today)) {
                                                    setErrInDate(true)
                                                    return
                                                }
                                                setErrInDate(false)
                                                field.onChange(e)
                                            }}
                                            maxDate={new Date()}
                                            selected={field.value as unknown as Date}
                                            value={field.value as unknown as string}
                                        />

                                        {errInDate && (
                                            <span className='text-[red]/70 text-xs block mt-3 relative -top-[0.5rem]'>
                                                Date cannot be past today
                                            </span>
                                        )}
                                    </>
                                ) : fieldDetails.name === 'amount' ? (
                                    <div className='flex border border-[#DADCE0] w-full h-[3rem] rounded-lg overflow-hidden bg-white'>
                                        <span className='flex items-center justify-center text-[#838383] ml-2 scale-75'>
                                            {CurrencyIcon(currencySymbol ?? '')}
                                            {/* <TbCurrencyNaira fontSize={20} /> */}
                                        </span>
                                        <input
                                            {...field}
                                            type='text'
                                            placeholder={`Enter Amount`}
                                            className='outline-none w-[90%] h-full ml-1 pl-2 placeholder:text-sm '
                                            required
                                            min={0}
                                            value={formatAmount(field.value as keyof ExpenseFieldProps).replaceAll(
                                                '₦',
                                                ''
                                            )}
                                            onChange={e => {
                                                const value_entered = formatAmountWithoutSymbols(e.target.value)
                                                field.onChange(value_entered)
                                            }}
                                        />
                                    </div>
                                ) : fieldDetails.fieldType === 'radio' ? (
                                    <TooltipProvider delayDuration={10}>
                                        <Tooltip>
                                            <TooltipTrigger type='button'>
                                                <RadioGroup
                                                    {...field}
                                                    value={field.value as string}
                                                    className='flex gap-8 disabled:opacity-50 relative'
                                                    onValueChange={value => {
                                                        field.onChange(value)
                                                    }}
                                                    disabled={fieldDetails.name === 'expense_type' && hasPrimaryBank}
                                                >
                                                    {fieldDetails?.fieldOptions?.map(details => (
                                                        <label
                                                            htmlFor={details.id}
                                                            key={details.id}
                                                            className={`flex gap-2 items-center  ${
                                                                fieldDetails.name === 'expense_type' && hasPrimaryBank
                                                                    ? 'opacity-50 cursor-not-allowed'
                                                                    : 'cursor-pointer opacity-100'
                                                            } `}
                                                        >
                                                            <RadioGroupItem
                                                                type='button'
                                                                value={details.id}
                                                                id={details.id}
                                                            />
                                                            {details.value}
                                                        </label>
                                                    ))}
                                                </RadioGroup>
                                            </TooltipTrigger>
                                            {fieldDetails.name === 'expense_type' && (
                                                <TooltipContent>
                                                    <div
                                                        className={`bg-[#EFEFF9] p-3 text-[#202020] text-sm rounded ${
                                                            hasPrimaryBank ? 'block' : 'hidden'
                                                        }`}
                                                    >
                                                        <p className=''>You don't have a bank account linked.</p>
                                                        <p>
                                                            Click{' '}
                                                            <button
                                                                className='text-brand underline font-medium'
                                                                onClick={() => {
                                                                    navigate('/dashboard/settings/user/payment')
                                                                }}
                                                            >
                                                                here
                                                            </button>{' '}
                                                            to add a bank account
                                                        </p>
                                                    </div>
                                                </TooltipContent>
                                            )}
                                        </Tooltip>
                                    </TooltipProvider>
                                ) : fieldDetails.fieldType === 'textarea' ? (
                                    <Textarea
                                        {...field}
                                        value={field.value as string}
                                        className='border border-[#DADCE0] pt-4 min-h-[5.6rem] text-18px resize-none'
                                        maxLength={120}
                                    />
                                ) : fieldDetails.fieldType === 'file' && !isEdit ? (
                                    !proofExpenseFile?.file ? (
                                        <label
                                            htmlFor={field.name}
                                            className='cursor-pointer upload flex items-center justify-center border border-dashed rounded-lg border-[#DADCE0] h-[7.6rem]'
                                        >
                                            <h1 className='text-center text-[18px] text-[#A8A8A8] font-semibold my-auto'>
                                                Upload receipt
                                                <br />
                                                Max 2MB | Accepts JPEG, PNG, and PDF formats
                                            </h1>
                                            <input
                                                {...field}
                                                value={field?.value as string}
                                                type='file'
                                                id={field.name}
                                                onChange={e => {
                                                    uploadProofFn(e)
                                                    // field.onChange(proofExpenseFile)
                                                }}
                                                hidden
                                            />
                                        </label>
                                    ) : (
                                        // {/* when the image has been uploaded */}
                                        <div>
                                            <div className='border border-dashed rounded-lg border-[#838383] h-[130px] px-5 py-3 bg-[#f9f9f9]'>
                                                <div className='bg-white flex justify-between items-center h-full rounded-lg px-4'>
                                                    <div className='flex items-center gap-4'>
                                                        <div className=''>
                                                            {proofExpenseFile?.fileType ===
                                                            create_expense_consts.pdf ? (
                                                                <PDFIcon className='w-[4.5rem] h-24 inline-block rounded-lg' />
                                                            ) : (
                                                                <img
                                                                    src={proofExpenseFile.fileUrl}
                                                                    alt=''
                                                                    className='w-[4.5rem] h-24 rounded-lg'
                                                                />
                                                            )}
                                                        </div>

                                                        <div className='text-lg font-semibold'>
                                                            <h2 className='text-[#838383] max-w-xs lg:w-auto truncate'>
                                                                {proofExpenseFile.file_name}
                                                            </h2>
                                                            <p className='text-[#202020]'>{proofExpenseFile.size}</p>
                                                        </div>
                                                    </div>

                                                    <span
                                                        className='cursor-pointer'
                                                        onClick={() => setProofExpenseFile(fileEmptyState)}
                                                    >
                                                        {
                                                            <CancelIcon className='rounded-full bg-[#F9F0EF]  text-red p-[0.35rem] h-10 w-10 !stroke-[#872D10]' />
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ) : fieldDetails.fieldType === 'checkbox' ? (
                                    <label className='text-[#454ADE] w-[382px] lg:w-auto text-sm lg:text-base flex items-center gap-3 cursor-pointer relative -top-2 font-semibold'>
                                        <input
                                            type='checkbox'
                                            className='w-4 h-4 accent-[#454ADE] min-w-0'
                                            {...field}
                                            value={field?.value as unknown as string}
                                            onChange={e => {
                                                setScanWithOCR(e.target.checked)
                                            }}
                                        />
                                        Scan receipt to verify amount
                                    </label>
                                ) : (
                                    <Input
                                        {...field}
                                        value={field.value as string}
                                        placeholder={fieldDetails?.placeholder}
                                        className='border-[#DADCE0] placeholder:text-[#A8A8A8]/90 h-[3rem]'
                                    />
                                )
                            }
                        />
                    </div>
                )
            })}
        </div>
    )
}
