import { Button } from '@/components'
import { AiOutlineClose } from 'react-icons/ai'
import { Textarea } from '@/components'
import { useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { useAuthorizeCompanyKYB } from '@/services/auth/mutations'
import { toastHandler } from '@/components/utils/Toast'
import { QueryOrgKYB } from '@/services/auth/types'
import { AuthorizeKYB } from '@/modules/dashboard/BusinessDirectory/partials/BusinessDirectoryCompliance'

type Props = {
    closeModal: () => void
    kybDetails: QueryOrgKYB
    orgId: string
}

export default function DeclineKYBReasonModal({ closeModal, kybDetails, orgId }: Props) {
    const [note, setNote] = useState('')
    const disableBtn = note.length > 20 ? false : true

    const { mutate: authorizeKYBFn, isLoading } = useAuthorizeCompanyKYB({
        onSuccess() {
            closeModal()
            toastHandler({
                message: kybDetails?.org.kyb_completed ? 'KYB revoked successfully' : 'KYB declined successfully',
                state: 'success',
            })
        },
    })

    const handleApproval = (result: AuthorizeKYB) => {
        authorizeKYBFn({ result, org_id: orgId, reason: note })
    }

    return (
        <>
            <ModalWrapper variants={undefined}>
                <div className='w-screen lg:w-[30rem] rounded-[20px]'>
                    <div className='border-b border-[#DADCE0] py-3 p-2  flex justify-end'>
                        <div className='flex items-center  justify-between h-full flex-1'>
                            <h2 className='font-semibold text-[#31254B] text-lg text-center flex-1'>
                                {kybDetails?.org.kyb_completed ? 'Revoke KYB' : 'Decline KYB '}
                            </h2>
                            <div
                                className='bg-[#F2F2F2] w-6 h-6 p-1.5 rounded-full flex items-center justify-center text-[#838383] cursor-pointer'
                                onClick={() => closeModal()}
                            >
                                <AiOutlineClose fontSize={25} />
                            </div>
                        </div>
                    </div>

                    <form onSubmit={(e: React.FormEvent) => e.preventDefault()} className=''>
                        <div className='px-[1rem]  pb-3  max-h-[400px] lg:max-h-[80vh] overflow-scroll'>
                            <div className={`flex flex-col gap-8 mb-12`}>
                                <div className='flex flex-col flex-1 gap-1.5'>
                                    <label className='text-[#202020] text-sm font-medium'>Reason</label>
                                    <Textarea
                                        name='note'
                                        placeholder='Explain further if you wish to.'
                                        className='border border-[#DADCE0]  resize-none placeholder:text-sm text-sm'
                                        value={note}
                                        onChange={e => {
                                            const { value } = e.target
                                            setNote(value)
                                        }}
                                    />
                                    {<p className='text-sm text-gray7'>Note must have a minimum of 20 characters</p>}
                                </div>
                            </div>
                            <Button
                                className={`w-full  text-sm font-normal mb-3 disabled:opacity-30 ${`bg-errorRed hover:bg-errorRed disabled:bg-errorRed`}`}
                                disabled={disableBtn}
                                loading={isLoading}
                                onClick={() => handleApproval(AuthorizeKYB.REJECT)}
                            >
                                {kybDetails?.org.kyb_completed ? 'Revoke' : 'Decline'}
                            </Button>
                        </div>
                    </form>
                </div>
            </ModalWrapper>
        </>
    )
}
