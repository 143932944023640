import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Textarea } from '@/components'
import { Link } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { emailPattern } from '@/const/regex'
import { useRequestAccess } from '@/services/auth/mutations'
import { kyb } from '@/modules/dashboard/Settings/partials/CompanyCompliance/utils'

interface Step1Props {
    toggleStep: (step: number) => void
}
type FormValues = {
    first_name: string
    last_name: string
    email: string
    org_name: string
    phone: string
    website: string
    industry: string
    description: string
}

const Step1 = ({ toggleStep }: Step1Props) => {
    const {
        handleSubmit,
        formState: { isValid },
        control,
        watch,
    } = useForm<FormValues>()

    const { mutate: requestAccessFn, isLoading } = useRequestAccess({
        onSuccess: () => {
            toggleStep(2)
        },
    })

    const watchDescription = watch('description')
    const onSubmit = async (values: FormValues) => {
        requestAccessFn({ ...values })
    }

    return (
        <div className='w-[90%] md:w-[85%] max-w-[1100px] mx-auto my-12'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 className='text-[#31254B] text-3xl mb-4 font-semibold'>Get started</h2>
                <p className='text-[#202020] mb-6 text-[18px]'>Begin your journey to easy financial management.</p>

                <div className='flex flex-col md:flex-row gap-[35px] mb-6'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='firstName' className='text-[#202020] text-[14px]'>
                            First name <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='first_name'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    className='h-[3.5rem]'
                                    placeholder='Enter your first name'
                                    value={field.value as string}
                                />
                            )}
                        />
                    </div>

                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='lastName' className='text-[#202020] text-[14px]'>
                            Last name <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='last_name'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input {...field} className='h-[3.5rem]' placeholder='Enter your last name' />
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-[35px] mb-6'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='OrganizationName' className='text-[#202020] text-[14px]'>
                            Business name <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='org_name'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    className='h-[3.5rem]'
                                    placeholder='Enter the name of your business'
                                />
                            )}
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='OrganizationName' className='text-[#202020] text-[14px]'>
                            Business Website <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='website'
                            rules={{
                                required: true,
                                minLength: 2,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input {...field} className='h-[3.5rem]' placeholder='Enter your business website' />
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-[35px] mb-6'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Work email <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='email'
                            rules={{
                                required: true,
                                validate: value => {
                                    return emailPattern.test(value)
                                },
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    className='h-[3.5rem]'
                                    type='email'
                                    placeholder='Enter your work email address'
                                />
                            )}
                        />
                    </div>

                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Work Phone Number <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='phone'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    className='h-[3.5rem]'
                                    type='number'
                                    placeholder='Enter your work phone number'
                                />
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-[35px] mb-6'>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Industry <span className='text-[red]'>*</span>
                        </label>
                        <Controller
                            name='industry'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Select {...field} name={'industry'} onValueChange={field.onChange}>
                                    <SelectTrigger className='h-12  text-sm'>
                                        <SelectValue placeholder='Select Industry' />
                                    </SelectTrigger>
                                    <SelectContent className='w-80 max-h-48 overflow-scroll'>
                                        {kyb.industries.map(industry => (
                                            <SelectItem value={industry.title}>{industry.title}</SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            )}
                        />
                    </div>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-sm flex justify-between items-center'>
                            <span>
                                Company description <span className='text-[red]'>*</span>
                            </span>
                            <span className='text-xs font-semibold opacity-50'>
                                {watchDescription?.length || 0}/120
                            </span>
                        </label>
                        <Controller
                            name='description'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Textarea
                                    {...field}
                                    className='w-full min-w-full resize-none h-12 placeholder:pl-2 border-black/40'
                                    placeholder={`Enter company description`}
                                    name={'description'}
                                    maxLength={120}
                                />
                            )}
                        />
                    </div>
                </div>

                <div className='flex flex-col-reverse md:flex-row gap-4 md:gap-0 items-center justify-between mt-12'>
                    <p className='flex items-center gap-2 text-[#202020] font-medium'>
                        Already have an account?
                        <Link to='/' className='text-[#454ADE] font-semibold'>
                            Log in
                        </Link>
                    </p>

                    <Button loading={isLoading} disabled={!isValid} variant='default' type='submit'>
                        Request Access
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default Step1
