import { Button } from '@/components'
import { ArrowLeft } from 'lucide-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMemo, useState } from 'react'
import { useAuthContext, usePayrollContext } from '@/context'
import { useGetOrgContractPayrollById, useGetorgContractPayrollHistory } from '@/services/payroll/queries'
import ProgressBar from '@/components/ProgressBar'
import { formatAmount } from '@/utils/money'
import { format } from 'date-fns'
import { capitalizeText } from '@/utils/string'
import { ContractPayrollType, MilestoneType, OrgContractPayHistory } from '@/types/payroll'
import AddContractToPayrollModal from '@/components/Modals/Payroll/AddContractToPayrollModal/AddContractToPayrollModal'
import { useGetOrgMembers } from '@/services/employees/queries'
import MilestonesTable from '@/modules/dashboard/Payroll/components/MilestonesTable'
import { useTerminateContract } from '@/services/payroll/mutations'
import { toastHandler } from '@/components/utils/Toast'
import OrgContractTable from '../../partials/ContractPayroll/components/OrgContractTable'
import PayHistoryEmptyState from '@/assets/pay-history-empty-state.svg'
import { BsPlusSquare } from 'react-icons/bs'
import StatusColor from '@/components/utils/StatusColor'
import CheckToEnableEditContractButton from '../../hoc/CheckToEnableEditContractButton'
import TerminateContractPrompt from '@/components/Modals/Payroll/Contract/TerminateContractPrompt'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { contractSidebarPermissions } from '@/layout/LayoutComponents/utils'

export default function ContractMoreDetails() {
    const navigate = useNavigate()
    const { selectedContract, setSelectedContract } = usePayrollContext()
    const [openUpdateModal, setUpdateModal] = useState(false)
    const [openUpdateMileStoneDetails, setUpdateMileStoneDetails] = useState(false)
    const [showTerminate, setShowTerminate] = useState(false)

    const { contract_id } = useParams()

    const { selectedAccount } = useAuthContext()
    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })
    const orgMembers = useMemo(() => org_members ?? [], [org_members])
    const enabled = useHasAccessPermisson(contractSidebarPermissions)

    const {
        data: org_contract_by_id_data,
        isLoading,
        isRefetching,
    } = useGetOrgContractPayrollById({
        queryParams: {
            id: contract_id as string,
        },
    })
    const selectedPayrollContract = useMemo(
        () => org_contract_by_id_data ?? selectedContract,
        [selectedContract, org_contract_by_id_data]
    )
    const {
        data: contract_payhistory,
        isLoading: isLoadingHistory,
        isRefetching: isReFetchinghistory,
    } = useGetorgContractPayrollHistory({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            owner: selectedPayrollContract?.employee?._id ?? (selectedPayrollContract?.employee?.id as string),
            contract: contract_id as string,
        },
    })
    const contractPayHistory = useMemo(
        () => contract_payhistory ?? ({} as OrgContractPayHistory),
        [contract_payhistory]
    )

    const { mutate: terminateContractFn, isLoading: isTerminatingContract } = useTerminateContract({
        queryParams: {
            contract_id: selectedPayrollContract?.id as string,
        },
        onSuccess: () => {
            setSelectedContract(undefined)
            setShowTerminate(false)
            toastHandler({ message: 'contract terminated', state: 'success' })
        },
    })
    const handleTerminate = (text: string) => {
        terminateContractFn({
            reason: text,
        })
    }

    const contract_details = [
        {
            name: 'title',
            desc: selectedPayrollContract?.contract_title,
        },
        {
            name: 'Contractor',
            desc: `${capitalizeText(selectedPayrollContract?.employee?.user?.first_name)} ${capitalizeText(
                selectedPayrollContract?.employee?.user?.last_name
            )}`,
        },
        {
            name: 'User ID',
            desc: selectedPayrollContract?.employee?.employee_id,
        },
        {
            name: 'Employment type',
            desc: capitalizeText(selectedPayrollContract?.employment_type),
        },
        {
            name: 'Total pay',
            desc: formatAmount(selectedPayrollContract?.total_pay),
        },
        {
            name: 'Pay frequency',
            desc:
                selectedPayrollContract?.pay_frequency === 'milestones'
                    ? 'Pay by milestones'
                    : capitalizeText(selectedPayrollContract?.pay_frequency),
        },
        {
            name: 'Status',
            desc: <StatusColor status={selectedPayrollContract?.status as string} disableBgColor={true} />,
        },
        {
            name: 'Start date',
            desc:
                selectedPayrollContract?.start_date &&
                format(new Date(selectedPayrollContract?.start_date as unknown as Date), 'dd/MM/yyyy'),
        },
        {
            name: 'End date',
            desc:
                selectedPayrollContract?.end_date &&
                format(new Date(selectedPayrollContract?.end_date as unknown as Date), 'dd/MM/yyyy'),
        },
        {
            name: 'Budget',
            enabled,
            desc: selectedPayrollContract?.budget ? (
                <span
                    role='button'
                    className='underline text-brand'
                    onClick={() =>
                        navigate(
                            `/dashboard/budget/${
                                selectedPayrollContract?.budget?._id ?? selectedPayrollContract?.budget?.id
                            }`
                        )
                    }
                >
                    {selectedPayrollContract?.budget?.name}
                </span>
            ) : (
                'N/A'
            ),
        },
    ]

    if ((isLoading && !selectedContract?.id) || selectedPayrollContract?.id !== contract_id) return <ProgressBar />
    return (
        <div className='h-screen overflow-scroll'>
            <section className='mx-[1.2rem] lg:mx-[2.7rem] py-3 pt-2 mb-6 '>
                <div className='flex gap-1 my-6 cursor-pointer' onClick={() => navigate(-1)}>
                    <ArrowLeft />
                    back
                </div>
                <section className=' bg-white rounded-xl px-4 lg:px-7 py-6 pb-11'>
                    <div className='border-b border-[#DADCE0] pb-3 flex items-center justify-between flex-wrap gap-y-4'>
                        <h2 className=' font-semibold text-lg'>{contract_details[0].desc}</h2>
                    </div>
                    <div
                        className={`flex flex-wrap max-w-[60rem] gap-x-[5rem] gap-y-8 mt-8 ${
                            isRefetching ? 'opacity-50' : 'opacity-100'
                        }`}
                    >
                        {contract_details
                            .filter((_, i) => i > 0)
                            .map((detail, idx) => {
                                if (!detail.enabled && detail.name == 'Budget') return
                                return (
                                    <>
                                        <div
                                            className={`flex flex-col ${detail.name === 'Description' ? 'w-[80%]' : ''}`}
                                            key={idx}
                                        >
                                            <h4 className='text-md font-semibold mb-1'>{detail.name}</h4>
                                            <span className='text-[#5E5E5E] text-[1rem]'>{detail.desc}</span>
                                        </div>
                                        {detail.name === 'Total pay' && <br className='hidden lg:block' />}
                                    </>
                                )
                            })}
                    </div>
                    <div className='mt-16'>
                        <div className='border-b border-[#DADCE0] pb-3 flex justify-between'>
                            <h2 className=' font-semibold text-lg'>Contract Milestones</h2>
                            {enabled && (
                                <CheckToEnableEditContractButton
                                    contract={selectedPayrollContract as ContractPayrollType}
                                    children={
                                        <Button
                                            className={`mb-0 flex gap-2 items-center text-[#5E5E5E] py-3 px-5 rounded-lg border border-transparent text-sm bg-transparent hover:bg-transparent `}
                                            onClick={() => {
                                                setUpdateModal(true)
                                                setUpdateMileStoneDetails(true)
                                            }}
                                            type='button'
                                        >
                                            <BsPlusSquare color='#5E5E5E' />
                                            Add Milestone
                                        </Button>
                                    }
                                />
                            )}
                        </div>
                        <div className='mt-6 mx-[2px] overflow-y-scroll'>
                            <MilestonesTable
                                data={selectedPayrollContract?.milestones as MilestoneType[]}
                                contract={selectedPayrollContract}
                            />
                        </div>
                    </div>
                    {enabled && (
                        <CheckToEnableEditContractButton
                            contract={selectedPayrollContract!}
                            children={
                                <div className='flex justify-between mt-10 w-full'>
                                    <div className='flex flex-row gap-2 lg:gap-5 lg:ml-auto'>
                                        <Button
                                            className='bg-[#CF491E] w-full lg:w-auto  min-w-[130px] min-h-[35px] bg-transparent text-errorRed hover:bg-transparent hover:border-[#CF491E] hover:border'
                                            onClick={() => setUpdateModal(true)}
                                        >
                                            Edit contract
                                        </Button>
                                        <Button
                                            className='whitespace-nowrap w-full lg:w-auto min-w-[130px] min-h-[35px] hover bg-[#CF491E] text-white hover:bg-[#ac573b]'
                                            onClick={() => setShowTerminate(true)}
                                        >
                                            Terminate contract
                                        </Button>
                                    </div>
                                </div>
                            }
                        />
                    )}
                    {selectedPayrollContract?.status === 'cancelled' && (
                        <div className='bg-[#FFEDED] px-5 py-3 rounded-lg font-semibold mx-2 mt-7'>
                            <h2 className='text-base text-[#202020]'>Contract termination</h2>
                            <p className='text-sm text-[#5E5E5E] mt-[5px]'>
                                {selectedPayrollContract?.reason === 'fraud activity' &&
                                    'This contract was terminated because the contractor was involved in fradulent activities.'}
                                {selectedPayrollContract?.reason === 'suspicious charges' &&
                                    'This contract was terminated due to the identification of irregular charges by the contractor.'}
                                {selectedPayrollContract?.reason !== 'suspicious charges' &&
                                    selectedPayrollContract?.reason !== 'fraud activity' &&
                                    selectedPayrollContract?.reason}
                            </p>
                        </div>
                    )}
                </section>
                <section className=' bg-white rounded-xl p-5 mt-6 mb-16'>
                    <div className='border-b border-[#DADCE0] pb-3 flex items-center justify-between'>
                        <h2 className=' font-semibold text-lg'>Pay history</h2>
                    </div>
                    <div className=''>
                        {isLoadingHistory ? (
                            <ProgressBar />
                        ) : (
                            <section className={`${isReFetchinghistory ? 'opacity-40' : 'opacity-100'} mt-8 `}>
                                {contractPayHistory?.history?.length >= 0 ? (
                                    <div className='overflow-y-scroll'>
                                        <OrgContractTable contractHistory={contractPayHistory} />
                                    </div>
                                ) : (
                                    <div className='h-450px] flex flex-col text-center justify-center items-center'>
                                        <h3
                                            className='text-2xl font-bold text-[#5E5E5E] mt-14'
                                            onClick={() => navigate('1234')}
                                        >
                                            No Payment made!
                                        </h3>
                                        <span className='text-[#202020] text-[14px] w-[45%] mx-auto mt-3 font-normal'>
                                            Monitor payments that are being paid to your bank account
                                        </span>
                                        <img
                                            src={PayHistoryEmptyState}
                                            alt='Payroll Empty state'
                                            className='mt-6 h-[200px] aspect-square'
                                        />
                                    </div>
                                )}
                            </section>
                        )}
                    </div>
                </section>
            </section>

            {openUpdateModal && (
                <AddContractToPayrollModal
                    orgMembers={orgMembers}
                    closeModal={setUpdateModal}
                    formProps={selectedPayrollContract}
                    updateContract={openUpdateModal}
                    setUpdateContract={setUpdateModal}
                    openUpdateMileStoneDetails={openUpdateMileStoneDetails}
                    setUpdateMileStoneDetails={setUpdateMileStoneDetails}
                />
            )}
            {showTerminate && (
                <TerminateContractPrompt
                    closeModal={setShowTerminate}
                    handleTerminate={handleTerminate}
                    isLoading={isTerminatingContract}
                />
            )}
        </div>
    )
}
