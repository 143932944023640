import { OrgPayrollHistoryType } from '@/types/payroll'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import React from 'react'

import Table from '@/components/Table'

import TableActions from '@/components/Table/TableActions'
import { usePayrollContext } from '@/context'
import { formatAmount } from '@/utils/money'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useHasAccessPermisson } from '@/hooks/useAccessPermisson'
import { payrollSidebarPermissions } from '@/layout/LayoutComponents/utils'

type Props = {
    payHistory: OrgPayrollHistoryType[]
}

export default function OrgPayHistoryTable({ payHistory }: Props) {
    const columnHelper = createColumnHelper<OrgPayrollHistoryType>()
    const { setSelectedPayHistory } = usePayrollContext()
    const [rowSelection, setRowSelection] = React.useState({})
    const navigate = useNavigate()
    const hasPermission = useHasAccessPermisson(payrollSidebarPermissions)

    const handleRowClick = (data: OrgPayrollHistoryType) => {
        const startDate = format(new Date(data?.current_period?.start), 'yyyy-MM-dd')
        const pay_date = format(new Date(data?.pay_date), 'yyyy-MM-dd')
        const endDate = format(new Date(data.current_period.end), 'yyyy-MM-dd')

        setSelectedPayHistory(data)
        hasPermission
            ? navigate(
                  `/dashboard/payroll/org-payroll-history?start=${startDate}&end=${endDate}&pay_date=${pay_date}&id=${data._id ?? data?.id}`
              )
            : navigate(`/dashboard/payroll/pay_history/${data._id}`)
        return data
    }

    const columns: ColumnDef<OrgPayrollHistoryType, any>[] = [
        columnHelper.accessor('pay_date', {
            header: () => <p>Pay date</p>,
            cell: cell => <>{cell.renderValue() && format(new Date(cell.renderValue()), 'dd/MM/yyyy')}</>,
            enableSorting: true,
        }),
        columnHelper.accessor('current_period.start', {
            header: () => <>Pay period</>,
            cell: cell => {
                return (
                    <div className='flex gap-2'>
                        <span> {cell.renderValue() && format(new Date(cell.renderValue()), 'dd/MM/yyyy')}</span>
                        {'  '} -{'  '}
                        <span>
                            {cell.row.original?.current_period?.end &&
                                format(new Date(cell.row.original?.current_period?.end), 'dd/MM/yyyy')}
                        </span>
                    </div>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('employees_paid', {
            header: () => <p>Users paid</p>,
            cell: cell => cell?.renderValue(),
            enableSorting: true,
        }),
        columnHelper.accessor('total_payout', {
            header: () => <p>Total payout</p>,
            cell: cell => <span>{formatAmount(cell?.renderValue())}</span>,
            enableSorting: true,
        }),
        columnHelper.accessor('budget.name', {
            header: () => <p>Linked budget</p>,
            cell: cell => <span>{cell?.renderValue() ?? 'N/A'}</span>,
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            cell: cell => {
                return (
                    <TableActions
                        actions={[
                            {
                                label: 'View payroll details',
                                method: () => {
                                    setSelectedPayHistory(cell?.row?.original)
                                    handleRowClick(cell.row.original)
                                },
                            },
                        ]}
                    />
                )
            },
        }),
    ]

    return (
        <section className='h-full'>
            <Table
                data={payHistory}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                rowClickable={true}
                handleRowClicked={handleRowClick}
            />
        </section>
    )
}
