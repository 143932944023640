import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import TableActions from '@/components/Table/TableActions'
import Table from '@/components/Table'
import React, { useState } from 'react'
import TransactionStatusBadge from './TransactionStatusBadge'
import TransactionEmptyStateIcon from '@/assets/v2dashboard/recent-transactions.svg'
import { TransactionStatus } from '../const'
import { FlagIcon } from '@/assets/assets.export'
import { TransactionType, TransactionTypeType } from '@/types/transactions'
import { format } from 'date-fns'
import { formatAmountNoSymbol } from '@/utils/money'
import { useNavigate } from 'react-router-dom'
import { useTransactionContext } from '@/context'
import { capitalizeText } from '@/utils/string'
import { QueryOrgMembers } from '@/services/employees/types'
import FlagTransactionModal from '@/components/Modals/Transactions/FlagTransaction/FlagTransactionModal'
import RedoTransaction from '@/components/Modals/Transactions/RedoTransactionModal'
import { useSendTransferOtp } from '@/services/payout/mutations'

interface TransactionHistoryProps {
    transactions: TransactionType[]
    orgMembers?: QueryOrgMembers
}
export type TransactionHistoryType = {
    id: string
    date: string
    amount: string
    initiatedBy: string
    destination: string
    type: string
    status: TransactionStatus
    flaged: boolean
    description: string
}

const TransactionHistoryTable = ({ transactions }: TransactionHistoryProps) => {
    const {
        setSelectedTransactions,
        selectedTransactions,
        setPageNumber,
        pageNumber,
        setPageNumberClicked,
        pagination_details,
    } = useTransactionContext()

    const { mutate: sendTransferOtpFn } = useSendTransferOtp({})

    const [showFlagModal, setShowFlagModal] = useState(false)
    const [openRedoModal, setOpenRedoModal] = useState(false)
    const [flagTransaction, setFlagTransaction] = useState<'FLAG' | 'UNFLAG'>()
    const columnHelper = createColumnHelper<TransactionType>()
    const [rowSelection, setRowSelection] = React.useState({})

    const navigate = useNavigate()

    //context api to sort the table
    const columns: ColumnDef<TransactionType, any>[] = [
        columnHelper.accessor('transaction_id', {
            header: () => {
                return <p className='whitespace-nowrap text-[95%] '>Transaction ID</p>
            },
            cell: cell => (
                <div>
                    <p className='font-normal mb-1 text-sm'>{cell.renderValue()}</p>

                    {cell.row.original?.flagged && <FlagIcon className='h-4 w-4' />}
                </div>
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('createdAt', {
            header: () => <p className='whitespace-nowrap text-[95%]'>Date & time</p>,
            cell: cell => (
                <div>
                    <p className='font-normal mb-1 text-sm'>{format(new Date(cell.renderValue()), 'dd/MM/yyyy')}</p>
                    <p className='font-normal mb-1 text-sm'>{format(new Date(cell.renderValue()), 'hh:mm a')}</p>
                </div>
            ),
            enableSorting: true,
        }),
        columnHelper.accessor('debit.amount', {
            header: () => <p>Amount</p>,
            cell: cell => {
                const debitAmt = cell.renderValue()
                return (
                    <span className='font-normal whitespace-nowrap'>
                        {cell.row.original.ownerTransactionType === 'DEBIT' ? (
                            <span className='text-[#CF491E]'>
                                <span className='font-medium'>{cell.row.original?.currency?.symbol ?? '₦'}</span>
                                {formatAmountNoSymbol(debitAmt)}
                            </span>
                        ) : (
                            <span className='text-[#19C37D]'>
                                <span className='font-medium'>{cell.row.original?.currency?.symbol ?? '₦'}</span>

                                {formatAmountNoSymbol(cell.row.original.credit.amount)}
                            </span>
                        )}
                    </span>
                )
            },
            enableSorting: true,
        }),
        columnHelper.accessor('debit.account.user.first_name', {
            header: () => <p>Initiated by</p>,
            cell: cell => {
                const owner = cell.row.original.initiated_by
                const orgName = capitalizeText(cell?.row.original?.owner?.org_name)
                const transactionType = cell.row.original?.type

                return (
                    <span className='font-normal '>
                        {transactionType === TransactionTypeType.SUBSCRIPTION
                            ? orgName
                            : owner?.first_name
                              ? `${capitalizeText(owner?.first_name)} ${capitalizeText(owner?.last_name?.[0])}`
                              : 'N/A'}
                    </span>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('credit.account.user.first_name', {
            header: () => <p>Destination</p>,
            cell: cell => {
                const vendorName = capitalizeText(cell.row.original?.metadata?.recipient?.name)
                const userName = cell.row.original?.credit?.account?.user
                    ? capitalizeText(cell.row.original?.credit?.account?.user?.first_name) +
                      ' ' +
                      capitalizeText(cell.row.original?.credit?.account?.user?.last_name?.[0])
                    : 'N/A'
                const orgName = capitalizeText(cell?.row.original?.owner?.org_name)
                const creditDetails = cell?.row.original?.credit
                const transactionType = cell.row.original?.type
                return (
                    <span className='font-normal '>
                        {transactionType === TransactionTypeType.INVOICE ||
                        transactionType === TransactionTypeType.WALLET_FUNDING
                            ? orgName
                            : transactionType === TransactionTypeType.SUBSCRIPTION ||
                                transactionType === TransactionTypeType.WALLET_DEDUCTION
                              ? 'Bizpend Technologies'
                              : transactionType === TransactionTypeType.TRANSFER ||
                                  transactionType === TransactionTypeType.ACCOUNT_FUNDING
                                ? cell.row.original?.recipient_type === 'vendors'
                                    ? vendorName
                                    : creditDetails?.financial_account_details?.account_name
                                      ? `${creditDetails?.financial_account_details?.account_name} (${creditDetails?.financial_account?.nickname ?? creditDetails?.financial_account?.provider})`
                                      : 'N/A'
                                : userName}
                    </span>
                )
            },
            enableSorting: false,
        }),
        columnHelper.accessor('type', {
            header: () => <p>Type</p>,
            cell: cell => (
                <span className='font-normal'>
                    {cell.renderValue() === TransactionTypeType.TRANSFER
                        ? cell?.row?.original?.description
                        : cell?.renderValue()}
                </span>
            ),
            enableSorting: false,
        }),
        columnHelper.accessor('status', {
            header: () => <p>Status</p>,
            cell: cell => {
                return (
                    <>
                        <TransactionStatusBadge status={cell.renderValue().toLowerCase()} />
                    </>
                )
            },
            enableSorting: false,
        }),
        columnHelper.display({
            id: 'actions',
            cell: cell => (
                <TableActions
                    actions={[
                        {
                            label: 'More details',
                            method: () => {
                                setSelectedTransactions(cell?.row?.original)
                                navigate(
                                    `/dashboard/transactions/transactions_history/${
                                        cell.row.original.id ?? cell.row.original._id
                                    }`
                                )
                            },
                        },
                        {
                            label: `${cell?.row.original?.status === 'COMPLETED' ? 'Repeat' : 'Retry'} transaction`,
                            method: () => {
                                setSelectedTransactions(cell?.row?.original)
                                setOpenRedoModal(true)
                                sendTransferOtpFn({})
                            },
                            hidden: cell.row.original.type !== 'Transfer' || cell.row.original.status === 'PROCESSING',
                        },
                        {
                            label: `${cell?.row.original?.flagged ? 'Unflag' : 'Flag'} transaction`,
                            method: () => {
                                setSelectedTransactions(cell?.row?.original)
                                setFlagTransaction(cell?.row.original?.flagged ? 'UNFLAG' : 'FLAG')
                                setShowFlagModal(true)
                            },
                            actionColor: '#CF491E',
                            hidden: cell.row.original.status === 'PROCESSING',
                        },
                    ]}
                />
            ),
        }),
    ]
    const handleRowClick = (transaction: TransactionType) => {
        setSelectedTransactions(transaction)
        navigate(`/dashboard/transactions/transactions_history/${transaction.id ?? transaction._id}`)
    }

    return (
        <div>
            <Table
                data={transactions}
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleRowClicked={handleRowClick}
                rowClickable={true}
                emptyStateTitle='No transactions'
                emptyStateDescription='No transaction has been made yet'
                emptyStateImg={TransactionEmptyStateIcon}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                pagination_data={pagination_details}
                setIsPageNumberClicked={setPageNumberClicked}
            />

            {showFlagModal && (
                <FlagTransactionModal
                    transactionId={selectedTransactions?._id ?? selectedTransactions?.id ?? ''}
                    closeModal={setShowFlagModal}
                    flagDetails={flagTransaction}
                />
            )}
            {openRedoModal && (
                <RedoTransaction closeModal={setOpenRedoModal} transactionDetails={selectedTransactions} />
            )}
        </div>
    )
}

export default TransactionHistoryTable
