import { Button } from '@/components/Button'
import { toastHandler } from '@/components/utils/Toast'
import { useApproveRequestAccess, useDeclineRequestAccess } from '@/services/auth/mutations'
import { RequestAccessType } from '@/types/request-access'
import ModalWrapper from '../ModalWrapper'

type Props = {
    closeModal: () => void
    accessRequest?: RequestAccessType
    requestType: 'approve' | 'delete'
}

export const RequestAccessConfirmationModal = ({ closeModal, accessRequest, requestType }: Props) => {
    const { mutate: approveRequestFn, isLoading } = useApproveRequestAccess({
        queryParams: {
            id: accessRequest?.id as string,
        },
        onSuccess: () => {
            toastHandler({ message: `Access request approved`, state: 'success' })
            closeModal()
        },
    })
    const { mutate: declineRequestFn, isLoading: isRejecting } = useDeclineRequestAccess({
        queryParams: {
            id: accessRequest?.id as string,
        },
        onSuccess: () => {
            toastHandler({ message: `Access request declined`, state: 'success' })
            closeModal()
        },
    })
    return (
        <ModalWrapper variants={undefined}>
            <div className='border border-[#DADCE0] w-screen lg:w-[540px] px-8 py-8 rounded-[1.3rem]'>
                <p>
                    Are you sure you want to {requestType === 'delete' ? 'decline' : requestType} this access request?
                </p>
                {/* Buttons */}
                <div className='mt-10 flex justify-end'>
                    <button
                        type='button'
                        className='min-h-[50px] px-5 mx-3'
                        onClick={() => closeModal()}
                        disabled={isLoading || isRejecting}
                    >
                        Cancel
                    </button>
                    <Button
                        className='bg-[#CF491E]/80 hover:bg-[#CF491E]'
                        loading={isLoading || isRejecting}
                        disabled={isLoading || isRejecting}
                        onClick={() => {
                            if (requestType === 'approve') {
                                approveRequestFn({})
                                return
                            }
                            declineRequestFn({})
                        }}
                    >
                        Yes, {requestType === 'delete' ? 'decline' : requestType}
                    </Button>
                </div>
            </div>
        </ModalWrapper>
    )
}
