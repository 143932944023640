// import downArrow from '@/assets/down-arrow.svg'

import { Button } from '@/components'
import BanksNotLinked from '@/components/Modals/Payroll/BanksNotLinked/BanksNotLinked'
import { PayrollProcessRoot } from '@/components/Modals/Payroll/PayrollProcess/PayrollProcessRoot'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext } from '@/context'
import { useGetPayrollListForOrg } from '@/services/payroll/queries'
import { PayrollListType } from '@/services/payroll/types'
import { formatAmount } from '@/utils/money'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import RunPayrollListTable from './RunPayrollListTable'
import useAccessToModule from '@/hooks/useAccessToModule'
import { ModuleNameEnum } from '@/types/billing'

import notFound from '@/assets/not-found.svg'

const RunPayrollDetailsPage = () => {
    const [showFlow, setShowFlow] = useState(false)

    const [removeErrorBannner, setRemoveErrorBanner] = useState(true)
    const [bankLinkedByEmployee, setBankLinkedByEmployee] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')

    const { selectedAccount, setShowUpgradeModal, setCurrentModule } = useAuthContext()
    const { enabled: isPayrollEnabled } = useAccessToModule({ module: ModuleNameEnum.PAYROLL })

    const { data, isLoading, isRefetching } = useGetPayrollListForOrg({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            search: searchQuery,
        },
    })

    const payrollDetails = useMemo(
        () =>
            data && !!Object.values(data ?? {}).length
                ? data
                : ({
                      summary: {
                          total_payout: 0,
                          total_salary: 0,
                          number_of_employees: 0,
                      },
                      list: [],
                  } as PayrollListType),
        [data]
    )

    const deets = [
        {
            title: 'Users',
            desc: payrollDetails.summary.number_of_employees,
        },
        {
            title: 'Total salary',
            desc: formatAmount(payrollDetails.summary.total_salary),
        },
        {
            title: 'Total Payout',
            desc: formatAmount(payrollDetails.summary.total_payout),
        },
    ]

    const payrollHasEmployeesWithoutBank = payrollDetails.list.some(payroll => !payroll.bank)
    const employeesWithOverdueSalaries = payrollDetails.list.some(payroll => payroll.due_status === 'overdue')

    const handleSearchRunPayroll = () => {
        return payrollDetails?.list?.filter(payroll => {
            const first_name = payroll?.account?.user?.first_name.toLowerCase()
            const last_name = payroll?.account?.user?.last_name.toLowerCase()

            return (
                `${first_name} ${last_name}`.includes(searchQuery.toLowerCase()) ||
                `${last_name} ${first_name}`.includes(searchQuery.toLowerCase())
            )
        })
    }

    useEffect(() => {
        handleSearchRunPayroll()
    }, [searchQuery])

    if (isLoading) return <ProgressBar />

    return (
        <section className='py-3 pt-2 mb-6  min-h-screen'>
            <AnimatePresence>
                {bankLinkedByEmployee && (
                    <BanksNotLinked
                        modalOpen={bankLinkedByEmployee}
                        closeModal={setBankLinkedByEmployee}
                        employeesPayrollData={payrollDetails.list}
                    />
                )}
            </AnimatePresence>

            <section className='rounded-xl py-6 flex flex-col gap-6 mb-4'>
                <div className='rounded-2xl px-6 py-3 bg-white flex justify-between items-center '>
                    <div className='flex justify-between lg:justify-normal w-full lg:gap-20 items-center '>
                        {deets.map((deet, idx) => (
                            <div className='' key={idx}>
                                <h1 className='font-medium text-sm flex gap-1 items-center'>{deet.title}</h1>
                                <p className='mt-2 text-gray7'>{deet.desc}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {payrollHasEmployeesWithoutBank && (
                    <div className='text-[#5E5E5E] bg-[#FFEDED] p-4 rounded-lg'>
                        <h5 className='font-semibold mb-2'>Note</h5>
                        <p className='text-sm'>
                            Some users have not linked their bank to their accounts so you cannot process this payroll.
                            To view users with no bank linked, click
                            <span
                                className='cursor-pointer ml-1 text-[#454ADE] font-semibold'
                                onClick={() => setBankLinkedByEmployee(true)}
                            >
                                here
                            </span>
                        </p>
                    </div>
                )}
            </section>

            <section className='bg-white p-4 rounded-2xl'>
                <div className='ml-auto flex justify-between mt-2 border-b border-gray6 pb-3 mb-6 items-center'>
                    <>
                        <p className='font-medium'>Salaries for this payroll</p>
                        {!!payrollDetails.list.length && (
                            <Button
                                className='bg-[#19C37D] text-white px-3 min-h-[40px] min-w-0 rounded-md text-xs '
                                onClick={() => {
                                    if (!isPayrollEnabled) {
                                        setShowUpgradeModal(true)
                                        setCurrentModule(ModuleNameEnum.PAYROLL)
                                        return
                                    }
                                    setShowFlow(true)
                                }}
                            >
                                Process payroll
                            </Button>
                        )}
                    </>
                </div>
                <div className='flex justify-between items-center'>
                    <div className='border border-[#DADCE0] h-[40px] w-[60%] lg:w-[30%] rounded flex items-center overflow-hidden px-3 '>
                        <input
                            type='text'
                            placeholder='Search user by name'
                            className='h-full w-[90%] outline-none text-xs'
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>
                {employeesWithOverdueSalaries && !removeErrorBannner && (
                    <div className='flex items-center justify-between bg-errorRedLight p-3 text-gray7 rounded-md mt-8'>
                        <div>
                            <h3 className='font-medium'>Note</h3>
                            <p className='text-sm'>
                                Some users have overdue salaries. You can pay overdue salaries alongside due salaries or
                                archive them.
                            </p>
                        </div>
                        <button className='text-sm font-medium' onClick={() => setRemoveErrorBanner(false)}>
                            Dismiss
                        </button>
                    </div>
                )}
                <div className='mt-10 overflow-y-scroll'>
                    {payrollDetails?.list?.length === 0 && isRefetching ? (
                        <ProgressBar />
                    ) : (payrollDetails?.list?.length === 0 && searchQuery?.length < 1) ||
                      (payrollDetails?.list?.length > 0 && searchQuery?.length >= 0) ? (
                        <section
                            className={` transition-all rounded-lg ${isRefetching ? 'opacity-30' : 'opacity-100'} `}
                        >
                            <RunPayrollListTable payrolls={handleSearchRunPayroll()} />
                        </section>
                    ) : (
                        <div className='h-[300px] flex flex-col text-center justify-center items-center'>
                            {searchQuery && payrollDetails?.list?.length === 0 ? (
                                <>
                                    <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                    <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                    <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                        "Oops! It seems we couldn't find any matching results for your search. Please
                                        try again with different keywords or refine your filter criteria for better
                                        results.
                                    </span>
                                </>
                            ) : (
                                <ProgressBar />
                            )}
                        </div>
                    )}
                </div>
            </section>

            {showFlow && <PayrollProcessRoot closeModal={setShowFlow} payrollDetails={payrollDetails} single={false} />}
        </section>
    )
}

export default RunPayrollDetailsPage
