import notFound from '@/assets/not-found.svg'
import PayHistoryEmptyState from '@/assets/pay-history-empty-state.svg'
import ProgressBar from '@/components/ProgressBar'
import { useAuthContext } from '@/context'
import { useGetUserPayrollHistory } from '@/services/payroll/queries'
import { PayrollHistoryType } from '@/types/payroll'
import { useMemo, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import PayHistoryTable from '../../components/PayHistoryTable'

const PayHistory = () => {
    const [searchQuery, setSearchQuery] = useState('')
    const { selectedAccount } = useAuthContext()

    const { data, isLoading, isRefetching } = useGetUserPayrollHistory({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
            owner: selectedAccount?.id as string,
        },
    })

    const pay_history = useMemo(() => (data as unknown as PayrollHistoryType[]) ?? [], [data])

    if (isLoading) return <ProgressBar />
    return (
        <div className='w-full py-6 block'>
            <div className='flex items-center justify-between mb-6'>
                {pay_history?.length > 0 && (
                    <div className='border border-[#DADCE0] h-[56px] w-[60%] md:w-[35%] rounded-lg flex items-center overflow-hidden '>
                        <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input
                            type='text'
                            placeholder='Search by amount'
                            className='h-full w-full outline-none'
                            onChange={e => setSearchQuery(e.target.value)}
                        />
                    </div>
                )}
                <div className=''>&nbsp;</div>
                ca{' '}
            </div>
            {pay_history?.length > 0 ? (
                <section
                    className={`overflow-y-scroll pb-4 transition-all rounded-lg ${isRefetching ? 'opacity-30' : ''}`}
                >
                    <PayHistoryTable payHistory={pay_history} />
                </section>
            ) : (
                <div className='h-450px] flex flex-col text-center justify-center items-center'>
                    {searchQuery ? (
                        <>
                            <img src={notFound} alt='Not found' className='mt-6 h-[270px]' />
                            <span className='text-3xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                            <span className='text-[#202020] text-[16px] w-[45%] mx-auto mt-3 font-medium'>
                                "Oops! It seems we couldn't find any matching results for your search. Please try again
                                with different keywords or refine your filter criteria for better results.
                            </span>
                        </>
                    ) : (
                        <>
                            <h3 className='text-2xl font-bold text-[#5E5E5E] mt-14'>No Payment made!</h3>
                            <span className='text-[#202020] text-[14px] w-[45%] mx-auto mt-3 font-normal'>
                                Monitor payments that are being paid to your bank account
                            </span>
                            <img
                                src={PayHistoryEmptyState}
                                alt='Payroll Empty state'
                                className='mt-6 h-[200px] aspect-square'
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default PayHistory
