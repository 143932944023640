import React, { useMemo, useState } from 'react'
import { staticField } from '../utils'
import { PencilIcon, Plus } from 'lucide-react'
import { DeleteIcon } from '@/assets/assets.export'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { CustomFields } from '@/components/Modals/Accounting/AccountingSetupModal'
import CreateCustomFieldsModal from '@/components/Modals/Accounting/CreateCustomFieldsModal'
import ModalWrapper from '@/components/Modals/ModalWrapper'
import { useGetCustomFields } from '@/services/accounting/queries'
import { useCreateCustomFields, useUpdateCustomFields } from '@/services/accounting/mutations'
import DeleteCustomFieldsPrompt from '@/components/Modals/Accounting/DeleteCustomFieldsPrompt'
import { CustomFieldsType } from '@/types/accounting'

const TransactionFields = () => {
    const form = useForm<{ customFields: CustomFields[] }>()
    const [openCustomFieldsModal, setOpenCustomFieldsModal] = useState(false)
    const [openDeletePrompt, setOpenDeletePrompt] = useState(false)
    const [selectedField, setSelectedField] = useState<CustomFieldsType>()

    const [editCustomField, setEditCustomField] = useState(false)

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'customFields',
    })

    const closeModal = () => {
        setOpenCustomFieldsModal(false)
        setEditCustomField(false)
        remove(fields.length)
    }

    const { data, isLoading: isGettingFields, isRefetching } = useGetCustomFields()

    const customFieldsData = useMemo(() => data ?? [], [data])

    const { mutate, isLoading } = useCreateCustomFields({
        onSuccess: () => {
            closeModal()
            form.setValue('customFields', [])
        },
    })
    const { mutate: updateCustomFieldsfn, isLoading: isUpdating } = useUpdateCustomFields({
        onSuccess: () => {
            closeModal()
            form.setValue('customFields', [])
            setSelectedField(undefined)
        },
    })

    const handleSubmit = () => {
        const customFields = ((form.watch('customFields') ?? []) as CustomFields[])?.map(data => ({
            field_name: data?.name,
            possible_values: data.uploadedField,
            description: data?.description,
        }))

        editCustomField
            ? updateCustomFieldsfn({
                  id: selectedField?.id,
                  ...customFields[0],
              })
            : mutate({
                  data: customFields,
              })
    }

    return (
        <FormProvider {...form}>
            <form onSubmit={e => e.preventDefault()} id='accountingCustomFieldsSettings'>
                <section className='mb-5 bg-white rounded-xl p-7 py-5 flex-1'>
                    <div className='border-gray/40 border-b pb-3'>
                        <h2 className='font-medium'>Transaction fields</h2>
                        <p className='text-sm text-gray7'>Configure the fields for your transaction table</p>
                    </div>
                    <div className='mt-8 mb-3'>
                        <h4 className='text-base font-medium text-black'>Default fields</h4>
                        <p className='text-xs text-gray7'>These are the default fields for transactions table</p>
                    </div>

                    <div className='rounded-lg border border-gray5 overflow-hidden mt-2 w-fit'>
                        <table className='w-fit leading-normal border-collapse text-xs'>
                            <thead>
                                <tr>
                                    {['Field name', 'Description'].map((col, i) => (
                                        <th
                                            key={i}
                                            className=' border-gray5 px-5 h-10  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'
                                        >
                                            {col}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {staticField.map((field, i) => (
                                    <tr key={i}>
                                        <td className='border-gray5 px-5 h-12 border-t font-medium text-gray7'>
                                            {field.name}
                                        </td>
                                        <td className='border-gray5 px-5 h-12 border-t  font-medium text-gray7'>
                                            {field.desc}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className=' border-y border-gray8 my-5 pb-5'>
                        {customFieldsData.length > 0 && (
                            <div className='mt-5 mb-3'>
                                <h4 className='text-base font-medium text-black'>Custom fields</h4>
                                <p className='text-xs text-gray7'>You can add more fields to your table</p>
                            </div>
                        )}
                        {customFieldsData.length > 0 && (
                            <div
                                className={`rounded-lg border border-gray5 overflow-hidden mt-2 ${isGettingFields || isRefetching ? 'opacity-50' : ''} `}
                            >
                                <table className='w-full leading-normal border-collapse text-xs'>
                                    <thead>
                                        <tr>
                                            {['Field name', 'Description', ''].map((col, i) => (
                                                <th
                                                    key={i}
                                                    className=' border-gray5 px-5 h-10  bg-[#f9f9f9] text-left font-semibold tracking-wider text-black/90'
                                                >
                                                    {col}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customFieldsData.map((field, i) => (
                                            <tr key={i}>
                                                <td className='border-gray5 px-5 h-12 border-t font-medium text-gray7'>
                                                    {field.field_name}
                                                </td>
                                                <td className='border-gray5 px-5 h-12 border-t  font-medium text-gray7'>
                                                    {field.description ?? 'N/A'}
                                                </td>
                                                <td className='border-gray5 px-5 h-12 border-t  text-gray7 flex items-center gap-4'>
                                                    <button
                                                        aria-label='pencil'
                                                        onClick={() => {
                                                            setEditCustomField(true)
                                                            setOpenCustomFieldsModal(true)
                                                            setSelectedField(field)
                                                            form.setValue('customFields', [
                                                                {
                                                                    name: field.field_name,
                                                                    description: field.description,
                                                                },
                                                            ])
                                                        }}
                                                    >
                                                        <PencilIcon className='w-4 h-4' />
                                                    </button>
                                                    <button
                                                        aria-label='delete'
                                                        onClick={() => {
                                                            setSelectedField(field)
                                                            setOpenDeletePrompt(true)
                                                        }}
                                                    >
                                                        <DeleteIcon className='w-7 h-7' />{' '}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <div>
                        <button
                            onClick={() => setOpenCustomFieldsModal(true)}
                            aria-label='Add more'
                            className='text-brand text-xs font-medium flex items-center gap-1'
                        >
                            <Plus size={15} /> Add custom field
                        </button>
                    </div>

                    {openDeletePrompt && (
                        <DeleteCustomFieldsPrompt
                            closeModal={() => setOpenDeletePrompt(false)}
                            id={selectedField?.id as string}
                        />
                    )}
                    {openCustomFieldsModal && (
                        <ModalWrapper
                            formId='accountingCustomFieldsSettings'
                            shouldConfirmClose
                            closeModal={() => {
                                setOpenCustomFieldsModal(false)
                                setSelectedField(undefined)
                            }}
                        >
                            <CreateCustomFieldsModal
                                index={fields.length === 0 ? 0 : fields.length - 1}
                                addMoreField={() => {
                                    append({ name: '', description: '', required: false })
                                }}
                                closeModal={() => {
                                    setOpenCustomFieldsModal(false)
                                    setEditCustomField(false)
                                }}
                                nextStep={handleSubmit}
                                loading={isLoading || isUpdating}
                                edit={editCustomField}
                                fields={fields}
                            />
                        </ModalWrapper>
                    )}
                </section>
            </form>
        </FormProvider>
    )
}

export default TransactionFields
