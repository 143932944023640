import { Button, Input } from '@/components'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext, usePayrollContext } from '@/context'
import usePersistedState from '@/hooks/usePersistedState'
import { useMakePaymentContract, useMilestonePaymentOtp, useRunPayroll } from '@/services/payroll/mutations'
import { PayrollListType } from '@/services/payroll/types'
import { ContractPayrollType, MilestoneType } from '@/types/payroll'
import { calculateTransferFee, formatAmount } from '@/utils/money'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import ModalWrapper from '../../ModalWrapper'
import { useGeneratePaymentOTP, useVerifyPaymentOTP } from '@/services/auth/mutations'
import { SourceType } from '@/utils/enum'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    setSteps: React.Dispatch<React.SetStateAction<number>>
    payday?: string
    payrollId?: () => string[]
    mileStoneData?: MilestoneType
    contract?: ContractPayrollType
    isMileStone?: boolean
    fee?: number
    payrollDetails?: PayrollListType
    single?: boolean
    formState: {
        account: string
        budgetId: string
    }
}

export default function PayrollProcess2({
    closeModal,
    setSteps,
    formState,
    contract,
    isMileStone,
    mileStoneData,
    payrollDetails,
    single,
}: Props) {
    const { control, watch } = useForm()
    const { selectedAccount } = useAuthContext()
    const { setSelectedContract } = usePayrollContext()

    const navigate = useNavigate()

    const [timer, setTimer] = usePersistedState({ key: 'payrollTimer', defaultValue: 120 })

    const { mutate: generateOtp } = useGeneratePaymentOTP({})

    const watchOtp = watch('otp')

    const { mutate: runPayrollFn, isLoading } = useRunPayroll({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
        onSuccess() {
            toastHandler({ message: 'Payment Proccessing', state: 'success' })
            closeModal(false)
            navigate('/dashboard/payroll/run_payroll?accountType=company')
        },
    })
    const { mutate: makeContractPaymentFn, isLoading: isMakingContractPayment } = useMakePaymentContract({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
        onSuccess: () => {
            closeModal(false)
            setSelectedContract(undefined)
            toastHandler({ message: 'Your payment is being processed', state: 'success' })
        },
    })

    const { mutate: verifyOtp, isLoading: isVerifying } = useVerifyPaymentOTP({
        onSuccess: () => {
            if (isMileStone) {
                makeContractPaymentFn({
                    contract_id: contract?.id,
                    milestone_id: mileStoneData?.id ?? mileStoneData?._id,
                    progress_status: 'approved',
                    budget: formState?.budgetId.length > 0 ? formState?.budgetId : undefined,
                    fee: calculateTransferFee(mileStoneData ? mileStoneData?.amount : '0'),
                    source_type:
                        formState.account !== SourceType.Wallet ? SourceType.DepositAccount : SourceType.Wallet,
                    source_id: formState.account !== SourceType.Wallet ? formState.account : undefined,
                })
                return
            }

            runPayrollFn({
                payrolls: single
                    ? [(payrollDetails as unknown as PayrollListType['list'][0])?.id]
                    : payrollDetails?.list.map(payroll => payroll.id),
                budget: formState?.budgetId ? formState?.budgetId : undefined,
                fee: fee ? fee : undefined,
                source_type: formState.account !== SourceType.Wallet ? SourceType.DepositAccount : SourceType.Wallet,
                source_id: formState.account !== SourceType.Wallet ? formState.account : undefined,
            })
        },
    })

    const { mutate: mileStoneOtpFn } = useMilestonePaymentOtp({})

    const fee = 5000 * (single ? 1 : payrollDetails?.list.length || 0) // number of items in payroll x 50 TODO: get the fee from somewhere else not hardcoded
    const totalDebitAmount =
        Number(
            single
                ? (payrollDetails as unknown as PayrollListType['list'][0])?.current_payout
                : payrollDetails?.summary.total_payout
        ) + fee

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1)
            }
            if (timer === 0) {
                setTimer(0)
                clearInterval(interval)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    if (isLoading)
        return (
            <ModalWrapper>
                <PayrollLoading />
            </ModalWrapper>
        )
    return (
        <section className='w-screen lg:w-[35rem] p-10 px-5 lg:px-10 z-50'>
            <h4 className='text-[#202020] text-lg font-semibold'>Verify action</h4>
            <p className='mt-[10px] font-medium text-[#202020] text-[14px]'>
                For security reasons, an OTP has been sent to your email to verify this action. Kindly enter OTP to
                proceed
            </p>
            {!isMileStone && (
                <div className='bg-[#F9F9F9] rounded-xl mt-3 p-5 pb-4  mb-8 flex flex-col gap-3 justify-between'>
                    <div className='flex justify-between'>
                        <h3 className='text-[#5E5E5E] text-base'>Salary payout</h3>
                        <p className='text-base font-semibold text-black'>
                            {formatAmount(
                                single
                                    ? (payrollDetails as unknown as PayrollListType['list'][0])?.current_payout
                                    : payrollDetails?.summary.total_payout
                            )}
                        </p>
                    </div>
                    <div className='flex justify-between'>
                        <h3 className='text-[#5E5E5E] text-base'>Payout fee</h3>
                        <p className='text-base font-semibold text-black'>{formatAmount(fee)}</p>
                    </div>
                    <div className='flex justify-between'>
                        <h3 className='text-[#5E5E5E] text-base'>Debit amount</h3>
                        <p className='text-base font-semibold text-black'>{formatAmount(totalDebitAmount)}</p>
                    </div>
                    <div className='flex justify-between'>
                        <h3 className='text-[#5E5E5E] text-base'>User(s)</h3>
                        <p className='text-base font-semibold text-black'>{single ? 1 : payrollDetails?.list.length}</p>
                    </div>
                </div>
            )}
            <form>
                <div className='flex flex-col md:flex-row mt-6 '>
                    <div className='flex flex-col flex-1 gap-3'>
                        <label htmlFor='email' className='text-[#202020] text-[14px]'>
                            Enter OTP
                        </label>
                        <Controller
                            name='otp'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    className='h-[3.5rem]'
                                    type='number'
                                    {...field}
                                    onChange={e => {
                                        if (e.target.value.length > 4) return
                                        field?.onChange(e)
                                    }}
                                    placeholder='Enter your OTP'
                                />
                            )}
                        />
                    </div>
                </div>
            </form>
            <p className='mt-4 text-sm font-medium'>
                I did not receive OTP.{' '}
                <button
                    className='text-[#454ADE] font-semibold disabled:text-[#A8A8A8]'
                    onClick={() => {
                        setTimer(120)
                        if (isMileStone) {
                            mileStoneOtpFn({})
                            return
                        }
                        generateOtp({})
                    }}
                    disabled={timer > 0}
                >
                    {' '}
                    Resend
                </button>{' '}
                {timer !== 0 && <span className='text-[#A8A8A8]'>in {timer < 10 ? `0${timer}` : timer} secs</span>}
            </p>

            <div className='flex justify-end gap-6 mt-12'>
                <Button
                    className='bg-transparent min-w-[100px] text-[#202020] hover:bg-transparent'
                    onClick={() => setSteps(1)}
                >
                    Cancel
                </Button>
                <Button
                    className='bg-[#19C37D] hover:bg-[#255541] min-w-[100px] px-4'
                    onClick={() => {
                        verifyOtp({ otp: watchOtp })
                    }}
                    disabled={watchOtp?.length < 4}
                    loading={isLoading || isMakingContractPayment || isVerifying}
                >
                    Proceed
                </Button>
            </div>
        </section>
    )
}

export const PayrollLoading = () => {
    return (
        <div className='w-[35rem] h-[325px] relative overflow-scroll my-6'>
            <div className='flex flex-col justify-center items-center h-full px-32 bg-[#FFFFFF] rounded-xl'>
                <div className='border-t border-[#A8A8A8] w-[400px] h-[184px] flex justify-center  mb-[2rem] rotate-180'>
                    <div
                        className='w-16 h-[184px] bg-[#9FDA96] rounded-b-lg animate-animate-up-down'
                        style={{ animationDelay: '600ms' }}
                    ></div>
                    <div
                        className='w-16 h-[184px] bg-[#FFAB90] rounded-b-lg animate-animate-up-down'
                        style={{ animationDelay: '400ms' }}
                    ></div>
                    <div
                        className='w-16 h-[184px] bg-[#7E82FB] rounded-b-lg animate-animate-up-down delay-[400ms]'
                        style={{ animationDelay: '200ms' }}
                    ></div>
                    <div className='w-16 h-[184px] bg-[#F5D484] rounded-b-lg animate-animate-up-down delay-[600ms]'></div>
                </div>
                <p className='text-base font-normal mx-auto text-center'>We are currently processing your payroll</p>
            </div>
        </div>
    )
}
