import { useState } from 'react'
import ManageGLCode from '../components/ManageGLCode'
import CategoryMapping from '../components/CategoryMapping'
import AccountMapping from '../components/AccountMapping'
import AccountingRules from '../components/AccountingRules'
import TransactionFields from '../components/TransactionFields'

export default function AccountingSettings() {
    const [step, setStep] = useState(1)

    return (
        <section className='mt-5'>
            <div>
                <h2 className='text-xl font-semibold'>Settings</h2>
                <p className='text-sm text-gray7'>Manage integrations, chart of accounts and accounts mapping</p>
            </div>
            <section className='flex flex-col lg:flex-row mt-10 gap-8'>
                <div
                    className='w-48 h-fit bg-white flex flex-col gap-4 rounded-xl p-5  text-sm items-start shadow-sm
                    '
                >
                    {[
                        'Charts of Account',
                        'Account mapping',
                        'Transaction Category',
                        'Accounting Rules',
                        'Transaction Fields',
                    ].map((data, idx) => (
                        <button onClick={() => setStep(idx + 1)} className={step === idx + 1 ? 'text-brand' : ''}>
                            {data}
                        </button>
                    ))}
                </div>

                {step === 1 && <ManageGLCode />}
                {step === 2 && <AccountMapping />}
                {step === 3 && <CategoryMapping />}
                {step === 4 && <AccountingRules />}
                {step === 5 && <TransactionFields />}
            </section>
        </section>
    )
}
