import notFound from '@/assets/not-found.svg'
import AddToPayrollModal from '@/components/Modals/Payroll/AddToPayroll/AddToPayrollModal'
import ProgressBar from '@/components/ProgressBar'
import { getSortString } from '@/components/Table/utils'
import { useAuthContext, usePayrollContext } from '@/context'
import { useGetOrgMembers } from '@/services/employees/queries'
import { useGetOrgPayrolls, useGetPayrollSettingsForOrg } from '@/services/payroll/queries'
import { Menu } from '@headlessui/react'
import { ColumnSort } from '@tanstack/react-table'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CiSearch } from 'react-icons/ci'
import { TfiAngleDown } from 'react-icons/tfi'
import PayrollListTable from '../../components/PayrollListTable'
import NewProfilePrompt from '@/components/Modals/Payroll/NewProfileConfirmation'
import { PayrollSettingsPrompt } from '@/components/Modals/Payroll/PayrollSettingsPrompt/PayrollSettingsPrompt'
import { PaymentProfile } from '@/types/payroll'
import { debounce } from 'lodash'
import usePersistedState from '@/hooks/usePersistedState'
import BulkProfileWrapper from '@/components/Modals/Payroll/BulkPayroll/BulkProfileWrapper'

export default function PayrollList() {
    const [searchQuery, setSearchQuery] = useState('')
    const [creationType, setCreationType] = useState<'manual' | 'bulk'>('manual')
    const [backspaceClicked, setBackSpaceClicked] = useState(false)
    const [backspace, setBackSpace] = useState(false)
    const { selectedAccount } = useAuthContext()

    const [showManualModal, setShowManualModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [filledPayrollSettings, setFilledPayrollSettings] = usePersistedState({
        defaultValue: false,
        key: `payroll_settings_${selectedAccount?.id}`,
    })
    const [showBulkModal, setShowBulkModal] = useState(false)
    const [showSetSettings, setShowSetSettings] = useState(false)
    const { sort } = usePayrollContext()

    const { data: orgPayrollSettings } = useGetPayrollSettingsForOrg({
        queryParams: {
            org_id: selectedAccount?.org.id as string,
        },
    })

    const {
        data: org_payroll_details,
        isLoading: isOrgPayrollDetailsloading,
        isRefetching: isOrgPayrollDetailsrefetching,
        refetch: refetchOrgPayroll,
    } = useGetOrgPayrolls({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
            sort: getSortString(sort as ColumnSort),
            search: searchQuery,
        },
        onSuccess: () => {
            setBackSpace(false)
        },
    })

    const { data: org_members } = useGetOrgMembers({
        queryParams: {
            org_id: `${selectedAccount?.org.id as string}`,
            status: 'ACTIVE',
        },
    })

    const orgMembers = useMemo(() => org_members ?? [], [org_members])
    const debouncedSearch = useRef(
        debounce(() => {
            refetchOrgPayroll()
        }, 300)
    ).current

    const handleKeyDown = (event: any) => {
        if (event.key === 'Backspace') {
            setBackSpaceClicked(true)
            setBackSpace(true)
        }
    }
    useEffect(() => {
        if (backspaceClicked) {
            refetchOrgPayroll()
            setBackSpaceClicked(false)
        }
        if (sort) {
            refetchOrgPayroll()
        }
        if (searchQuery) {
            debouncedSearch()
        }
        return () => {
            debouncedSearch.cancel()
        }
    }, [backspaceClicked, debouncedSearch, refetchOrgPayroll, searchQuery, sort])

    const orgPayrollDetails = useMemo(
        () => (org_payroll_details?.profiles ?? []) as PaymentProfile[],
        [org_payroll_details]
    )

    const handleProfile = (name: 'manual' | 'bulk') => {
        // setShowManualModal(true)
        if (orgPayrollDetails.length > 0 || filledPayrollSettings) {
            if (name === 'manual') {
                setShowManualModal(true)
            } else if (name === 'bulk') {
                setShowBulkModal(true)
            }
        } else {
            setShowConfirmationModal(true)
            setCreationType(name)
        }
    }

    if (isOrgPayrollDetailsloading) return <ProgressBar />
    return (
        <section>
            <div className='block lg:flex justify-between mb-4 lg:mb-0 mt-4'>
                <div className='mb-6 '>
                    <h1 className='text-dark font-medium text-lg'>People</h1>
                    <p className='text-gray7 text-sm'>View and manage people in your payroll.</p>
                </div>
                <Menu as='div'>
                    <Menu.Button
                        className='bg-[#454ADE] text-white px-4 h-[45px] rounded-md text-sm flex justify-between items-center gap-2 w-full md:w-auto'
                        id='payroll_payment_profile'
                    >
                        Create Payment Profile
                        <TfiAngleDown />
                    </Menu.Button>
                    <Menu.Items className='w-[159.56px] flex flex-col absolute py-[13px] px-0 rounded-lg justify-center border border-[#DADCE0] bg-white mt-2 z-10'>
                        <Menu.Item>
                            <span
                                className='px-4 py-2 cursor-pointer hover:bg-[#454ADE] hover:text-white'
                                onClick={() => handleProfile('manual')}
                            >
                                Manually
                            </span>
                        </Menu.Item>
                        <Menu.Item>
                            <span
                                className='px-4 py-2 cursor-pointer hover:bg-[#454ADE] hover:text-white'
                                onClick={() => handleProfile('bulk')}
                            >
                                Bulk Import
                            </span>
                        </Menu.Item>
                    </Menu.Items>
                </Menu>
            </div>
            <div className='w-full py-6 block bg-white p-4 rounded-2xl'>
                <div className='flex items-center justify-between mb-6'>
                    <div className='border border-[#DADCE0] h-[45px] w-[60%] md:w-[30%] rounded-lg flex items-center overflow-hidden '>
                        <div className='w-[10%] text-[#A8A8A8] flex items-center justify-center h-full mx-1 md:mx-0'>
                            <CiSearch fontSize={25} />
                        </div>
                        <input
                            type='text'
                            placeholder='Search by Name'
                            className='h-full w-full outline-none placeholder:text-xs'
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </div>
                {orgPayrollDetails.length === 0 && isOrgPayrollDetailsrefetching ? (
                    <ProgressBar />
                ) : (orgPayrollDetails.length === 0 && searchQuery?.length < 1 && !backspace) ||
                  (orgPayrollDetails.length > 0 && searchQuery?.length >= 0 && !backspace) ? (
                    <section
                        className={`overflow-x-scroll transition-all rounded-lg ${
                            isOrgPayrollDetailsrefetching ? 'opacity-30' : 'opacity-100'
                        } `}
                    >
                        <PayrollListTable payrolls={orgPayrollDetails} orgMembers={orgMembers} />
                    </section>
                ) : (
                    <div className='h-[300px] flex flex-col text-center justify-center items-center'>
                        {searchQuery && orgPayrollDetails.length === 0 ? (
                            <>
                                <img src={notFound} alt='Not found' className='mt-6 h-[170px]' />
                                <span className='text-xl font-bold text-[#5E5E5E]'>Sorry! No result found :(</span>
                                <span className='text-[#202020] text-sm w-[45%] mx-auto mt-3 font-medium'>
                                    "Oops! It seems we couldn't find any matching results for your search. Please try
                                    again with different keywords or refine your filter criteria for better results.
                                </span>
                            </>
                        ) : (
                            <ProgressBar />
                        )}
                    </div>
                )}
                {showManualModal && (
                    <AddToPayrollModal
                        orgPayrollSettings={orgPayrollSettings}
                        closeModal={setShowManualModal}
                        orgMembers={orgMembers}
                    />
                )}
                {showConfirmationModal && (
                    <NewProfilePrompt
                        closeModal={setShowConfirmationModal}
                        addProfile={setShowManualModal}
                        setSettings={setShowSetSettings}
                    />
                )}

                {showBulkModal && <BulkProfileWrapper closeModal={setShowBulkModal} />}

                {showSetSettings && (
                    <PayrollSettingsPrompt
                        setSettings={setShowSetSettings}
                        setShowManualModal={creationType === 'manual' ? setShowManualModal : setShowBulkModal}
                        setFilledPayrollSettings={setFilledPayrollSettings}
                    />
                )}
            </div>
        </section>
    )
}
