import { CancelIcon } from '@/assets/assets.export'
import { Button } from '@/components'
import ModalWrapper from '../ModalWrapper'
import { formatAmount } from '@/utils/money'
import { format } from 'date-fns'
import { TransactionType } from '@/types/transactions'
import { useMutateRepeatTransaction, useMutateRetryTransaction } from '@/services/transactions/mutations'
import { toastHandler } from '@/components/utils/Toast'
import usePersistedState from '@/hooks/usePersistedState'
import { useSendTransferOtp } from '@/services/payout/mutations'
import { useEffect, useState } from 'react'
import { transactionDestination } from '@/modules/dashboard/Transactions/components/utils'

type Props = {
    closeModal: React.Dispatch<React.SetStateAction<boolean>>
    transactionDetails?: TransactionType
}
const RedoTransaction = ({ closeModal, transactionDetails }: Props) => {
    const [timer, setTimer] = usePersistedState({ key: 'payrollTimer', defaultValue: 120 })
    const { mutate: sendTransferOtpFn, isLoading: isResendingOtp } = useSendTransferOtp({})
    const [otp, setOtp] = useState('')

    useEffect(() => {
        const interval = setInterval(() => {
            if (timer > 0) {
                setTimer(timer - 1)
            }
            if (timer === 0) {
                setTimer(0)
                clearInterval(interval)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    const transaction_details = [
        { name: 'Transaction ID', desc: transactionDetails?.transaction_id },
        {
            name: 'Amount',
            desc:
                transactionDetails?.ownerTransactionType === 'CREDIT'
                    ? `+ ${formatAmount(transactionDetails?.credit?.amount)}`
                    : `- ${formatAmount(transactionDetails?.debit?.amount)}`,
        },
        { name: 'Fee', desc: formatAmount(transactionDetails?.fee) },

        {
            name: 'Date',
            desc: (
                <>
                    <span className='whitespace-nowrap'>{format(new Date(), 'PP hh:mm aa')}</span>
                </>
            ),
        },

        {
            name: 'Initiated by',
            desc: `${transactionDetails?.initiated_by?.first_name} ${transactionDetails?.initiated_by?.last_name}`,
        },
        {
            name: 'Destination',
            // desc: transactionDetails?.credit?.account?.user?.first_name
            //     ? `${capitalizeText(transactionDetails?.credit?.account?.user?.first_name)} ${capitalizeText(
            //           transactionDetails?.credit?.account?.user?.last_name
            //       )}`
            //     : transactionDetails?.metadata?.recipient
            //       ? capitalizeText(transactionDetails?.metadata?.recipient?.name)
            //       : 'Wallet Funding',
            desc: transactionDestination(transactionDetails as TransactionType)?.destination,
        },
    ]

    const { mutate: retryTransactionFn, isLoading: isRetryingTransaction } = useMutateRetryTransaction({
        queryParams: {
            transaction_id: transactionDetails?._id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Transaction retry in progress', state: 'success' })
            closeModal(false)
        },
    })

    const { mutate: repeatTransactionFn, isLoading: isRepeatingTransaction } = useMutateRepeatTransaction({
        queryParams: {
            transaction_id: transactionDetails?._id as string,
        },
        onSuccess: () => {
            toastHandler({ message: 'Transaction repeat in progress', state: 'success' })
            closeModal(false)
        },
    })

    const handleClick = () => {
        if (transactionDetails?.status === 'COMPLETED') {
            repeatTransactionFn({})
            return
        }
        retryTransactionFn({})
    }

    return (
        <ModalWrapper variants={undefined}>
            <main className='min-h-[30vh] h-[38rem] w-screen md:w-[38rem]'>
                <section className='flex items-center border-b border-[#DADCE0] p-4 '>
                    <span className='flex-1 text-center text-xl font-medium'>
                        {transactionDetails?.status === 'COMPLETED' ? 'Repeat' : 'Retry'} Transaction
                    </span>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2 mr-5 cursor-pointer'
                        onClick={() => closeModal(false)}
                    />
                </section>
                <div className='overflow-scroll'>
                    <div className='px-[23px] lg:px-[46px] mt-4 mb-4 '>
                        <div className='bg-[#EFEFF9] p-5 rounded-xl text-[#202020] flex flex-col gap-1 mb-6'>
                            {transaction_details.map(field => (
                                <p className='text-base font-semibold'>
                                    {field.name}
                                    <span className='font-medium'>: {field.desc}</span>
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
                <section className='px-[23px] lg:px-[46px]'>
                    <p className='font-medium text-[#202020] text-[14px]'>
                        An OTP has been sent to your email to verify this action. Kindly enter OTP to proceed
                    </p>
                    <form>
                        <div className='flex flex-col md:flex-row mt-4 '>
                            <div className='flex flex-col flex-1 gap-3'>
                                <label htmlFor='email' className='text-[#202020] text-[14px]'>
                                    Enter OTP
                                </label>
                                <input
                                    className='h-[3.5rem] text-dark rounded-md border border-gray1 focus:border-2 focus:border-brand bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none disabled:cursor-not-allowed'
                                    type='number'
                                    placeholder='Enter your OTP'
                                    onChange={e => {
                                        const { value } = e.target
                                        setOtp(value)
                                    }}
                                />
                            </div>
                        </div>
                    </form>
                    <p className='mt-4 text-sm font-medium'>
                        I did not receive OTP.{' '}
                        <button
                            className='text-[#454ADE] font-semibold disabled:text-[#A8A8A8]'
                            onClick={() => {
                                sendTransferOtpFn({})
                                setTimer(120)
                            }}
                            disabled={timer > 0}
                        >
                            {' '}
                            Resend
                        </button>{' '}
                        {timer !== 0 && (
                            <span className='text-[#A8A8A8]'>in {timer < 10 ? `0${timer}` : timer} secs</span>
                        )}
                    </p>
                </section>
                <section className='flex items-center justify-end border-t border-[#DADCE0] p-4 pr-12 fixed bottom-0 bg-white w-full pb-2 rounded-b-md'>
                    <Button
                        className=' text-white px-8  rounded-lg text-[14px] min-w-[130px]'
                        loading={isRepeatingTransaction || isRetryingTransaction}
                        onClick={handleClick}
                        disabled={otp.length < 4 || isResendingOtp}
                    >
                        Proceed
                    </Button>
                </section>
            </main>
        </ModalWrapper>
    )
}

export default RedoTransaction
