import { useState } from 'react'
import ModalWrapper from '../ModalWrapper'
import { CancelIcon } from '@/assets/assets.export'
import { Controller, useForm } from 'react-hook-form'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/Select'
import { Input } from '@/components/Input'
import { Textarea } from '@/components/Textarea'
import { Switch } from '@headlessui/react'
import { Button } from '@/components/Button'

type FormType = {
    acct_type: string
    acct_name: string
    description: string
    acct_code?: number
}
type Props = {
    closeModal: () => void
}

const AddNewAccountModal = ({ closeModal }: Props) => {
    const {
        handleSubmit,
        control,
        watch,
        formState: { isValid },
    } = useForm<FormType>({})
    const [enabled, setEnabled] = useState(false)

    const acctType = watch('acct_type')
    const descValue = watch('description')

    const onSubmit = (values: FormType) => {
        console.log(values)
    }
    return (
        <ModalWrapper>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className=' min-h-[18rem] w-[calc(100vw-25px)] md:w-[28rem] overflow-scroll'
            >
                <div className='flex items-center border-b border-[#DADCE0] p-3 px-5 justify-between'>
                    <h2 className='text-center text-lg font-semibold opacity-80'>Add new account</h2>
                    <CancelIcon
                        className='rounded-full bg-[#F2F2F2] p-2  cursor-pointer'
                        onClick={() => closeModal()}
                    />
                </div>
                <div className='px-7'>
                    <fieldset className='mt-5 flex flex-col flex-1 '>
                        <label className='mb-3' htmlFor='funding_method'>
                            Account type*
                        </label>
                        <Controller
                            name='acct_type'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Select name='fund_source' onValueChange={field.onChange}>
                                    <SelectTrigger
                                        className=' text-base h-[3rem] border-gray8 flex'
                                        variant={acctType ? 'selected' : 'new'}
                                    >
                                        <SelectValue placeholder={'Select account type'} />
                                    </SelectTrigger>
                                    <SelectContent className='w-[24.5rem]'>
                                        <SelectItem value={'expense'} className='pl-3'>
                                            Expense
                                        </SelectItem>
                                        <SelectItem value={'income'} className='pl-3'>
                                            Income
                                        </SelectItem>
                                    </SelectContent>
                                </Select>
                            )}
                        />
                    </fieldset>
                    <fieldset className='mt-5 flex flex-col flex-1 '>
                        <label className='mb-3' htmlFor='funding_method'>
                            Account name*
                        </label>
                        <Controller
                            name='acct_name'
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    type='text'
                                    placeholder='Enter account name'
                                    className='h-12 border-gray8 text-base'
                                />
                            )}
                        />
                    </fieldset>
                    <fieldset className='mt-5 flex flex-col flex-1 '>
                        <label className='mb-3 flex items-center justify-between' htmlFor='funding_method'>
                            Description
                            <span className='text-[#838383] text-sm'>{descValue?.length ?? '0'}/120</span>
                        </label>
                        <Controller
                            name='description'
                            rules={{
                                required: true,
                                maxLength: 120,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Textarea
                                    {...field}
                                    value={field.value as string}
                                    className='border border-[#DADCE0] pt-2 min-h-[4rem] text-base resize-none'
                                    maxLength={120}
                                    placeholder='Enter description'
                                />
                            )}
                        />
                    </fieldset>
                    <div className='flex items-center justify-between mt-5'>
                        <p>Set an account code for this account</p>
                        <Switch
                            checked={enabled}
                            onChange={setEnabled}
                            className={`relative inline-flex h-6 w-11 items-center rounded-full rotate-180 ${
                                enabled ? 'bg-brand' : 'bg-[#EFEFF9]'
                            }`}
                        >
                            <span className='sr-only'>Set account code </span>
                            <span
                                className={`${
                                    enabled ? 'translate-x-1 bg-white' : 'translate-x-6 bg-brand'
                                } inline-block h-4 w-4 transform rounded-full  transition`}
                            />
                        </Switch>
                    </div>
                    {enabled && (
                        <fieldset className='mt-5 flex flex-col flex-1'>
                            <label className='mb-3' htmlFor='funding_method'>
                                Account code*
                            </label>
                            <Controller
                                name='acct_code'
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        type='number'
                                        placeholder='Enter account code'
                                        className='h-12 border-gray8 text-base w-1/2'
                                    />
                                )}
                            />
                        </fieldset>
                    )}
                </div>
                <div className='border-t border-gray4 flex mt-6 mb-3 justify-end items-center pt-2.5 px-4'>
                    <Button disabled={!isValid} onClick={() => closeModal()} className='text-sm min-h-[2.5rem] py-4'>
                        Save new account
                    </Button>
                </div>
            </form>
        </ModalWrapper>
    )
}

export default AddNewAccountModal
