import { PlansCheckmark } from '@/assets/assets.export'
import animationData from '@/assets/upgrade-animation.json'
import upgradeheader from '@/assets/upgrade-header.png'
import { Button } from '@/components/Button'
import { RadioGroup, RadioGroupItem } from '@/components/Radio Group'
import { toastHandler } from '@/components/utils/Toast'
import { useAuthContext } from '@/context'
import { useUpgradePlan } from '@/services/billing/mutations'
import { useGetModuleAvailable, useGetOrgPlans } from '@/services/billing/queries'
import { formatAmount } from '@/utils/money'
import { capitalizeText } from '@/utils/string'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AiOutlineClose } from 'react-icons/ai'
import Lottie from 'react-lottie'
import ModalWrapper from '../ModalWrapper'
import { ModuleNameEnum } from '@/types/billing'

type Props = {
    closeModal: () => void
    initialStep?: number
    planId?: string
    module?: ModuleNameEnum
}

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}
export default function UpgradePlanModal({ closeModal, initialStep, planId: selectedPlanId }: Props) {
    const [step, setStep] = useState(initialStep ?? 1)
    const { orgPlans: plansData, selectedAccount, currentModule } = useAuthContext()
    const { data } = useGetOrgPlans()
    const orgPlans = useMemo(() => plansData ?? data ?? [], [data])

    const orgBusinessPlan = selectedAccount?.org?.business_plan

    const { mutate: upgradePlanFn, isLoading } = useUpgradePlan({
        onSuccess: () => {
            setStep(2)
            toastHandler({ message: 'Upgrade successful', state: 'success' })
        },
    })

    const { isLoading: isChecking } = useGetModuleAvailable({
        enabled: !!currentModule,
        queryParams: {
            module: currentModule as string,
        },
    })

    const {
        handleSubmit,
        control,
        watch,
        formState: { isValid },
    } = useForm<{ plan_id: string }>({
        defaultValues: {
            plan_id:
                typeof orgBusinessPlan?.plan === 'string'
                    ? (orgBusinessPlan?.plan as string)
                    : (orgBusinessPlan?.plan?.id as string),
        },
    })

    const planId = watch('plan_id')
    const OnSubmit = (values: { plan_id: string }) => {
        upgradePlanFn({ plan_id: values?.plan_id })
    }

    return (
        <ModalWrapper closeModal={() => closeModal()}>
            {step === 2 && (
                <UpgradeSuccess
                    amount={orgPlans?.find(orgPlan => orgPlan?.id === (selectedPlanId ?? planId))?.price as string}
                    closeModal={() => closeModal()}
                />
            )}
            {step === 1 && (
                <section
                    // ref={ref}
                    className='w-screen lg:w-[53rem] min-h-[69vh] max-h-[90vh] overflow-scroll relative bg-[#F9F9F9] rounded-lg'
                >
                    <form onSubmit={handleSubmit(OnSubmit)}>
                        <div className='relative top-0  rounded-t-lg z-50 overflow-hidden'>
                            <div className='z-0 h-16 lg:h-24'>
                                <img src={upgradeheader} alt='SVg image' />
                            </div>
                            <div className=' px-3 flex justify-between text-white items-center z-10 absolute top-[35%] lg:top-1/2 -translate-y-1/2 w-full'>
                                <div>
                                    <h2 className='font-semibold text-xl'>Upgrade Plan</h2>
                                    <p className='text-xs mt-1'>
                                        Upgrade your subscription plan to enhance your experience
                                    </p>
                                </div>
                                <div
                                    className='close-upgrade bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                                    onClick={() => closeModal()}
                                >
                                    <AiOutlineClose fontSize={25} />
                                </div>
                            </div>
                        </div>
                        <section className={`mt-5 lg:mt-20 mx-6 mb-24 lg:mb-20 ${isChecking ? 'opacity-90' : ''}`}>
                            <Controller
                                name='plan_id'
                                rules={{
                                    required: true,
                                    minLength: 2,
                                }}
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        onValueChange={field.onChange}
                                        className='grid grid-cols-1 lg:grid-cols-3 gap-6'
                                    >
                                        {orgPlans
                                            ?.filter(plan => !plan?.archived)
                                            ?.sort((plan_1, plan_2) =>
                                                orgBusinessPlan?.plan === plan_1?.id
                                                    ? -1
                                                    : plan_2?.id === orgBusinessPlan?.plan
                                                      ? 1
                                                      : 0
                                            )
                                            .map(plan => (
                                                <label
                                                    htmlFor={plan?.id}
                                                    key={plan?.id}
                                                    className='shadow-sm rounded-lg p-2 border-gray/10 border cursor-pointer'
                                                >
                                                    <div className='flex justify-between items-center gap-4'>
                                                        <div>
                                                            <h2 className='font-semibold'>
                                                                {capitalizeText(plan?.name)}
                                                            </h2>
                                                            <p className='text-gray7 text-sm truncate w-48'>
                                                                {' '}
                                                                {plan?.description}
                                                            </p>
                                                        </div>
                                                        <RadioGroupItem value={plan?.id} id={plan?.id} />
                                                    </div>
                                                    <div className='text-2xl font-semibold my-3'>
                                                        {formatAmount(plan?.price)}
                                                        <span className='font-normal text-sm'>/ month</span>
                                                    </div>
                                                </label>
                                            ))}
                                    </RadioGroup>
                                )}
                            />

                            <h3 className='text-gray7 font-medium text-xl text-center mt-6 max-w-xl'>Compare plans</h3>
                            <ul className='grid grid-cols-1 lg:grid-cols-2 gap-3 mt-5'>
                                {orgPlans &&
                                    orgPlans
                                        ?.filter(plan => planId === plan?.id)?.[0]
                                        ?.metadata?.map(plan => (
                                            <li className='flex items-center gap-1 text-sm font-medium '>
                                                <PlansCheckmark className='w-3 h-3' /> {plan}
                                            </li>
                                        ))}
                            </ul>
                        </section>

                        <div className='bg-white fixed bottom-0 border-t border-gray8 w-full flex items-center justify-end gap-4  px-6 py-2 rounded-b-md'>
                            <Button
                                type={'submit'}
                                className='bg-[#454ADE] text-white px-8 h-[3rem] rounded-lg text-[14px] min-w-[130px]'
                                loading={isLoading}
                                disabled={!isValid}
                            >
                                Upgrade Plan
                            </Button>
                        </div>
                    </form>
                </section>
            )}
        </ModalWrapper>
    )
}

const UpgradeSuccess = ({ closeModal, amount }: { closeModal: () => void; amount: string }) => {
    return (
        <section className='w-screen lg:w-[30rem] min-h-[55vh] max-h-[90vh] overflow-scroll relative bg-[#F9F9F9] rounded-lg'>
            <div className='flex justify-end m-3'>
                <span
                    className='bg-[#F2F2F2] w-[30px] h-[30px] p-2 rounded-[50%] flex items-center justify-center text-[#838383] cursor-pointer'
                    onClick={() => closeModal()}
                >
                    <AiOutlineClose fontSize={25} />
                </span>
            </div>
            <div>
                <Lottie options={defaultOptions} height={300} width={300} />
                <h2 className='text-2xl font-semibold text-center'>Congratulations</h2>
                <p className='text-gray7 text-sm text-center mt-2'> Your plan has been upgraded successfully.</p>
                <div className='bg-[#D5D5F5] rounded-xl p-4 m-4'>
                    <h4 className='text-lg font-semibold'>Note</h4>
                    <p className='text-gray7 text-sm'>
                        The upgrade cost of {formatAmount(amount)} will be deducted from your wallet immediately. This
                        amount covers the cost for the remaining days in your current billing cycle.
                    </p>
                </div>
            </div>
        </section>
    )
}
