import { useEffect, useState } from 'react'
import StatusColor from '@/components/utils/StatusColor'
import { useAuthContext } from '@/context'
import { useGetCompanyKYB } from '@/services/auth/queries'
import { useSearchParams } from 'react-router-dom'
import CompanyCompliancePage from './partials/CompanyCompliancePage'
import { complianceDetailsType } from './utils'
import ProgressBar from '@/components/ProgressBar'
import { CompleteCompanyCompliance } from './partials/CompleteCompanyCompliance'

export type dataProps = {
    name: string
    desc: string
    type: string
    verified?: 'verified' | 'rejected' | 'review'
}

export default function CompanyCompliance() {
    const [editComplianceDetails, setEditComplianceDetails] = useState(false)
    const { selectedAccount } = useAuthContext()
    const [step, setStep] = useState(1)
    const [searchParams] = useSearchParams()

    const { data: company_kyb, isLoading } = useGetCompanyKYB({
        queryParams: {
            org_id: selectedAccount?.org?.id as string,
        },
    })

    const business_info = [
        { name: 'Legal business name', desc: company_kyb?.legal_business_name },
        {
            name: 'Industry',
            desc: company_kyb?.industry,
        },
        {
            name: 'Sub Industry',
            desc: company_kyb?.sub_industry,
        },
        {
            name: 'Number of employees',
            desc: company_kyb?.number_of_employees,
        },
        {
            name: 'Business email address',
            desc: company_kyb?.business_email,
        },
        {
            name: 'Business phone number',
            desc: company_kyb?.business_phone,
        },
        { name: 'Website url', desc: company_kyb?.website },
        {
            name: 'Company description',
            desc: company_kyb?.description,
        },
    ]
    const main_contact_info = [
        {
            name: 'Country',
            desc: company_kyb?.address?.main?.country,
        },
        {
            name: 'State',
            desc: company_kyb?.address?.main?.state,
        },
        { name: 'City', desc: company_kyb?.address?.main?.city },
        { name: 'Postal code', desc: company_kyb?.postal_code },
        {
            name: 'Street address',
            desc: company_kyb?.address?.main?.addressLine_1,
        },
    ]
    const registered_contact_info = [
        {
            name: 'Country',
            desc: company_kyb?.address?.registered?.country,
        },
        {
            name: 'State',
            desc: company_kyb?.address?.registered?.state,
        },
        { name: 'City', desc: company_kyb?.address?.registered?.city },
        {
            name: 'Postal code',
            desc: company_kyb?.address?.registered?.postalCode,
        },
        {
            name: 'Street address',
            desc: company_kyb?.address?.registered?.addressLine_1,
        },
    ]

    const incorporation_info = [
        {
            name: 'Business type',
            desc: company_kyb?.business_type?.replaceAll('_', ' '),
        },
        { name: 'RC Number', desc: company_kyb?.rc_number },
        { name: 'BN Number', desc: company_kyb?.bn_number },
        { name: 'CAC IT Number', desc: company_kyb?.cac_it_number },
        {
            name: 'Company registration date',
            desc: company_kyb?.registration_date,
        },
        { name: 'CAC-BN1', desc: company_kyb?.cac_bn1 },
        { name: 'Officers', desc: company_kyb?.officers },
        { name: 'CAC IT form 1', desc: company_kyb?.cac_it_form },
        { name: 'Tax ID number', desc: company_kyb?.tax_identification_number },
        { name: ' SCUML Certificate', desc: company_kyb?.scuml_certificate },
        { name: 'Proof of address', desc: company_kyb?.proof_of_address },
        { name: 'Certification of incorporation', desc: company_kyb?.certificate_of_incorporation },
        { name: 'Certification of Business name', desc: company_kyb?.certificate_of_business_name },
        { name: 'CAC Status Report', desc: company_kyb?.cac_status_report },

        { name: ' Memorandum and articles of association', desc: company_kyb?.memorandum_of_association },
    ]

    const compliance_details = {
        1: { name: 'Business Information', deet: business_info },
        2: { name: 'Address Information', deet: [...main_contact_info, { registered: registered_contact_info }] },
        3: { name: 'Incorporation Information', deet: incorporation_info },
    }

    useEffect(() => {
        if (searchParams.get('from') === 'get_started') {
            setEditComplianceDetails(true)
        }
    }, [])

    if (isLoading) return <ProgressBar />
    return (
        <section className=' lg:mr-5'>
            <div>
                <h1 className='flex items-center gap-3 font-medium'>
                    Compliance
                    <StatusColor status={company_kyb?.verification_status as string} />
                </h1>
                <p className='text-sm text-gray1 mt-1'>Manage KYB documents and legal business details</p>
            </div>

            <section className='flex flex-col lg:flex-row mt-10 gap-8'>
                <div className='w-52 h-fit bg-white flex flex-col gap-4 rounded-xl p-5  text-sm items-start shadow-sm'>
                    {['Business Information', 'Address Information', 'Incorporation details'].map((data, idx) => (
                        <button onClick={() => setStep(idx + 1)} className={step === idx + 1 ? 'text-brand' : ''}>
                            {data}
                        </button>
                    ))}
                </div>

                {!editComplianceDetails && (
                    <CompanyCompliancePage
                        setEditComplianceDetails={setEditComplianceDetails}
                        compliance_details={
                            compliance_details?.[step as keyof typeof compliance_details] as complianceDetailsType
                        }
                        setStep={setStep}
                        status={company_kyb?.verification_status as string}
                    />
                )}

                {editComplianceDetails && <CompleteCompanyCompliance step={step} setStep={setStep} />}
            </section>
        </section>
    )
}
