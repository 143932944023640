import { Input } from '@/components'
import { useAddQueryParamsToURL } from '@/hooks/useAddQueryParamsToURL'
import { motion } from 'framer-motion'
import { Search } from 'lucide-react'
import AccountingTableComponent from '../components/Accounting-Table/Accounting-Table'
import { useAccountingContext } from '@/context'

const filterType = {
    all: 'all',
    review: 'needs_review',
    ready: 'ready',
}

export default function AccountingTransactions() {
    const { all, ready, review } = filterType
    const [setAccoutingFilter, searchParamDetails] = useAddQueryParamsToURL<{ type: keyof typeof filterType }>()
    const subRoute = searchParamDetails.get('type') ?? all
    const { rows: transactions } = useAccountingContext()

    const needsReview = transactions.filter(transaction => !transaction.ready)
    const readyforExport = transactions.filter(transaction => transaction.ready)

    const renderedTransactions = subRoute === all ? transactions : subRoute === review ? needsReview : readyforExport

    const filterDetailsBtn = [
        {
            desc: 'All',
            id: all,
            filterVal: transactions?.length,
        },
        {
            desc: 'Needs review',
            filterVal: needsReview?.length,
            id: review,
        },
        {
            desc: 'Ready for export',
            filterVal: readyforExport?.length,
            id: ready,
        },
    ]

    return (
        <section className='mt-8'>
            <div>
                <h2 className='text-xl font-semibold'>Accounting</h2>
                <p className='text-sm text-gray7'>Export your transactions to your preferred accounting platform</p>
            </div>
            <section className='mt-8 mb-4 bg-white p-4 rounded-xl'>
                <div className='overflow-x-scroll'>
                    <ul className='flex items-center gap-4 mb-5'>
                        {filterDetailsBtn.map(btnDetails => (
                            <li
                                className={`relative ${btnDetails.id === subRoute ? 'text-brand ' : 'bg-transparent text-[#5E5E5E]'} text-sm rounded-md p-2 px-3`}
                                onClick={() => setAccoutingFilter('type', btnDetails.id)}
                            >
                                <button className='flex gap-2 whitespace-nowrap'>
                                    {btnDetails.desc}{' '}
                                    {btnDetails?.filterVal && (
                                        <span
                                            className={`${btnDetails.id === subRoute ? 'bg-brand ' : ' bg-gray7 '} text-white text-xs px-3 p-0.5 rounded-xl`}
                                        >
                                            {btnDetails?.filterVal}
                                        </span>
                                    )}
                                </button>
                                {btnDetails.id === subRoute && (
                                    <motion.div
                                        layoutId='accounting-filter'
                                        className={
                                            'bg-brand/10 rounded  text-sm  w-full left-[1px] top-0  bottom-0 absolute'
                                        }
                                    />
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <Input
                    placeholder='Search'
                    className='max-w-xs h-11 border-gray4'
                    leftIcon={<Search className='text-gray' />}
                />
                <div className={`mt-5 `}>
                    <AccountingTableComponent rows={renderedTransactions} />
                </div>
            </section>
        </section>
    )
}
