import Table from '@/components/Table'
import TableActions from '@/components/Table/TableActions'
import TransactionEmptyStateIcon from '@/assets/v2dashboard/recent-transactions.svg'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import { ChartOfAccountType } from '@/types/accounting'
import { Pagination } from '@/types/utils'

type Props = {
    coa: ChartOfAccountType[]
    pagination: Pagination
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
    setPageNoClicked: React.Dispatch<React.SetStateAction<boolean>>
}
export default function ChartOfAccountTable({ coa, pagination, pageNumber, setPageNoClicked, setPageNumber }: Props) {
    const columnHelper = createColumnHelper<ChartOfAccountType>()

    const columns: ColumnDef<ChartOfAccountType, any>[] = [
        columnHelper.accessor('account_name', {
            header: () => <p>Account Name</p>,
            cell: cell => {
                return <span className=' font-medium text-gray7'>{cell.renderValue()}</span>
            },
            enableSorting: false,
        }),
        columnHelper.accessor('code', {
            header: () => <p>ID</p>,
            cell: cell => {
                return <span className=' font-medium text-gray7'>{cell.renderValue()}</span>
            },
            enableSorting: false,
        }),
        columnHelper.accessor('description', {
            header: () => <p>Description</p>,
            cell: cell => {
                return <span className=' font-medium text-gray7'>{cell.renderValue()}</span>
            },
            enableSorting: false,
        }),

        columnHelper.display({
            id: 'actions',
            cell: () => <TableActions rotate actions={[]} />,
        }),
    ]
    return (
        <div className='rounded-lg'>
            <Table
                data={coa}
                columns={columns}
                rowClickable={true}
                emptyStateTitle='No Chart of Account'
                emptyStateImg={TransactionEmptyStateIcon}
                pagination_data={pagination}
                currentPageNumber={pageNumber}
                setCurrentPageNumber={setPageNumber}
                setIsPageNumberClicked={setPageNoClicked}
            />
        </div>
    )
}
