import { createMutation } from '../api/mutation'
import { customFields, glcodeKey } from './queries'

export const useUploadGlCode = createMutation({
    url: '/gl-code',
    method: 'POST',
    keysToRefetch: [glcodeKey],
    apiOptions: {
        headers: { 'Content-Type': 'multipart/form-data' },
    },
})

export const useUpdateGlCode = createMutation({
    url: '/gl-code',
    method: 'PATCH',
    keysToRefetch: [glcodeKey],
})

export const useDeleteGlCode = createMutation({
    url: '/gl-code',
    method: 'DELETE',
    keysToRefetch: [glcodeKey],
})

export const useUploadCOA = createMutation({
    url: '/accounting/account',
    method: 'POST',
    keysToRefetch: [],
})

export const useCreateCustomFields = createMutation({
    url: '/accounting/custom-field',
    method: 'POST',
    keysToRefetch: [customFields],
})

export const useDeleteCustomFields = createMutation({
    url: '/accounting/custom-field',
    method: 'DELETE',
    keysToRefetch: [customFields],
})

export const useUpdateCustomFields = createMutation({
    url: '/accounting/custom-field',
    method: 'PATCH',
    keysToRefetch: [customFields],
})
