import { QueryOrgPayrollByDateType } from '@/services/payroll/types'
import { ContractPayrollType, OrgPayrollHistoryType, UserTypeLeave, PayrollHistoryType } from '@/types/payroll'
import { ReactNode, createContext, useState } from 'react'
// import UpgradePlanModal from '@/components/Modals/Upgrade-plan'
// import { useNavigate } from 'react-router-dom'
// import useAccessToModule from '@/hooks/useAccessToModule'
// import { ModuleNameEnum } from '@/types/billing'

type SortColumn = { id: string | undefined; desc: boolean | undefined }

export type defaultPayrollProps = {
    sort: SortColumn | undefined
    setSort: React.Dispatch<React.SetStateAction<SortColumn | undefined>> | undefined
    sortLeave: SortColumn | undefined
    setSortLeave: React.Dispatch<React.SetStateAction<SortColumn | undefined>> | undefined
    sortContract: SortColumn | undefined
    setSortContract: React.Dispatch<React.SetStateAction<SortColumn | undefined>> | undefined
    setGroupDatePayroll: React.Dispatch<React.SetStateAction<QueryOrgPayrollByDateType | undefined>>
    groupDatePayroll: QueryOrgPayrollByDateType | undefined
    selectedLeaveData: UserTypeLeave | undefined
    setSelectedLeaveData: React.Dispatch<React.SetStateAction<UserTypeLeave | undefined>>
    assignLeaveModal: boolean
    setAssignLeaveModal: React.Dispatch<React.SetStateAction<boolean>>
    userPayrollHistory: PayrollHistoryType | undefined
    setUserPayrollHistory: React.Dispatch<React.SetStateAction<PayrollHistoryType | undefined>>
    selectedContract: ContractPayrollType | undefined
    setSelectedContract: React.Dispatch<React.SetStateAction<ContractPayrollType | undefined>>
    setUserContractPayroll: React.Dispatch<React.SetStateAction<ContractPayrollType | undefined>>
    userContractPayroll: ContractPayrollType | undefined
    selectedPayHistory: OrgPayrollHistoryType | undefined
    setSelectedPayHistory: React.Dispatch<React.SetStateAction<OrgPayrollHistoryType | undefined>>
    pageNumber: number
    setPageNumber: React.Dispatch<React.SetStateAction<number>>
    pageNumberClicked: boolean
    setPageNumberClicked: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultPayrollProps: defaultPayrollProps = {
    sort: undefined,
    setSort: () => undefined,
    sortLeave: undefined,
    setSortLeave: () => undefined,
    sortContract: undefined,
    setSortContract: () => undefined,
    groupDatePayroll: undefined,
    setGroupDatePayroll: () => undefined,
    selectedLeaveData: undefined,
    setSelectedLeaveData: () => undefined,
    assignLeaveModal: false,
    setAssignLeaveModal: () => undefined,
    userPayrollHistory: undefined,
    setUserPayrollHistory: () => undefined,
    selectedContract: undefined,
    setSelectedContract: () => undefined,
    userContractPayroll: undefined,
    setUserContractPayroll: () => undefined,
    selectedPayHistory: undefined,
    setSelectedPayHistory: () => undefined,
    pageNumberClicked: false,
    setPageNumberClicked: () => undefined,
    pageNumber: 1,
    setPageNumber: () => undefined,
}

export const PayrollContext = createContext(defaultPayrollProps)
type Props = { children: ReactNode }

export const PayrollContextProvider = ({ children }: Props) => {
    const [sort, setSort] = useState<SortColumn>()
    const [sortContract, setSortContract] = useState<SortColumn>()
    const [sortLeave, setSortLeave] = useState<SortColumn>()
    const [groupDatePayroll, setGroupDatePayroll] = useState<QueryOrgPayrollByDateType>()
    const [selectedLeaveData, setSelectedLeaveData] = useState<UserTypeLeave>()
    const [selectedContract, setSelectedContract] = useState<ContractPayrollType>()
    const [selectedPayHistory, setSelectedPayHistory] = useState<OrgPayrollHistoryType>()
    const [userPayrollHistory, setUserPayrollHistory] = useState<PayrollHistoryType>()
    const [userContractPayroll, setUserContractPayroll] = useState<ContractPayrollType | undefined>()
    const [assignLeaveModal, setAssignLeaveModal] = useState(false)
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [pageNumberClicked, setPageNumberClicked] = useState(false)

    // const navigate = useNavigate()
    // const { enabled } = useAccessToModule({ module: ModuleNameEnum.PAYROLL })

    const values = {
        sort,
        setSort,
        setGroupDatePayroll,
        groupDatePayroll,
        selectedLeaveData,
        setSelectedLeaveData,
        assignLeaveModal,
        setAssignLeaveModal,
        userPayrollHistory,
        setUserPayrollHistory,
        selectedContract,
        setSelectedContract,
        userContractPayroll,
        setUserContractPayroll,
        setSortContract,
        sortContract,
        sortLeave,
        setSortLeave,
        selectedPayHistory,
        setSelectedPayHistory,
        pageNumber,
        setPageNumber,
        pageNumberClicked,
        setPageNumberClicked,
    }

    // if (!enabled) return <UpgradePlanModal module={ModuleNameEnum.PAYROLL} closeModal={() => navigate(-1)} />
    return <PayrollContext.Provider value={values}>{children}</PayrollContext.Provider>
}
